import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import Dropdown from 'assets/components/dropdown/Dropdown';
import ThemeDropdownPopup from 'assets/components/dropdown/themes/Theme.Dropdown.Popup';
import Icon, { iconStyles } from 'assets/components/icon/Icon';
import Tag from 'assets/components/tag/Tag';
import ThemeTagRounded from 'assets/components/tag/themes/Theme.Tag.Rounded';
import Theme from 'assets/themes/Theme.Common';
import { calculatePages } from 'assets/utils/data/Number';
import { useTheme } from '../../../hooks/theme/useTheme';
import DataMap from '../../dataMap/DataMap';
import RenderProps from '../../helpers/RenderProps';
import useSelect from './hooks/Select';
import { getLocales } from 'assets/locales/Locale';

export enum SelectInputStructure {
  formInput = 'formInput',
  inputSelection = 'inputSelection',
  input = 'input',
}
export type Verify = Utils.VerifyExtends<Component.Input.Select.Structure, typeof SelectInputStructure>;
export default function SelectInput<Name>(props: Component.Input.Select.Import<Name>) {
  const { animation, value, displayValue, getFilteredData, getSelectedData, handleChange, search } = useSelect(props);
  const { lang } = getLocales();

  const offset = props.offset || 0;
  const limit = props.limit || 0;
  const count = calculatePages(limit, props.count || 0);
  const extend = useTheme(SelectInputStructure, props, {
    animation: {
      formInput: {
        trigger: 'focus',
      },
    },
    animateIn: animation.animateIn,
    animateOut: animation.animateOut,
  });
  const selectedData = getSelectedData();

  return (
    <div
      {...extend(SelectInputStructure.formInput, true)}
      data-label={props.label}
      data-error={props.error || undefined}
      data-read-only={props.readOnly || undefined}
      data-disabled={props.disabled || undefined}
      children={
        <>
          {props.onAdd && (
            <Button
              class={ThemeButtonCircle}
              media={<Icon class={iconStyles.common.add} />}
              onClick={props.onAdd}
              htmlElementProps={{ title: lang.add }}
            />
          )}
          {props.isMultiselect && !props.disableTags && selectedData && selectedData.length > 0 && (
            <>
              <div
                {...extend(SelectInputStructure.inputSelection)}
                children={
                  <DataMap
                    data={selectedData}
                    render={({ data }) => (
                      <Tag
                        class={ThemeTagRounded}
                        key={data.id}
                        data={data.id}
                        onClickAlt={handleChange}
                        text={data.title}
                      />
                    )}
                  />
                }
              />
            </>
          )}
          <input
            {...extend(SelectInputStructure.input)}
            type={'text'}
            id={props.name.toString()}
            name={'selectInput'}
            autoComplete="off"
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            value={displayValue}
            onChange={search}
          />
          {RenderProps(props, 'icon')}
          {animation.isVisible && (
            <Dropdown
              class={ThemeDropdownPopup}
              horizontalPosition="auto_fixed"
              verticalPosition="auto_fixed"
              animation={{ animate: animation.isIn }}
              render={
                <>
                  {!props.onOffsetChange ? null : (
                    <Box
                      style={{
                        padding: Theme.Size.S,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      render={
                        <>
                          {props.onOffsetChange && (
                            <>
                              <Button
                                class={ThemeButtonCircle}
                                style={{ marginLeft: 'auto' }}
                                media={<Icon class={iconStyles.common.left} />}
                                onClick={(value) => {
                                  if (props.onOffsetChange) props.onOffsetChange({ name: 'offset', value });
                                  //animation.animateIn();
                                }}
                                data={offset - 1}
                                disabled={offset < 1}
                                htmlElementProps={{ title: lang.prev }}
                              />
                              &nbsp;
                              {count > 1 && `${offset + 1}/${count}`}
                              &nbsp;
                              <Button
                                class={ThemeButtonCircle}
                                media={<Icon class={iconStyles.common.right} />}
                                onClick={(value) => {
                                  if (props.onOffsetChange) props.onOffsetChange({ name: 'offset', value });
                                  //animation.animateIn();
                                }}
                                data={offset + 1}
                                disabled={offset >= count - 1}
                                htmlElementProps={{ title: lang.next }}
                              />
                            </>
                          )}
                        </>
                      }
                    />
                  )}
                  <DataMap
                    data={getFilteredData()}
                    render={({ data }) => (
                      <Button
                        class={ThemeButtonMenuItem}
                        data={data.id}
                        key={data.id}
                        active={value.find((v) => v === data.id)}
                        onClick={handleChange}
                        disabled={data.disabled}
                        media={data.icon}
                        style={{ buttonText: {} }}
                        render={
                          <>
                            <span children={data.title} />
                            <span children={data.subtitle} />
                          </>
                        }
                        nonClickable={
                          props.onEdit &&
                          !data.disableEdit && (
                            <Button
                              class={ThemeButtonCircle}
                              style={{ marginLeft: 'auto', pointerEvents: 'all' }}
                              media={data.actionIcon || <Icon class={iconStyles.common.edit} />}
                              onClick={props.onEdit}
                              data={data.id}
                              htmlElementProps={{ title: data.actionTitle || lang.edit }}
                            />
                          )
                        }
                      />
                    )}
                  />
                </>
              }
            />
          )}
        </>
      }
    />
  );
}
