import { combineStrings } from 'assets/utils/data/String';
import Model from 'models/Model';
import Client from 'models/claims/client/Model.Client';

export default class VertaforeClient
  extends Model<Model.IVertaforeClient>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IVertaforeClient>
{
  id: number;
  email: string;
  firstName?: string;
  lastName?: string;
  vertaforeCustomerNumber?: string;
  clients?: Client[];

  constructor(
    data: Partial<Model.IVertaforeClient> | Utils.FormData<Model.IVertaforeClient>,
    language?: Locale.Languages
  ) {
    super(
      {
        clients: [Client],
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: combineStrings(' ', this.firstName, this.lastName) || '',
      subtitle: this.email,
      info: this.vertaforeCustomerNumber,
    };
  }
}
