import { getLocales } from 'assets/locales/Locale';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';
import Model from 'models/Model';

export async function productTierPricePointListApi(
  body: Api.Core.ProductTierPricePoint.List.IRequest
): Promise<Api.Res<Api.Core.ProductTierPricePoint.List.IResponse>> {
  return await executeServerApi<
    Api.Core.ProductTierPricePoint.List.IRequest,
    Api.Core.ProductTierPricePoint.List.IResponse,
    Api.Core.ProductTierPricePoint.List.IHeaders
  >({
    method: HttpMethod.get,
    url: '/api/ProductManagement/ProductTierPricePoint',
    body,
    headers: getDefaultHeaders(),
  });
}
export async function productTierPricePointSaveApi(
  body: Api.Core.ProductTierPricePoint.Save.IRequest
): Promise<Api.Res<Api.Core.ProductTierPricePoint.Save.IResponse>> {
  const serverResponse = await executeServerApi<
    Api.Core.ProductTierPricePoint.Save.IRequest,
    Api.Core.ProductTierPricePoint.Save.IResponse,
    Api.Core.ProductTierPricePoint.Save.IHeaders
  >({
    method: body?.id ? HttpMethod.put : HttpMethod.post,
    url: '/api/ProductManagement/ProductTierPricePoint',
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export async function productTierPricePointDeleteApi(
  body: Api.Core.ProductTierPricePoint.Delete.IRequest
): Promise<Api.Res<Api.Core.ProductTierPricePoint.Delete.IResponse>> {
  const serverResponse = await executeServerApi<
    Api.Core.ProductTierPricePoint.Delete.IRequest,
    Api.Core.ProductTierPricePoint.Delete.IResponse,
    Api.Core.ProductTierPricePoint.Delete.IHeaders
  >({
    method: HttpMethod.delete,
    url: createPathFromRoute('/api/ProductManagement/ProductTierPricePoint/:id', body),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}

export default class ProductTierPricePoint
  extends Model<Model.IProductTierPricePoint>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IProductTierPricePoint>
{
  id: number;
  termInMonths: number;
  priceCAD: number;
  productId: number;
  tierId: number;
  pricingDistributions?: Utils.PricingDistribution;
  subfees?: Array<Utils.SurchargeInfo>;
  productProvinceGroupId?: number;

  constructor(
    data: Partial<Model.IProductTierPricePoint> | Utils.FormData<Model.IProductTierPricePoint>,
    language?: Locale.Languages
  ) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    const { lang } = getLocales();
    return {
      id: this.id,
      title: `${this.termInMonths}${lang.month}`,
      info: `${this.priceCAD}$`,
    };
  }
  static async list(body?: Api.Core.ProductTierPricePoint.List.IRequest) {
    const { payload, ...rest } = await productTierPricePointListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new ProductTierPricePoint(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await productTierPricePointSaveApi(this);
  }
  async delete() {
    return await productTierPricePointDeleteApi({ id: this.id });
  }
}
