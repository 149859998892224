import { getLocales } from 'assets/locales/Locale';
import { usePriceSelectionTools } from '../hooks/usePriceSelectionTools';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import Icon from 'assets/components/icon/Icon';

export default function PriceAdjustmentSelectionTools() {
  const { lang } = getLocales();
  const priceSelectionTools = usePriceSelectionTools();

  return (
    <InputWrapper>
      {priceSelectionTools.distributors.length > 0 && (
        <SelectInput
          name="distributorId"
          value={priceSelectionTools.distributor?.id}
          label={lang.distributors}
          data={priceSelectionTools.distributors?.map((d) => ({
            id: d.id,
            title: d.name,
            disabled: !!priceSelectionTools.dealer && d.id !== priceSelectionTools.dealer?.representativeId,
          }))}
          onChange={({ distributorId }) => priceSelectionTools.onSelect({ distributorId })}
          style={{ width: '200px' }}
        />
      )}
      {priceSelectionTools.templates.length > 0 && (
        <SelectInput
          name="distributorTemplateId"
          value={priceSelectionTools.distributorTemplate?.id}
          label={lang.pricingDistributionTemplate}
          data={priceSelectionTools.templates?.map((t) => ({
            id: t.id,
            title: t.name,
          }))}
          onChange={({ distributorTemplateId }) =>
            priceSelectionTools.onSelect({
              distributorTemplateId,
              distributorId: priceSelectionTools.distributor?.id,
              dealerId: priceSelectionTools.dealer?.id,
            })
          }
          style={{ width: '200px' }}
        />
      )}
      {priceSelectionTools.dealers.length > 0 && (
        <SelectInput
          name="dealerId"
          value={priceSelectionTools.dealer?.id}
          label={lang.dealers}
          data={priceSelectionTools.dealers?.map((d) => ({
            id: d.id,
            title: d.name,
            actionIcon: <Icon class="fas-copy" />,
            actionTitle: 'Copy adjustments to this dealer',
            disableEdit:
              !priceSelectionTools.dealer ||
              priceSelectionTools.dealer?.id === d.id ||
              !priceSelectionTools.distributor ||
              d.representativeId !== priceSelectionTools.distributor?.id,
            disabled: !!priceSelectionTools.distributor && d.representativeId !== priceSelectionTools.distributor?.id,
          }))}
          onChange={({ dealerId }) => {
            const distributorId = priceSelectionTools.dealers?.find((it) => it.id === dealerId)?.representativeId;
            priceSelectionTools.onSelect({ dealerId, distributorId });
          }}
          style={{ width: '200px' }}
          //onEdit={(id) => dealerPricingSettings.copyTo(id, addProgressToWindow)}
        />
      )}
    </InputWrapper>
  );
}
