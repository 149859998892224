import { get, keys, merge } from 'lodash';
import { useState } from 'react';
import { AllPricingOptions, usePriceEditor } from '../hooks/usePriceEditor';
import { roundToStep } from 'assets/utils/data/Number';

type CopyAdjustment = { amount: number; unit?: Utils.CalcUnit };
type CopyPricingOptions = {
  base?: Partial<Record<string, CopyAdjustment>>;
  discount?: {
    distributions?: Partial<Record<string, CopyAdjustment>>;
    distributorDistributions?: Partial<Record<string, CopyAdjustment>>;
  };
  limitation?: {
    minAmount?: CopyAdjustment;
    maxAmount?: CopyAdjustment;
  };
  distributor?: Partial<Record<string, CopyAdjustment>>;
};

export default function usePriceEditorCopy() {
  const { copyToPrices, updatePrices } = usePriceEditor();

  const [copyPrice, setCopyPrice] = useState<CopyPricingOptions>({});
  function updateCopyPrice(partialCopyPrice: CopyPricingOptions) {
    setCopyPrice((old) => merge({}, old, partialCopyPrice));
  }
  const currency = 'CAD';
  function calculatePrice(price: number, adjustment: CopyAdjustment) {
    const _price =
      adjustment.unit === 'percent'
        ? price + price * (adjustment.amount / 100)
        : adjustment.unit === 'addition'
        ? price + adjustment.amount
        : adjustment.amount;

    return roundToStep(_price, 0.01);
  }

  function applyCopyChanges() {
    updatePrices(copyToPrices, (price) => {
      const updatedPrice: Utils.DeepPartial<AllPricingOptions> = {
        base: {
          distributions: {},
        },
        discount: {
          distributions: {},
          distributorDistributions: {},
        },
        distributor: {
          distributions: {},
        },
        limitation: {
          limitations: {},
        },
      };
      for (const templateKey of keys(copyPrice.base)) {
        updatedPrice.base.distributions[templateKey] = {
          amount: calculatePrice(get(price.base.distributions, templateKey)?.amount ?? 0, copyPrice.base[templateKey]),
        };
      }
      for (const templateKey of keys(copyPrice.distributor)) {
        updatedPrice.distributor.distributions[templateKey] = {
          amount: calculatePrice(
            get(price.distributor?.distributions, templateKey)?.amount ?? 0,
            copyPrice.distributor[templateKey]
          ),
        };
      }
      for (const templateKey of keys(copyPrice.discount?.distributions)) {
        updatedPrice.discount.distributions[templateKey] = {
          amount: calculatePrice(
            get(price.discount?.distributions, templateKey)?.amount ?? 0,
            copyPrice.discount?.distributions[templateKey]
          ),
        };
      }
      for (const templateKey of keys(copyPrice.discount?.distributorDistributions)) {
        updatedPrice.discount.distributorDistributions[templateKey] = {
          amount: calculatePrice(
            get(price.discount?.distributorDistributions, templateKey)?.amount ?? 0,
            copyPrice.discount?.distributorDistributions[templateKey]
          ),
        };
      }
      if (copyPrice.limitation?.minAmount) {
        updatedPrice.limitation.limitations.minAmount = calculatePrice(
          price.limitation.limitations.minAmount,
          copyPrice.limitation.minAmount
        );
      }
      if (copyPrice.limitation?.maxAmount) {
        updatedPrice.limitation.limitations.maxAmount = calculatePrice(
          price.limitation.limitations.maxAmount,
          copyPrice.limitation.maxAmount
        );
      }
      return updatedPrice;
    });
    setCopyPrice({});
  }

  return {
    copyPrice,
    updateCopyPrice,
    currency,
    applyCopyChanges,
  };
}
