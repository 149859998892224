import { AcceptType, ContentType, HttpMethod, HttpStatus } from 'config/Api.Config';
import { isValidValue } from '../parsersAndValidation/Validators';
import { getUrlInfo, isLocalhost, stringifyQueryString } from '../dom/UrlParsing';
import { combineStrings } from '../data/String';
import { cleanValues } from '../data/Object';

function setupRequest<Req, H extends Api.Headers.IDefault>(props: Api.Req<Req, H>) {
  const tempBody = cleanValues(props.body || {});
  let body: any;
  const isProgress = isValidValue(props.progressIndex);
  const headers = props.headers || ({} as H);
  const hasFiles = Object.values(tempBody).find((it) => it instanceof File) || props.files ? true : false;
  if ((props.headers && props.headers['content-type'] === ContentType.MultipartForm) || isProgress || hasFiles) {
    const form = new FormData();
    for (const [key, val] of Object.entries(tempBody)) {
      form.append(key, val instanceof File || typeof val === 'string' ? val : JSON.stringify(val));
    }
    for (const [id, file] of Object.entries(props.files || [])) {
      form.append(id, file);
    }
    body = form;
  } else {
    body = tempBody;
    headers['content-type'] = ContentType.JSON;
  }
  if (!headers.Accept) headers.Accept = AcceptType.JSON;

  return { body, isProgress, headers, method: props.method || HttpMethod.post, progressIndex: props.progressIndex };
}
export default async function executeServerApi<Req, Res, H extends Api.Headers.IDefault>(
  props: Api.Req<Req, H>
): Promise<Api.Res<Res>> {
  const hasBody = props.method === HttpMethod.post || props.method === HttpMethod.put;
  let payload: Res | undefined = undefined;
  let percentage = -1;
  const { body, isProgress, headers, method, progressIndex: index } = setupRequest(props);
  const { server_url, url, port } = getUrlInfo(
    `${document.location.origin}${props.url}${!hasBody ? stringifyQueryString(body) : ''}`
  );
  const finalURL = port ? url : server_url;
  try {
    if (!isProgress) {
      const apiResponse = await fetch(finalURL, {
        method,
        headers: headers as any,
        body: hasBody ? (body instanceof FormData ? body : JSON.stringify(body)) : undefined,
        credentials: 'include',
        mode: 'cors',
      });
      const status = apiResponse.status as Api.HttpStatus;
      let message: string;
      try {
        payload = await apiResponse.json();
        if (props.tryParse && typeof payload !== 'object') payload = JSON.parse(payload as any) as unknown as Res;
        if (status !== HttpStatus.ok) {
          const errors = payload as unknown as Api.IErrorResponse;
          payload = undefined;
          message = combineStrings(
            '. ',
            errors.title,
            //errors.detail,
            errors.errors
              ? Object.values(errors.errors)
                .map((it) => it.join(' '))
                .join(' ')
              : errors.detail
          );
        }
      } catch (e) {
        payload = undefined;
      }

      if (props.callback) props.callback({ status, payload, percentage, message });
      return { status, payload, percentage, message };
    } else {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', finalURL, true);
      xhr.withCredentials = true;

      // Update progress (can be used to show progress indicator)
      xhr.upload.addEventListener('progress', function (e) {
        let percentage = (e.loaded * 100.0) / e.total;
        percentage = percentage === 100 ? 99.99 : percentage;
        if (props.callback) props.callback({ status: HttpStatus.ok, index, payload, percentage });
      });

      xhr.addEventListener('readystatechange', function () {
        const status = xhr.status as Api.HttpStatus;

        if (xhr.readyState === 4 && xhr.status === HttpStatus.ok) {
          try {
            payload = JSON.parse(xhr.responseText);
          } catch (e) {
            if (isLocalhost()) console.log('Json parse error');
          }
          percentage = 100;
        }
        if (props.callback) props.callback({ status, index, payload, percentage });
      });
      xhr.send(body);
      return { status: HttpStatus.ok, index, payload, percentage: 0 };
    }
  } catch (error) {
    if (isLocalhost())
      if (props.callback)
        props.callback({ status: HttpStatus.internalServerError, index: props.progressIndex, payload, percentage });
    return { status: HttpStatus.internalServerError, index: props.progressIndex, payload, percentage };
  }
}
