import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { entries, reduce } from 'lodash';
import { PricingFilter, usePriceEditor } from '../hooks/usePriceEditor';

type PriceAdjustmentFilterAndSortProps = {
  filter: PricingFilter;
  isForCopy?: boolean;
};
export default function PriceAdjustmentFilterAndSort({ filter, isForCopy }: PriceAdjustmentFilterAndSortProps) {
  const { translate, lang } = getLocales();
  const { switchOrder, switchFilter, switchFilterCopy } = usePriceEditor();
  const areAllSelected = filter.options.every((o) => (isForCopy ? o.isEnabledForCopy : o.isSelected));

  return (
    <>
      <DropdownButton
        horizontalPosition="auto_fixed"
        verticalPosition="auto_fixed"
        constrainment="constrained"
        subcomponents={ThemeDropdownButtonPopup}
        buttonBody={
          <>
            {translate(filter.key)} (
            {reduce(filter.options, (p, c) => p + Number(isForCopy ? c.isEnabledForCopy : c.isSelected), 0)}/
            {filter.options.length})
          </>
        }
        dropdownOptions={({ animateIn }) => (
          <>
            {filter.options.length > 1 && filter.source === 'local' && (
              <Button
                class={ThemeButtonMenuItem}
                media={<Icon class={areAllSelected ? 'fas-minus-square' : 'far-square'} />}
                render={areAllSelected ? lang.selectNone : lang.selectAll}
                onClick={() => {
                  animateIn();
                  for (const opt of filter.options.filter((o) => (areAllSelected ? o.isSelected : !o.isSelected))) {
                    if (isForCopy) {
                      switchFilterCopy(filter.key, opt.value);
                    } else {
                      switchFilter(filter.key, opt.value);
                    }
                  }
                }}
              />
            )}
            <DataMap
              data={filter.options}
              render={({ data: filterOption }) => (
                <Button
                  key={
                    typeof filterOption.value === 'object'
                      ? entries(filterOption.value)
                          .map((e) => e.join(':'))
                          .join(';')
                      : filterOption.value
                  }
                  class={ThemeButtonMenuItem}
                  media={
                    <Icon
                      class={
                        (isForCopy ? filterOption.isEnabledForCopy : filterOption.isSelected)
                          ? 'fas-check-square'
                          : 'far-square'
                      }
                    />
                  }
                  render={filterOption.label}
                  onClick={() => {
                    animateIn();
                    if (isForCopy) {
                      switchFilterCopy(filter.key, filterOption.value);
                    } else {
                      switchFilter(filter.key, filterOption.value);
                    }
                  }}
                />
              )}
            />
          </>
        )}
      />
      <Button
        class={ThemeButtonCircle}
        media={<Icon class={filter.order === 'asc' ? 'fas-chevron-down' : 'fas-chevron-up'} />}
        onClick={switchOrder}
        data={filter.key}
      />
    </>
  );
}
