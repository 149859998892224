import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Contract.UnknownVertaforeStatusList.IRequest;
type Response = Api.Core.Contract.UnknownVertaforeStatusList.IResponse;
type Headers = Api.Core.Contract.UnknownVertaforeStatusList.IHeaders;
const url = '/api/Sales/Contract/UnknownVertaforeStatus';
export default async function contractUnknownVertaforeStatusListApi(): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.get,
    url,
    headers: getDefaultHeaders(),
  });
}
