import useForm from 'assets/components/form/hooks/Form';
import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import { flatten } from 'lodash';
import ProductEnhancement, {
  productEnhancementDeleteApi,
  productEnhancementSaveApi,
} from 'models/productManagement/productEnhancement/Model.ProductEnhancement';
import {
  productEnhancementDocumentDeleteApi,
  productEnhancementDocumentSaveApi,
  productEnhancementDocumentUpdateAllFieldsApi,
} from 'models/productManagement/productEnhancementDocument/ProductEnhancementDocument';
import { useEffect, useMemo, useState } from 'react';
import { productEnhancementIndexRoute } from './ProductEnhancement.Index';

export enum ProductEnhancementAction {
  EDIT = 'edit',
  DELETE = 'delete',
}
export enum ProductEnhancementEditTab {
  INFO = 'info',
  DOCUMENTS = 'documents',
}
export type VerifyProductAction = Utils.VerifyExtends<
  Module.ProductManagement.ProductEnhancement.Actions,
  Utils.ValueOf<typeof ProductEnhancementAction>
>;
export type VerifyProductEnhancementEditTab = Utils.VerifyExtends<
  Module.ProductManagement.ProductEnhancement.EditTab,
  Utils.ValueOf<typeof ProductEnhancementEditTab>
>;
export default function useProductEnhancement() {
  const { permissions } = useAuthStore();

  const { lang } = getLocales();
  const pageRouter = usePageRouter<
    Module.ProductManagement.ProductEnhancement.Params,
    Module.ProductManagement.ProductEnhancement.Query
  >({
    route: productEnhancementIndexRoute,
  });
  const productEnhancementId = pageRouter.params.id ? parseInt(pageRouter.params.id) : null;
  const [isLoading, setIsLoading] = useState(false);
  const canView = permissions.CREATE_PRODUCT || permissions.EDIT_PRODUCT || permissions.DELETE_PRODUCT;

  const listApi = useApi({
    action: ProductEnhancement.list,
    default: {
      limit: defaultLimit,
      offset: defaultOffset,
    },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({ ...pageRouter.params }, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const {
    payload: productEnhancement,
    execute: reloadProductEnhancement,
    isExecuting: isEditLoading,
    reset: resetProductEnhancement,
  } = useApi(
    {
      action: ProductEnhancement.find,
      body: { id: productEnhancementId },
      default: null,
      wait: !productEnhancementId,
    },
    [productEnhancementId]
  );
  useEffect(() => {
    if (!productEnhancementId) resetProductEnhancement(true);
  }, [productEnhancementId]);

  const deleteApi = useApi({
    action: productEnhancementDeleteApi,
    callback: () => {
      listApi.execute((body) => body);
      pageRouter.redirect({ ...pageRouter.params, action: undefined }, { ...pageRouter.query, editTab: undefined });
    },
    wait: true,
  });

  //#region Data editing
  const currentProductEnhancement = productEnhancement?.toJSON();
  const form = useForm<Utils.FormData<Model.IProductEnhancement>>(
    {
      default: currentProductEnhancement
        ? {
            ...currentProductEnhancement,
            productType: currentProductEnhancement?.productType ?? 'WARRANTY_TIER-BASED',
          }
        : {
            productType: 'WARRANTY_TIER-BASED',
            products: [],
          },
      onSubmit: async ({ productEnhancementDocuments, ...productEnhancement }) => {
        const productSaveResponse = await productEnhancementSaveApi(productEnhancement);
        const productEnhancementId = productSaveResponse.payload;
        if (productEnhancementDocuments?.length) {
          await productEnhancementDocumentUpdateAllFieldsApi({
            productEnhancementId: productEnhancement.id || productEnhancementId,
            productEnhancementAdditionalFields: flatten(
              productEnhancementDocuments.map((d) => d.productEnhancementAdditionalFields)
            ),
          });
        }
        if (productEnhancementId?.toString() === pageRouter.params.id) {
          reloadProductEnhancement({ id: productEnhancementId });
        } else {
          pageRouter.updateParams({
            action: ProductEnhancementAction.EDIT,
            id: productEnhancementId.toString(),
          });
        }
      },
      validation: (data, errors) => {
        if (!data.name) errors.name = lang.mustNotBeEmpty;
      },
    },
    [productEnhancement]
  );
  const newDocuments = form.data.productEnhancementDocuments?.filter((d) => !d.id) || [];
  const uploadedDocuments = form.data.productEnhancementDocuments?.filter((d) => !!d.id) || [];
  //#endregion
  function reload() {
    reloadProductEnhancement((body) => body);
  }
  async function uploadFile(params: Pick<Model.IProductDocument, 'formFile' | 'productDocumentType'>) {
    if (!params.formFile || !params.productDocumentType) return;
    setIsLoading(true);
    await productEnhancementDocumentSaveApi({
      ...params,
      productEnhancementId: productEnhancement.id,
    });
    setIsLoading(false);
    reload();
    return;
  }
  async function deleteFile(documentId: number) {
    if (!documentId) return;
    setIsLoading(true);
    await productEnhancementDocumentDeleteApi({ id: form.data?.id, documentId });
    setIsLoading(false);
    reload();
  }

  return {
    reload,
    uploadFile,
    deleteFile,
    newDocuments,
    uploadedDocuments,
    productEnhancement,
    pageRouter,
    listApi,
    deleteApi,
    form,
    selected,
    permissions,
    isEditLoading: isEditLoading || isLoading,
    canView,
    views: {
      [ProductEnhancementAction.DELETE]:
        pageRouter.params.action === ProductEnhancementAction.DELETE && selected && permissions.DELETE_PRODUCT,
      [ProductEnhancementAction.EDIT]:
        pageRouter.params.action === ProductEnhancementAction.EDIT &&
        ((permissions.CREATE_PRODUCT && !selected) || (permissions.EDIT_PRODUCT && selected)),
    },
  };
}

export function useProductEnhancementProvider() {
  return useDataProvider<ReturnType<typeof useProductEnhancement>>();
}
