import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import { addLog } from 'assets/components/feedback/Feedback';
import Icon from 'assets/components/icon/Icon';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import NumberInputTextValue from 'assets/components/inputs/number/NumberInputTextValue';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { HttpStatus } from 'config/Api.Config';
import ClaimJobItemPayee from 'models/claims/claimJobItemPayee/Model.ClaimJobItemPayee';
import payeeSaveApi from 'models/claims/payee/save/Api.Payee.Save';
import { CountryCode, CountryCodeFallback } from 'models/enums/CountryCode';
import LineItem from 'models/enums/LineItem';
import PaymentStatus from 'models/enums/PaymentStatus';
import { CSSProperties, useState } from 'react';
import { useClaimEditJobPayeeItem, useClaimJobEdit } from '../ClaimEdit.Job.Hooks';
import ClaimEditJobItemNewPayee from './components/ClaimEdit.Job.Item.NewPayee';
import ClaimStatusCode from 'models/enums/ClaimStatusCode';

type Props = {
  lossCodes: Model.ILossCode[];
  index: number;
  ordinalNumber: number;
  data?: Utils.FormData<Model.IClaimJobItem> & { isWarrantyProduct: boolean };
  onDelete(data: Utils.FormData<Model.IClaimJobItem>, index: number): void;
  onUpdate(data: Partial<Model.IClaimJobItem>): void;
};

export const addRowButtonStyles: CSSProperties = {
  backgroundColor: 'rgb(240, 139, 7)',
  color: Theme.Color.textAlt,
  marginLeft: 'auto',
};

export default function ClaimEditJobItem({ data, index, ordinalNumber, lossCodes, onDelete, onUpdate }: Props) {
  const { lang } = getLocales();
  const { getJobItemDefaultValues, isClaimJobItemEditable, setNewPayeeId, newPayeeId, claim } = useClaimJobEdit();
  const { payeeList, setOffset, setSearch, limit, offset, count, permissions } = useClaimEditJobPayeeItem();
  const [isCreatingPayee, setIsCreatingPayee] = useState<boolean>(false);

  const payee = new ClaimJobItemPayee(data?.claimJobItemPayee);
  const countryCode =
    payee?.payee?.countryCode ?? payee?.client?.countryCode ?? payee?.dealer?.countryCode ?? CountryCode.CAN;
  const payeeInfo = payee?.client ?? payee?.dealer ?? payee?.payee;

  return (
    <tr>
      <td>{ordinalNumber + 1}</td>
      <td>
        {payeeInfo &&
        data?.id &&
        (claim?.statusCode === ClaimStatusCode.OPEN_APPROVED_TO_PAY || isClaimJobItemEditable) ? (
          payeeInfo.displayInfo?.title
        ) : (
          <SelectInput
            name="claimJobItemPayee"
            data={payeeList?.map((it) => ({ ...it.displayInfo, id: `${it.displayInfo.id}-${it.payeeType?.id}` }))}
            displayText={payee?.displayInfo?.title}
            value={`${
              data?.claimJobItemPayee?.payee?.id ??
              data?.claimJobItemPayee?.client?.id ??
              data?.claimJobItemPayee?.dealer?.id
            }-${data?.claimJobItemPayee?.payeeType?.id}`}
            limit={limit}
            offset={offset}
            count={count}
            onAdd={() => {
              setNewPayeeId(null);
              setIsCreatingPayee(true);
            }}
            onSearch={setSearch}
            onOffsetChange={({ value: offset }) => setOffset(offset)}
            onChange={({ claimJobItemPayee: id }) => {
              const itemPayee = payeeList?.find(
                (it) => `${it.payee?.id ?? it.dealer?.id ?? it.client?.id}-${it.payeeType?.id}` === id
              );
              onUpdate({
                claimJobItemPayee: itemPayee,
                ...getJobItemDefaultValues({ jobItemTypeCode: data?.jobItemTypeCode, claimJobItemPayee: itemPayee }),
              });
            }}
          />
        )}
      </td>
      <td>
        <SelectInput
          name="lossCode"
          data={lossCodes.map((it) => ({
            id: it.code,
            title: it.descnEn,
          }))}
          disabled={isClaimJobItemEditable}
          value={data?.lossCode}
          onChange={onUpdate}
        />
      </td>
      {data?.jobItemTypeCode === LineItem.part && (
        <td>
          <TextInput
            name="partNumber"
            disabled={isClaimJobItemEditable}
            value={data?.partNumber}
            onChange={onUpdate}
            maxLength={20}
          />
        </td>
      )}
      <td>
        <SelectInput
          name="statusCode"
          data={Object.values(PaymentStatus).map((it) => ({
            id: it,
            title: it,
          }))}
          disabled={isClaimJobItemEditable}
          value={data?.statusCode}
          onChange={onUpdate}
        />
      </td>
      {data?.jobItemTypeCode === LineItem.labour && (
        <>
          <td>
            <NumberInput
              name="hoursWorked"
              value={data?.hoursWorked}
              onChange={onUpdate}
              disabled={isClaimJobItemEditable}
              error={
                (data?.hoursWorked === null || data?.hoursWorked < 0) &&
                lang.mustBeMoreThanAmount.replace('{amount}', '0')
              }
            />
          </td>
          <td>
            <NumberInput
              name="labourRate"
              value={data?.labourRate !== 0 ? data?.labourRate : payee?.dealer?.labourRate ?? payee?.payee?.labourRate}
              onChange={onUpdate}
              readOnly
              style={{ input: { textAlign: 'right' } }}
              icon={<Icon>{data.currency}</Icon>}
            />
          </td>
        </>
      )}
      <td>
        <NumberInput
          name="correction"
          value={Number(data?.correction?.toFixed(2))}
          onChange={onUpdate}
          step={0.01}
          readOnly={
            isClaimJobItemEditable ||
            (data.jobItemTypeCode === LineItem.deductible && data?.isWarrantyProduct) ||
            data.jobItemTypeCode === LineItem.labour
          }
          style={{ input: { textAlign: 'right' } }}
          icon={<Icon>{data.currency}</Icon>}
        />
      </td>
      <td>
        <NumberInput
          name="adjudication"
          value={Number(data?.adjudication?.toFixed(2))}
          onChange={onUpdate}
          step={0.01}
          disabled={isClaimJobItemEditable}
          style={{ input: { textAlign: 'right' } }}
          icon={<Icon>{data.currency}</Icon>}
        />
      </td>
      <td>
        <NumberInput
          name="gstHst"
          value={Number(((data?.gstHst || 0.0) * data.adjudication).toFixed(2))}
          onChange={({ gstHst }) =>
            onUpdate({
              gstHst: gstHst / data.adjudication,
            })
          }
          step={0.01}
          readOnly={isClaimJobItemEditable}
          error={
            data?.gstHst > 1 &&
            lang.mustBeEqualOrLessThanAmount.replace('{amount}', `Adjusted amt: ${data.adjudication.toFixed(2)}`)
          }
          style={{ input: { textAlign: 'right' } }}
          icon={<Icon>{data.currency}</Icon>}
        />
      </td>
      {(countryCode === CountryCode.CAN || countryCode === CountryCodeFallback.CA) && (
        <td>
          <NumberInput
            name="provincialSalesTax"
            value={Number(((data?.provincialSalesTax || 0.0) * data.adjudication).toFixed(2))}
            readOnly={isClaimJobItemEditable}
            onChange={({ provincialSalesTax }) =>
              onUpdate({
                provincialSalesTax: provincialSalesTax / data.adjudication,
              })
            }
            error={
              data?.provincialSalesTax > 1 &&
              lang.mustBeEqualOrLessThanAmount.replace('{amount}', `Adjusted amt: ${data.adjudication.toFixed(2)}`)
            }
            step={0.01}
            style={{ input: { textAlign: 'right' } }}
            icon={<Icon>{data.currency}</Icon>}
          />
        </td>
      )}
      <td>
        <NumberInputTextValue
          name="total"
          value={Number((data.adjudication + data.adjudication * (data?.gstHst + data?.provincialSalesTax)).toFixed(2))}
          readOnly
          onChange={onUpdate}
          style={{ input: { textAlign: 'right' } }}
          icon={<Icon>{data.currency}</Icon>}
        />
      </td>
      <td>
        <Button
          class={ThemeButtonCircle}
          media={<Icon class="fas-trash" />}
          onClick={(data) => onDelete(data, index)}
          data={data}
          htmlElementProps={{
            title: lang.delete,
          }}
          disabled={isClaimJobItemEditable}
        />
      </td>
      {(permissions.CREATE_PAYEE || permissions.EDIT_PAYEE) && isCreatingPayee && !newPayeeId && (
        <ClaimEditJobItemNewPayee
          onCancel={() => setIsCreatingPayee(false)}
          onSubmit={async (payeeData) => {
            const res = await payeeSaveApi(payeeData);
            if (res.status === HttpStatus.ok) {
              addLog({ success: lang.saveSuccess });
              setNewPayeeId(res?.payload);
              setIsCreatingPayee(false);
            } else {
              addLog({ error: res.message });
              setIsCreatingPayee(false);
            }
          }}
        />
      )}
    </tr>
  );
}
