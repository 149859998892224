enum CancellationReason {
  CUSTOMER_CANCELLATION = 'Customer Cancellation',
  ADMIN_CANCELLATION = 'Admin Cancellation',
  REPOSSESSION = 'Repossession',
  WRITE_OFF = 'Write-Off',
  OTHER = 'Other',
}

export default CancellationReason;
export type VerifyCancellationReason = Utils.VerifyExtends<
  Model.Enum.CancellationReason,
  Utils.ValueOf<typeof CancellationReason>
>;
