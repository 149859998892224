import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import { HttpStatus, productManagementHomeRoute } from 'config/Api.Config';
import PriceAdjustmentsPage from './components/PriceAdjustmentPage';
import { PriceEditorProvider } from './hooks/usePriceEditor';
import useProductPrices from './hooks/useProductPrices';
import { PriceSelectionToolsProvider } from './hooks/usePriceSelectionTools';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';

export const priceAdjustmentsIndexRoute =
  productManagementHomeRoute + '/priceAdjustments/:dataType/:id/:enhancementId?';

export const buildPriceAdjustmentsPath = (params: Module.ProductManagement.PriceAdjustments.Params) =>
  createPathFromRoute(priceAdjustmentsIndexRoute, params);

export default function PriceAdjustmentsIndex() {
  const pageRouter = usePageRouter<
    Module.ProductManagement.PriceAdjustments.Params,
    Module.ProductManagement.PriceAdjustments.Query
  >({
    route: priceAdjustmentsIndexRoute,
  });
  const id = parseInt(pageRouter.params.id);
  const dataType = pageRouter.params.dataType;
  const dealerId = pageRouter.query.dealerId;
  const distributorId = pageRouter.query.distributorId;
  const distributorTemplateId = pageRouter.query.distributorTemplateId;

  const { productPriceInfo, save, reload } = useProductPrices({
    id,
    dataType,
    dealerId,
    distributorId,
  });

  if (productPriceInfo.hasAccess === false) return <ErrorPage status={HttpStatus.unauthorized} />;
  if (!id) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <PriceSelectionToolsProvider
      onSelect={(newQuery) => pageRouter.redirect(pageRouter.params, newQuery)}
      id={id}
      dataType={dataType}
      dealerId={dealerId}
      distributorId={distributorId}
      distributorTemplateId={productPriceInfo.distributorTemplateId ?? distributorTemplateId}
      render={(priceSelectionTools) => (
        <PriceEditorProvider
          type={productPriceInfo.type}
          prices={productPriceInfo.prices}
          usedFilters={productPriceInfo.usedFilters}
          dataMappings={productPriceInfo.dataMappings}
          onSave={save}
          onSaved={reload}
          templates={{
            base: productPriceInfo.templateKeys ?? [],
            distributor: priceSelectionTools.distributorTemplate?.keys,
          }}
        >
          <PriceAdjustmentsPage
            isLoading={productPriceInfo.isLoading}
            title={productPriceInfo.title}
            id={id}
            dataType={dataType}
          />
        </PriceEditorProvider>
      )}
    />
  );
}
