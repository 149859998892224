import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageSplit from 'assets/components/page/themes/Theme.Page.Split';
import { getLocales } from 'assets/locales/Locale';
import {
  ProductEnhancementAction,
  ProductEnhancementEditTab,
  useProductEnhancementProvider,
} from '../ProductEnhancement.Hooks';
import ProductEnhancementEditDocuments from './edit/ProductEnhancement.Edit.Documents';
import ProductEnhancementEditInfo from './edit/ProductEnhancement.Edit.Info';
import ProductEnhancementEditNavigation from './edit/ProductEnhancement.Edit.Navigation';
import ProductPricingAdjustmentButton from 'module/productManagement/components/productPricingButton/ProductPricingAdjustmentButton';

export default function ProductEnhancementEdit() {
  const { lang } = getLocales();
  const { pageRouter, form, productEnhancement, isEditLoading, newDocuments } = useProductEnhancementProvider();

  return (
    <Page
      class={ThemePageSplit}
      header={
        productEnhancement || form.data ? (
          <Card
            class={ThemeCardRow}
            {...(productEnhancement?.displayInfo || {
              title: lang.addNew,
              subtitle: lang.productEnhancement,
              media: 'fas-plus',
            })}
          />
        ) : (
          lang.noInfo
        )
      }
      isLoading={isEditLoading}
      sidebar={
        <>
          {(pageRouter.query.editTab === ProductEnhancementEditTab.INFO || !pageRouter.query.editTab) && (
            <ProductEnhancementEditInfo />
          )}
          {pageRouter.query.editTab === ProductEnhancementEditTab.DOCUMENTS && <ProductEnhancementEditDocuments />}
        </>
      }
      render={<ProductEnhancementEditNavigation />}
      footer={
        <>
          <ProductPricingAdjustmentButton
            id={productEnhancement?.id}
            productType={productEnhancement?.productType ?? 'WARRANTY_TIER-BASED'}
            dataType="productEnhancement"
            label={lang.adjustments}
          />
          <Button
            onClick={form.submit}
            media={<Icon class="fas-save" />}
            render={
              <>
                {lang.save}
                {newDocuments.length > 0 && (
                  <>
                    &nbsp;+&nbsp;{newDocuments.length}
                    &nbsp;
                    <Icon class="fas-paperclip" />
                  </>
                )}
              </>
            }
            active={!form.hasErrors && form.isUpdated}
            disabled={form.hasErrors || !form.isUpdated}
            isLoading={form.isSubmitted}
            class={ThemeButtonCircle}
          />
          {productEnhancement && (
            <Button
              onClick={pageRouter.updateParams}
              data={{
                id: productEnhancement?.id?.toString(),
                action: ProductEnhancementAction.DELETE,
              }}
              class={ThemeButtonCircle}
              disabled={!productEnhancement.id}
              render={lang.delete}
              media={<Icon class="fas-trash" />}
            />
          )}
        </>
      }
    />
  );
}
