import PurchaseState from 'models/enums/PurchaseState';
import Product from 'models/productManagement/product/Model.Product';
import contractPricingInfoApi from 'models/sales/contract/pricingInfo/Api.Contract.PricingInfo';

export type ProductSubfeeInfo = {
  subfeeId: number;
  name: string;
  amount: number;
  currency: string;
};
export type ProductEnhancementInfo = {
  enhancementId: number;
  enhancementPricePointId: number;
  name: string;
  priceCAD: number;
  originalPriceCAD: number;
  discountPriceCAD: number;
  distributorPriceCAD: number;
  pricingDistributions: Utils.PricingDistribution;
  distributorDistributions: Utils.PricingDistribution;
};

export type PricingPlanInfo = {
  pricingPlanId: number;
  name: string;
  primaryDescription?: string;
  secondaryDescription?: string;
  isForNewVehicle: boolean;
  minOdometerKm: number;
  maxOdometerKm: number;
  minPriceCAD: number;
  maxPriceCAD: number;
  warrantyOption: string;
};

export type ProductPriceInfo = {
  pricePointId: number;
  priceCAD: number;
  salePriceCAD: number; //Can be changed through input & subfee (surcharge) price should be added to it
  minPriceCAD: number;
  maxPriceCAD: number;
  originalPriceCAD: number;
  discountPriceCAD: number;
  distributorPriceCAD: number;
  pricingDistributions: Utils.PricingDistribution;
  distributorDistributions: Utils.PricingDistribution;
  termInMonths?: number;
  maxKm?: number;
  deductible?: number;
  maxVehiclePriceCAD?: number;
  minVehiclePriceCAD?: number;
  customInfo?: string; //Custom dimension in pricing table
  customInfoGroup?: string; //Additional custom dimension in pricing table
  tier?: string;
  tierId?: number;
  class?: string;
  classId?: number;
  subfees?: Array<ProductSubfeeInfo>;
  enhancements?: Array<ProductEnhancementInfo>;
  pricingPlanInfo?: PricingPlanInfo;
};
export type ProductPricingInfo = {
  product: Model.IProduct;
  customPrices: Array<ProductPriceInfo>;
  nonWarrantyPrices: Array<ProductPriceInfo>;
  warrantyTierPrices: Array<ProductPriceInfo>;
  warrantyClassPrices: Array<ProductPriceInfo>;
};

function mapPriceInfo(priceInfo: Api.Core.Contract.PricingInfo.PriceInfo) {
  const productpriceInfo: ProductPriceInfo = {
    pricePointId: priceInfo.pricePointId,
    priceCAD: priceInfo.suggestedPriceCAD,
    salePriceCAD: priceInfo.suggestedPriceCAD,
    minPriceCAD: 0,
    maxPriceCAD: priceInfo.maxPriceCAD,
    originalPriceCAD: priceInfo.originalPriceCAD,
    discountPriceCAD: priceInfo.discountPriceCAD,
    distributorPriceCAD: priceInfo.distributorPriceCAD,
    pricingDistributions: priceInfo.pricingDistributions,
    distributorDistributions: priceInfo.distributorDistributions,
    subfees: priceInfo.subfees ?? [],
    enhancements: priceInfo.enhancements ?? [],
    termInMonths: priceInfo.termInMonths,
    customInfo: priceInfo.customInfo,
    customInfoGroup: priceInfo.customInfoGroup,
    maxVehiclePriceCAD: priceInfo.maxVehiclePriceCAD,
    minVehiclePriceCAD: priceInfo.minVehiclePriceCAD,
    tier: priceInfo.tier,
    tierId: priceInfo.tierId,
    maxKm: priceInfo.maxKm,
    deductible: priceInfo.deductible,
    class: priceInfo.class,
    classId: priceInfo.classId,
    pricingPlanInfo: !priceInfo.pricingPlanInfo
      ? null
      : {
          pricingPlanId: priceInfo.pricingPlanInfo.pricingPlanId,
          primaryDescription: priceInfo.pricingPlanInfo.primaryDescription,
          secondaryDescription: priceInfo.pricingPlanInfo.secondaryDescription,
          name: priceInfo.pricingPlanInfo.name,
          isForNewVehicle: priceInfo.pricingPlanInfo.isForNewVehicle ?? false,
          minOdometerKm: priceInfo.pricingPlanInfo.minOdometerKm,
          maxOdometerKm: priceInfo.pricingPlanInfo.maxOdometerKm,
          warrantyOption: priceInfo.pricingPlanInfo.warrantyOption,
          minPriceCAD: priceInfo.pricingPlanInfo.minPriceCAD,
          maxPriceCAD: priceInfo.pricingPlanInfo.maxPriceCAD,
        },
  };
  return productpriceInfo;
}
type CalculatePricesForContractProps = {
  dealer: Model.IDealer;
  distributor?: Model.IDistributor;
  vehicle: Model.IVehicle;
};
export default async function calculatePricesForContract({
  dealer,
  distributor,
  vehicle,
}: CalculatePricesForContractProps) {
  const dealerProductIds = dealer.products?.filter((p) => p.isActive).map((p) => p.id) ?? [];
  const productsResponse = dealerProductIds?.length ? await Product.list({ productId: dealerProductIds }) : null;
  const calculatedPricesResponse = await contractPricingInfoApi({
    dealerId: dealer.id,
    distributorId: distributor?.id ?? dealer?.representativeId,
    vehicleModelId: vehicle?.vehicleModel?.id ?? 0,
    purchasePrice: vehicle?.purchasePrice || 0,
    odometerReading: vehicle?.posOdometerReading || 0,
    isNew: vehicle?.purchaseState === PurchaseState.NEW,
  });

  const finalProducts: ProductPricingInfo[] = [];
  for (const product of productsResponse?.payload?.data ?? []) {
    const calculatedPrices = calculatedPricesResponse?.payload?.find((it) => it.productId === product.id);
    if (
      !calculatedPrices?.customPrices?.length &&
      !calculatedPrices?.nonWarrantyPrices?.length &&
      !calculatedPrices?.warrantyTierPrices?.length &&
      !calculatedPrices?.warrantyClassPrices?.length
    ) {
      continue;
    }
    finalProducts.push({
      product,
      customPrices: calculatedPrices.customPrices?.map(mapPriceInfo) ?? [],
      nonWarrantyPrices: calculatedPrices.nonWarrantyPrices?.map(mapPriceInfo) ?? [],
      warrantyTierPrices: calculatedPrices.warrantyTierPrices?.map(mapPriceInfo) ?? [],
      warrantyClassPrices: calculatedPrices.warrantyClassPrices?.map(mapPriceInfo) ?? [],
    });
  }
  return finalProducts;
}
