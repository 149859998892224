import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import Icon from 'assets/components/icon/Icon';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import { getLocales } from 'assets/locales/Locale';
import { defaultInputDateFormat } from 'assets/utils/data/Date';
import CancellationReason from 'models/enums/CancellationReason';
import { CancellationForm } from '../Sales.Contract.Cancellation';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';

const ContractCancellationReasonForm: React.FC = () => {
  const { lang } = getLocales();
  const { form } = useModalForm<CancellationForm>();

  function updateContractCancelationRequest(
    contractCancelationRequest: Api.Core.Contract.SetStatus.ContractCancellationRequest
  ) {
    if (
      contractCancelationRequest.cancellationReason !== undefined &&
      contractCancelationRequest.cancellationReason !== CancellationReason.OTHER
    ) {
      contractCancelationRequest.cancellationReasonDescription = null;
    }

    form.merge({ contractCancelationRequest });
  }

  return (
    <FormWrapper>
      <DateInput
        name="cancellationDate"
        label={lang.cancellationDate}
        dateTimeFormat={defaultInputDateFormat}
        isLocal={true}
        value={form?.data?.contractCancelationRequest?.cancellationDate}
        error={form?.errors?.contractCancelationRequest?.cancellationDate}
        onChange={updateContractCancelationRequest}
        icon={<Icon class="fas-calendar-alt" />}
      />
      <NumberInput
        name="cancellationOdometerReading"
        label={lang.odometerReading}
        value={form?.data?.contractCancelationRequest?.cancellationOdometerReading}
        error={form?.errors?.contractCancelationRequest?.cancellationOdometerReading}
        onChange={updateContractCancelationRequest}
      />
      <SelectInput
        name="cancellationReason"
        label={lang.cancellationReason}
        value={form?.data?.contractCancelationRequest?.cancellationReason}
        error={form?.errors?.contractCancelationRequest?.cancellationReason}
        data={Object.values(CancellationReason).map((id) => ({
          id,
          title: id,
        }))}
        onChange={updateContractCancelationRequest}
      />
      {form.data?.contractCancelationRequest?.cancellationReason === CancellationReason.OTHER && (
        <MultilineTextInput
          name="cancellationReasonDescription"
          label={lang.cancellationReasonDescription}
          value={form?.data?.contractCancelationRequest?.cancellationReasonDescription}
          error={form?.errors?.contractCancelationRequest?.cancellationReasonDescription}
          onChange={updateContractCancelationRequest}
        />
      )}
    </FormWrapper>
  );
};
export default ContractCancellationReasonForm;
