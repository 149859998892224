import Model from 'models/Model';
import Product from '../product/Model.Product';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import PricingDistributionTemplate from '../pricingDistributionTemplate/Model.PricingDistributionTemplate';
import ProductEnhancementDocument from '../productEnhancementDocument/ProductEnhancementDocument';

export async function productEnhancementSaveApi(body?: Api.Core.ProductEnhancement.Save.IRequest) {
  const serverResponse = await executeServerApi<
    Api.Core.ProductEnhancement.Save.IRequest,
    Api.Core.ProductEnhancement.Save.IResponse,
    Api.Core.ProductEnhancement.Save.IHeaders
  >({
    method: body?.id ? HttpMethod.put : HttpMethod.post,
    url: '/api/ProductManagement/ProductEnhancement',
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export async function productEnhancementListApi(body?: Api.Core.ProductEnhancement.List.IRequest) {
  return await executeServerApi<
    Api.Core.ProductEnhancement.List.IRequest,
    Api.Core.ProductEnhancement.List.IResponse,
    Api.Core.ProductEnhancement.List.IHeaders
  >({
    method: HttpMethod.get,
    url: createPathFromRoute('/api/ProductManagement/ProductEnhancement'),
    body,
    headers: getDefaultHeaders(),
  });
}
export async function productEnhancementFindApi(body: Api.Core.ProductEnhancement.Find.IRequest) {
  return await executeServerApi<
    Api.Core.ProductEnhancement.Find.IRequest,
    Api.Core.ProductEnhancement.Find.IResponse,
    Api.Core.ProductEnhancement.Find.IHeaders
  >({
    method: HttpMethod.get,
    url: createPathFromRoute('/api/ProductManagement/ProductEnhancement/:id', body),
    headers: getDefaultHeaders(),
  });
}
export async function productEnhancementDeleteApi(body: Api.Core.ProductEnhancement.Delete.IRequest) {
  const serverResponse = await executeServerApi<
    Api.Core.ProductEnhancement.Delete.IRequest,
    Api.Core.ProductEnhancement.Delete.IResponse,
    Api.Core.ProductEnhancement.Delete.IHeaders
  >({
    method: HttpMethod.delete,
    url: createPathFromRoute('/api/ProductManagement/ProductEnhancement/:id', body),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export default class ProductEnhancement
  extends Model<Model.IProductEnhancement>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IProductEnhancement>
{
  id: number;
  name: string;
  productType?: Model.Enum.ProductType;
  products?: Product[];
  pricingDistributionTemplateId?: number;
  pricingDistributionTemplate?: PricingDistributionTemplate;
  productEnhancementDocuments?: ProductEnhancementDocument[];

  constructor(
    data: Partial<Model.IProductEnhancement> | Utils.FormData<Model.IProductEnhancement>,
    language?: Locale.Languages
  ) {
    super(
      {
        products: [Product],
        productEnhancementDocuments: [ProductEnhancementDocument],
        pricingDistributionTemplate: PricingDistributionTemplate,
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id?.toString(),
    };
  }

  static async list(body?: Api.Core.ProductEnhancement.List.IRequest) {
    const { payload, ...rest } = await productEnhancementListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new ProductEnhancement(it)),
        count: payload.count,
      },
    };
  }
  static async find(params: Api.Core.ProductEnhancement.Find.IRequest) {
    const { payload, ...rest } = await productEnhancementFindApi(params);
    return {
      ...rest,
      payload: payload ? new ProductEnhancement(payload) : null,
    };
  }
  async save() {
    return await productEnhancementSaveApi(this);
  }
  async delete() {
    return await productEnhancementDeleteApi({ id: this.id });
  }
}
