import Icon from 'assets/components/icon/Icon';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import { getLocales } from 'assets/locales/Locale';
import ClaimJobItemPayee from 'models/claims/claimJobItemPayee/Model.ClaimJobItemPayee';
import LineItem from 'models/enums/LineItem';
import { useClaimEditJobPayeeItem, useClaimJobEdit } from '../ClaimEdit.Job.Hooks';

type Props = {
  data?: Utils.FormData<Model.IClaimJobItem> & { isWarrantyProduct: boolean };
  onUpdate(data: Partial<Model.IClaimJobItem>): void;
};

export default function ClaimEditJobItemDeductible({ data, onUpdate }: Props) {
  const { lang } = getLocales();
  const { getJobItemDefaultValues, isClaimJobItemEditable } = useClaimJobEdit();
  const { payeeList, setOffset, setSearch, limit, offset, count } = useClaimEditJobPayeeItem();

  const payee = new ClaimJobItemPayee(data?.claimJobItemPayee);
  const payeeInfo = payee?.client ?? payee?.dealer ?? payee?.payee;

  return (
    <tr>
      <td>
        {payeeInfo && data?.id && isClaimJobItemEditable ? (
          payeeInfo.displayInfo?.title
        ) : (
          <SelectInput
            name="claimJobItemPayee"
            data={payeeList?.map((it) => ({ ...it.displayInfo, id: `${it.displayInfo.id}-${it.payeeType?.id}` }))}
            displayText={payee?.displayInfo?.title}
            limit={limit}
            offset={offset}
            count={count}
            onOffsetChange={({ value: offset }) => setOffset(offset)}
            onSearch={setSearch}
            disabled={isClaimJobItemEditable}
            error={!data?.claimJobItemPayee && data.jobItemTypeCode === LineItem.deductible && lang.mustNotBeEmpty}
            value={`${
              data?.claimJobItemPayee?.payee?.id ??
              data?.claimJobItemPayee?.client?.id ??
              data?.claimJobItemPayee?.dealer?.id
            }-${data?.claimJobItemPayee?.payeeType?.id}`}
            onChange={({ claimJobItemPayee: id }) => {
              const itemPayee = payeeList?.find(
                (it) => `${it.payee?.id ?? it.dealer?.id ?? it.client?.id}-${it.payeeType?.id}` === id
              );
              onUpdate({
                claimJobItemPayee: itemPayee,
                ...getJobItemDefaultValues({ claimJobItemPayee: itemPayee }),
              });
            }}
          />
        )}
      </td>
      <td>
        <NumberInput
          name="correction"
          value={data?.correction}
          onChange={onUpdate}
          step={0.01}
          disabled={isClaimJobItemEditable || (data.jobItemTypeCode === LineItem.deductible && data?.isWarrantyProduct)}
          style={{ input: { textAlign: 'right' } }}
          icon={<Icon>{data.currency}</Icon>}
        />
      </td>
      <td>
        <NumberInput
          name="adjudication"
          value={data?.adjudication}
          onChange={onUpdate}
          step={0.01}
          disabled={isClaimJobItemEditable}
          style={{ input: { textAlign: 'right' } }}
          icon={<Icon>{data.currency}</Icon>}
        />
      </td>
      <td style={{ width: 'auto' }}>
        <NumberInput
          name="total"
          value={data?.adjudication}
          readOnly
          onChange={onUpdate}
          style={{ input: { textAlign: 'right' } }}
          icon={<Icon>{data.currency}</Icon>}
        />
      </td>
    </tr>
  );
}
