enum VertaforeDocumentStatuses {
  UNKNWON = 'UNKNOWN',
  NO_UPLOADING = 'NO_UPLOADING',
  NOT_UPLOADED = 'NOT_UPLOADED',
  UNSIGNED_UPLOADED = 'UNSIGNED_UPLOADED',
  SIGNED_UPLOADED = 'SIGNED_UPLOADED',
}

export default VertaforeDocumentStatuses;
export type VerifyVertaforeDocumentStatuses = Utils.VerifyExtends<
  Model.Enum.VertaforeDocumentStatuses,
  Utils.ValueOf<typeof VertaforeDocumentStatuses>
>;
