import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import React from 'react';
import { SalesContractInfoTab, useSalesContractProvider } from '../../Sales.Contract.Hooks';
import FormatValue from 'assets/components/formatValue/FormatValue';
import CancellationReason from 'models/enums/CancellationReason';
import ContractStatusCode from 'models/enums/ContractStatusCode';
import { dateDef, defaultDateFormat, defaultServerDateFormat } from 'assets/utils/data/Date';

const titleStyle: React.CSSProperties = { fontSize: Theme.FontSize.L, fontWeight: 'bold' };
export default function ContractInfoNavigation() {
  const { lang } = getLocales();
  const { pageRouter, selected, revisionsApi, subfeeApi } = useSalesContractProvider();

  return (
    <>
      <div
        data-active={pageRouter.query.infoTab === SalesContractInfoTab.DOCUMENTS ? true : undefined}
        onClick={() => pageRouter.updateQuery({ infoTab: SalesContractInfoTab.DOCUMENTS })}
        title={lang.showMore}
      >
        <div style={titleStyle}>{lang.documents}</div>
        <br />
        {lang.autoFilledDocuments}: {selected?.documents?.length ?? '0'}
        <br />
        {lang.productDocuments}: {selected?.productDocuments?.length ?? '0'}
        <br />
        {lang.surchargeDocuments}:{' '}
        {subfeeApi?.payload?.data?.reduce((p, c) => p + (c.documents?.length ?? 0), 0) ?? '0'}
        <br />
        {lang.additionalDocumentation}: {selected?.contractDocuments?.length ?? '0'}
      </div>
      <div
        data-active={pageRouter.query.infoTab === SalesContractInfoTab.REVISIONS ? true : undefined}
        onClick={() => pageRouter.updateQuery({ infoTab: SalesContractInfoTab.REVISIONS })}
        title={lang.showMore}
      >
        <div style={titleStyle}>{lang.revisions}</div>
        <br />
        {revisionsApi?.payload?.length ? (
          <>
            {revisionsApi?.payload?.length} {lang.revisions}
          </>
        ) : (
          lang.noInfo
        )}
      </div>
      <div
        data-active={pageRouter.query.infoTab === SalesContractInfoTab.INFO ? true : undefined}
        onClick={() => pageRouter.updateQuery({ infoTab: SalesContractInfoTab.INFO })}
        title={lang.showMore}
      >
        <div style={titleStyle}>{lang.info}</div>
        <div>
          {selected.product?.name || lang.unknownProduct}:{' '}
          <b>
            <FormatValue value={`${(selected.priceCAD || 0).toFixed(2)}$`} />
          </b>
        </div>
        <div>
          {lang.appNumber}: <b>{selected.appNumber}</b>
        </div>
        <div>
          {lang.vin}: <b>{selected.vehicle.vin}</b>
        </div>
        <div>
          {lang.client}:{' '}
          <b>
            {selected.client.firstName} {selected.client.lastName}
          </b>
        </div>
        <div>
          {lang.dealer}: <b>{selected.dealer.name}</b>
        </div>
        <br />
        <div>
          {lang.status}: <b>{selected.statusCode?.replace('_', ' - ')}</b>
        </div>
        {selected.statusCode === ContractStatusCode.CANCELED_REFUND_PR_REFUND && (
          <>
            <div>
              {lang.cancellationDate}:{' '}
              <b>{dateDef(selected.cancellationDate, defaultServerDateFormat).format(defaultDateFormat)}</b>
            </div>
            <div>
              {lang.cancellationReason}: <b>{selected.cancellationReason}</b>
            </div>
            <div>
              {lang.odometerReading}: <b>{selected.cancellationOdometerReading} km</b>
            </div>
            {selected.cancellationReason !== undefined && selected.cancellationReason === CancellationReason.OTHER && (
              <div>
                {lang.cancellationReasonDescription}: <b>{selected.cancellationReasonDescription}</b>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
