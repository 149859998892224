import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageStatic1 from 'assets/components/page/themes/Theme.Page.Static.1';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { usePriceEditor } from '../hooks/usePriceEditor';
import { usePriceSelectionTools } from '../hooks/usePriceSelectionTools';
import PriceAdjustmentAddFilter from './PriceAdjustmentAddFilter';
import PriceAdjustmentSelectionTools from './PriceAdjustmentSelectionTools';
import PriceAdjustmentSummary from './PriceAdjustmentSummary';
import PriceEditTable from './PriceEditTable';
import ErrorWrapper from 'assets/components/inputs/wrappers/ErrorWrapper';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import { productIndexRoute } from '../../product/Product.Index';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { productEnhancementIndexRoute } from '../../productEnhancement/ProductEnhancement.Index';

type PriceAdjustmentsPageProps = {
  isLoading?: boolean;
  title?: Utils.ReactNodes;
  id?: number;
  dataType?: Module.ProductManagement.PriceAdjustments.DataType;
};
export default function PriceAdjustmentsPage({ isLoading, title, id, dataType }: PriceAdjustmentsPageProps) {
  const { lang } = getLocales();
  const { permissions } = useAuthStore();
  const { pricingType, hasNoInfo, isSaving, isUpdated, prices, allPrices, saveAll } = usePriceEditor();
  const priceSelectionTools = usePriceSelectionTools();
  const isInTransition = isSaving || isLoading;

  return (
    <Page
      class={ThemePageStatic1}
      style={{
        pageContent: isInTransition || hasNoInfo ? undefined : { padding: 0 },
        pageSubHeader: {
          margin: 0,
          padding: `${Theme.Size.L} ${Theme.Size.L} ${Theme.Size.M} ${Theme.Size.L}`,
          height: 'fit-content',
          zIndex: 9,
        },
        pageHeader: {
          backgroundColor: Theme.Color.backgroundDark,
          color: Theme.Color.primaryDark,
          zIndex: 10,
        },
      }}
      header={
        isLoading ? undefined : (
          <>
            <Card
              class={ThemeCardRow}
              info={title}
              title={<>{lang[pricingType]} adjustments</>}
              subtitle={
                isLoading ? undefined : (
                  <>
                    Showing {prices.length}/{allPrices.length} prices
                  </>
                )
              }
              actions={!isInTransition && <PriceAdjustmentSelectionTools />}
            />
          </>
        )
      }
      subHeader={!isInTransition && <PriceAdjustmentAddFilter />}
      render={
        isSaving ? (
          <ErrorWrapper>Saving please wait...</ErrorWrapper>
        ) : isLoading ? (
          <ErrorWrapper>Loading please wait...</ErrorWrapper>
        ) : hasNoInfo ? (
          <ErrorWrapper>{lang.noInfo}</ErrorWrapper>
        ) : (
          <PriceEditTable
            dealerId={priceSelectionTools.dealer?.id}
            distributorId={priceSelectionTools.distributor?.id}
          />
        )
      }
      footer={
        <>
          <PriceAdjustmentSummary />
          <Button
            onClick={() =>
              document.location.replace(
                createPathFromRoute(dataType === 'product' ? productIndexRoute : productEnhancementIndexRoute, {
                  action: 'edit',
                  id,
                })
              )
            }
            disabled={!permissions.EDIT_PRODUCT}
            class={ThemeButtonCircle}
            media={<Icon class="fas-edit" />}
            style={{ marginLeft: 'auto' }}
            render={dataType === 'product' ? lang.goToProductEdit : lang.goToEnhancementEdit}
            htmlElementProps={{ title: lang.edit }}
          />
          <Button
            media={<Icon class="fas-save" />}
            render={lang.save}
            class={ThemeButtonCircle}
            active={isUpdated}
            disabled={!isUpdated || isSaving}
            htmlElementProps={{ title: lang.save }}
            onClick={saveAll}
          />
        </>
      }
    />
  );
}
