import SearchInput from 'assets/components/inputs/search/SearchInput';
import { getLocales } from 'assets/locales/Locale';
import { useProductEnhancementProvider } from '../ProductEnhancement.Hooks';

export default function ProductEnhancementFilters() {
  const { lang } = getLocales();
  const { listApi } = useProductEnhancementProvider();

  return (
    <>
      <SearchInput
        value={listApi.body?.search}
        onSearch={(search) => listApi.execute((body) => ({ ...body, search }))}
        threshold={2}
        placeholder={`${lang.search}...`}
      />
    </>
  );
}
