import LinkButton from 'assets/components/button/LinkButton';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import Icon from 'assets/components/icon/Icon';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { compact, first, uniq } from 'lodash';
import ProductEnhancement from 'models/productManagement/productEnhancement/Model.ProductEnhancement';
import React, { Fragment, useMemo } from 'react';
import { useDealerProvider } from '../../Dealer.Hooks';
import { DealerForm } from '../Dealer.Edit';

const DealerProductEnhancementDocumentForm: React.FC<{
  additionalProductEnhancementIds: number[];
  setAdditionalProductEnhancementIds: React.Dispatch<React.SetStateAction<number[]>>;
}> = ({ additionalProductEnhancementIds }) => {
  const { form } = useModalForm<DealerForm>();
  const { selected } = useDealerProvider();
  const { lang } = getLocales();

  const productEnhancementIds = useMemo(() => {
    const ids = uniq([...compact(selected?.productEnhancements?.map((p) => p.id)), ...additionalProductEnhancementIds]);
    return ids.length ? ids : undefined;
  }, [selected?.productEnhancements, additionalProductEnhancementIds]);
  const { payload: productEnhancementsPayload } = useApi(
    {
      action: ProductEnhancement.list,
      wait: !productEnhancementIds,
      body: {
        id: productEnhancementIds,
      },
    },
    [productEnhancementIds]
  );
  const productEnhancements = productEnhancementsPayload?.data;

  return (
    <FormWrapper>
      <DataMap
        data={productEnhancements}
        render={({ data: productEnhancement }) =>
          !productEnhancement.productEnhancementDocuments.length ? null : (
            <Fragment key={productEnhancement.id}>
              <h3>{productEnhancement.name}</h3>
              <DataMap
                data={productEnhancement.productEnhancementDocuments}
                render={({ data: productEnhancementDocument }) => {
                  let currentDocs = form.data.productEnhancementDealerDocuments ?? [];
                  const foundDocs = currentDocs.filter(
                    (d) => d.productEnhancementDocumentId === productEnhancementDocument.id && !d.isDeleted
                  );
                  const dealerProductEnhancementDocumentMediaFile = currentDocs?.find(
                    (d) => d.id && d.productEnhancementDocumentId === productEnhancementDocument.id
                  )?.mediaFile;

                  return (
                    <Fragment key={productEnhancementDocument.id}>
                      <FileInput
                        name="files"
                        fileLimit={1}
                        value={foundDocs?.map((it) => it.formFile ?? { id: it.id, name: it.mediaFile?.name })}
                        onChange={({ files }) => {
                          currentDocs = currentDocs.map((d) =>
                            d.productEnhancementDocumentId === productEnhancementDocument.id
                              ? { ...d, isDeleted: true }
                              : d
                          );
                          if (files.length) {
                            currentDocs.push({
                              productEnhancementDocumentId: productEnhancementDocument.id,
                              productEnhancementDocument,
                              formFile: first(files),
                            });
                          }
                          form.update({ productEnhancementDealerDocuments: currentDocs });
                        }}
                        class={ThemeFileInputDropzone}
                        label={<b>{productEnhancementDocument.displayInfo.title}</b>}
                      />
                      {dealerProductEnhancementDocumentMediaFile && (
                        <LinkButton
                          media={<Icon class="fas-download" />}
                          class={ThemeButtonCircle}
                          style={{ alignSelf: 'flex-end' }}
                          render={`${lang.download} - ${dealerProductEnhancementDocumentMediaFile?.name}`}
                          url={dealerProductEnhancementDocumentMediaFile?.url}
                        />
                      )}
                    </Fragment>
                  );
                }}
              />
            </Fragment>
          )
        }
      />
    </FormWrapper>
  );
};
export default DealerProductEnhancementDocumentForm;
