import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { cleanValues } from 'assets/utils/data/Object';
import { compact, differenceBy, reduce, sortBy, uniq, uniqBy } from 'lodash';
import Product from 'models/productManagement/product/Model.Product';
import Tier from 'models/productManagement/tier/Model.Tier';
import ProductTierPricePoint, {
  productTierPricePointDeleteApi,
  productTierPricePointSaveApi,
} from 'models/productManagement/productTierPricePoint/Model.ProductTierPricePoint';
import { useEffect, useMemo, useState } from 'react';
import { warrantyTierPricingRoute } from './WarrantyTierPricing.Index';
import useDistributorPricingDistributions from 'module/productManagement/hooks/useDistributorPricingDistributions';
import useDealerPricingSettings from 'module/productManagement/hooks/useDealerPricingSettings';
import iterateWithProgress, { ProgressInfo } from 'assets/utils/data/Iterations';
import { ProgressKey } from 'assets/components/progressWindow/ProgressWindow';

type Term = number;
type PricePoint = Model.IProductTierPricePoint & {
  isUpdated?: boolean;
};
export default function useWarrantyTierPricing() {
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<
    Module.ProductManagement.WarrantyTierPricing.Params,
    Module.ProductManagement.WarrantyTierPricing.Query
  >({
    route: warrantyTierPricingRoute,
  });
  const productId = parseInt(pageRouter.params.productId) || 0;
  const productProvinceGroupId = parseInt(pageRouter.params.productProvinceGroupId) || undefined;
  const hasAccess =
    permissions.VIEW_PRODUCTTIERPRICEPOINT ||
    permissions.CREATE_PRODUCTTIERPRICEPOINT ||
    permissions.EDIT_PRODUCTTIERPRICEPOINT ||
    permissions.DELETE_PRODUCTTIERPRICEPOINT;

  const listApi = useApi({
    action: ProductTierPricePoint.list,
    body: { productId: [productId] },
    wait: !hasAccess,
  });

  const tierApi = useApi({
    action: Tier.list,
    wait: !hasAccess,
  });

  const productApi = useApi({
    action: Product.find,
    body: { id: productId },
    wait: !hasAccess,
  });

  const distributorPricingDistributions = useDistributorPricingDistributions({
    productId,
  });

  const dealerPricingSettings = useDealerPricingSettings({
    productId,
  });

  //#region PRICES
  const [data, setData] = useState<PricePoint[]>([]);
  const isUpdated = useMemo(
    () =>
      data?.some((it) => it.isUpdated) ||
      distributorPricingDistributions.distributorData?.isUpdated ||
      distributorPricingDistributions.distributorData?.distributions?.some((it) => it.isUpdated) ||
      (dealerPricingSettings.isUpdated && !dealerPricingSettings.hasErrors),
    [
      data,
      distributorPricingDistributions.distributorData,
      dealerPricingSettings.isUpdated,
      dealerPricingSettings.hasErrors,
    ]
  );
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setData(() => listApi.payload?.data?.filter((it) => it.productProvinceGroupId == productProvinceGroupId) || []);
  }, [listApi.payload?.data, productProvinceGroupId]);

  async function saveAll(onSaveProgress?: (key: ProgressKey, progressInfo: ProgressInfo) => void) {
    setIsSaving(true);
    distributorPricingDistributions.saveAll(onSaveProgress);
    dealerPricingSettings.saveAll(onSaveProgress);
    const updatedPricesCount = await iterateWithProgress(
      data.filter((it) => it.isUpdated),
      async (pricePoint) => {
        if (pricePoint.priceCAD == null && pricePoint.id) {
          await productTierPricePointDeleteApi({ id: pricePoint.id });
        } else if (pricePoint.priceCAD != null) {
          await productTierPricePointSaveApi(cleanValues(pricePoint));
        }
      },
      (p) => onSaveProgress('priceDistribution', p)
    );
    if (updatedPricesCount) {
      await listApi.execute((body) => body);
    }
    setIsSaving(false);
  }

  function getPrice(term: Term, tierId: number) {
    return data.find((it) => it.termInMonths === term && it.tierId === tierId);
  }
  function updatePrice(term: Term, tierId: number, pricingDistributions?: Partial<Utils.PricingDistribution>) {
    const d = getPrice(term, tierId);
    if (d) {
      d.isUpdated = true;
      if (pricingDistributions == null) {
        d.priceCAD = null;
        d.pricingDistributions = null;
        d.subfees = null;
      } else {
        d.pricingDistributions = {
          ...productApi?.payload?.pricingDistribution,
          ...d.pricingDistributions,
          ...pricingDistributions,
        };
        d.priceCAD = reduce(d.pricingDistributions || {}, (prev, curr) => prev + curr.amount, 0);
      }
      setData((old) => [...old]);
    } else {
      const newD: PricePoint = {
        id: undefined,
        isUpdated: true,
        termInMonths: term,
        priceCAD: reduce(pricingDistributions || {}, (prev, curr) => prev + curr.amount, 0),
        pricingDistributions,
        productProvinceGroupId,
        tierId,
        productId,
      };
      setData((old) => [...old, newD]);
    }
  }
  function updateSubfee(term: Term, tierId: number, subfees?: Utils.SurchargeInfo) {
    const d = getPrice(term, tierId);
    if (d) {
      d.isUpdated = true;
      d.subfees = uniqBy(compact([subfees, ...(d.subfees ?? [])]), (it) => it.subfeeId);
      setData((old) => [...old]);
    }
  }
  //#endregion

  const tiers = useMemo(
    () =>
      sortBy(
        uniqBy(compact(data.map((d) => tierApi.payload?.data?.find((vc) => vc?.id === d.tierId))), (it) => it.id),
        (t) => t.orderNumber
      ),
    [data, tierApi.payload?.data]
  );

  const terms = useMemo(() => sortBy(uniq(data.map((it) => it.termInMonths))), [data]);
  const unselectedTiers = useMemo(
    () => differenceBy(tierApi.payload?.data || [], tiers, (it) => it.id),
    [tierApi.payload?.data, tiers]
  );

  function removeTier(tier: Model.ITier) {
    setData((old) => old.filter((it) => it.id !== tier.id));
  }
  function removeTerm(termInMonths: Term) {
    setData((old) => old.filter((it) => it.termInMonths !== termInMonths));
  }

  function addEmptyPrices(newTerms: number[], newTiers: Model.ITier[]) {
    const prices: PricePoint[] = [];
    for (const term of newTerms) {
      for (const tier of newTiers) {
        if (!data.some((it) => it.termInMonths === term && it.tierId === tier.id)) {
          prices.push({
            id: undefined,
            isUpdated: false,
            termInMonths: term,
            priceCAD: null,
            productProvinceGroupId,
            tierId: tier.id,
            productId,
          });
        }
      }
    }
    setData((old) => [...old, ...prices]);
  }

  const isAllowedToCopy = !!productProvinceGroupId && data.length === 0;
  async function copy() {
    setIsSaving(true);
    if (!listApi.payload?.data?.some((it) => it.productProvinceGroupId === productProvinceGroupId)) {
      const defaultTierPricePoints = listApi.payload?.data?.filter((it) => !it.productProvinceGroupId);
      for (const tierPricePoint of defaultTierPricePoints || []) {
        await productTierPricePointSaveApi({
          termInMonths: tierPricePoint.termInMonths,
          priceCAD: tierPricePoint.priceCAD,
          productId: tierPricePoint.productId,
          tierId: tierPricePoint.tierId,
          pricingDistributions: tierPricePoint.pricingDistributions,
          productProvinceGroupId,
        });
      }
    }
    setIsSaving(false);
    listApi.execute((body) => body);
  }
  return {
    distributorPricingDistributions,
    dealerPricingSettings,
    hasAccess,
    provinceGroup: productApi?.payload?.productProvinceGroups?.find((g) => g.id === productProvinceGroupId),
    isAllowedToCopy,
    copy,
    isSaving,
    isUpdated,
    removeTier,
    removeTerm,
    addEmptyPrices,
    updatePrice,
    updateSubfee,
    getPrice,
    data,
    terms,
    tiers,
    saveAll,
    pageRouter,
    productApi,
    unselectedTiers,
    tierApi,
    listApi,
    permissions,
  };
}

export function useWarrantyTierPricingProvider() {
  return useDataProvider<ReturnType<typeof useWarrantyTierPricing>>();
}
