import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import ProviderBuilder from 'assets/providers/ProviderBuilder';
import { compact } from 'lodash';
import dealerListApi from 'models/dealerManagement/dealer/list/Api.Dealer.List';
import pricingDistributionTemplateListApi from 'models/productManagement/pricingDistributionTemplate/list/Api.PricingDistributionTemplate.List';
import distributorFindApi from 'models/sales/distributor/find/Api.Distributor.Find';
import distributorListApi from 'models/sales/distributor/list/Api.Distributor.List';
import { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

type PriceSelectionToolsProps = {
  id?: number;
  dataType?: Module.ProductManagement.PriceAdjustments.DataType;
  dealerId?: number;
  distributorId?: number;
  distributorTemplateId?: number;
  onSelect(props: { dealerId?: number; distributorId?: number; distributorTemplateId?: number }): void;
};
function usePriceSelectionToolsCore({ id, dataType, onSelect, ...restProps }: PriceSelectionToolsProps) {
  const { user, isDealer, isDistributor } = useAuthStore();

  const [dealers, setDealers] = useState<Model.IDealer[]>([]);
  const [distributors, setDistributors] = useState<Model.IDistributor[]>([]);
  const [templates, setTemplates] = useState<Model.IPricingDistributionTemplate[]>([]);

  useEffect(() => {
    (async () => {
      if (isDealer) {
        const dealerIds = compact(user?.dealers?.map((it) => it.toJSON())).map((d) => d.id);
        if (user?.distributorId && dealerIds.length) {
          const templates = (await pricingDistributionTemplateListApi({ distributorId: user.distributorId }))?.payload
            ?.data;
          const distributor = (await distributorFindApi({ id: user.distributorId })).payload;
          const dealers = (await dealerListApi({ id: dealerIds })).payload?.data.filter((d) =>
            dataType === 'product'
              ? d.products?.some((p) => p.id === id)
              : d.productEnhancements?.some((p) => p.id === id)
          );
          unstable_batchedUpdates(() => {
            setDealers(compact(dealers));
            setDistributors(compact([distributor]));
            setTemplates(compact(templates));
          });
        }
      } else if (isDistributor) {
        if (user?.distributorId) {
          const templates = (await pricingDistributionTemplateListApi({ distributorId: user.distributorId }))?.payload
            ?.data;
          const distributor = (await distributorFindApi({ id: user.distributorId })).payload;
          const dealers = (await dealerListApi({ distributorId: [user.distributorId] })).payload?.data.filter((d) =>
            dataType === 'product'
              ? d.products?.some((p) => p.id === id)
              : d.productEnhancements?.some((p) => p.id === id)
          );
          unstable_batchedUpdates(() => {
            setDealers(compact(dealers));
            setDistributors(compact([distributor]));
            setTemplates(compact(templates));
          });
        }
      } else {
        const dealers = (await dealerListApi()).payload?.data.filter((d) =>
          dataType === 'product'
            ? d.products?.some((p) => p.id === id)
            : d.productEnhancements?.some((p) => p.id === id)
        );
        const distributors = (await distributorListApi()).payload?.data.filter((dis) =>
          dealers?.some((d) => d.representativeId === dis.id)
        );
        const templates = (await pricingDistributionTemplateListApi())?.payload?.data?.filter((t) =>
          distributors?.some((d) => d.id === t.distributorId)
        );
        unstable_batchedUpdates(() => {
          setDealers(compact(dealers));
          setDistributors(compact(distributors));
          setTemplates(compact(templates));
        });
      }
    })();
  }, [id, dataType]);

  return {
    dataType,
    dataTypeId: id,
    onSelect,
    dealers,
    distributors,
    templates,
    dealer: dealers.find((d) => d.id === restProps.dealerId),
    distributor: distributors.find((d) => d.id === restProps.distributorId),
    distributorTemplate: templates.find((d) => d.id === restProps.distributorTemplateId),
  };
}

const { useProvider: usePriceSelectionTools, Provider: PriceSelectionToolsProvider } =
  ProviderBuilder(usePriceSelectionToolsCore);

export { usePriceSelectionTools, PriceSelectionToolsProvider };
