import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import ObjectTable from 'assets/templates/objectTable/ObjectTable';
import { DealerGroupForm } from '../DealerGroup.Edit';

const DealerGroupContractConsentProductAggregate = () => {
  const { form } = useModalForm<DealerGroupForm>();

  const updateValue = (value: string, id: number) => {
    const aggregates = form.data?.contractConsentProductAggregates?.map((item) =>
      id === item?.productId ? { ...item, description: value } : item
    );
    form.update({ contractConsentProductAggregates: aggregates });
  };

  return (
    <FormWrapper>
      <ObjectTable
        paths={[]}
        data={form?.data?.contractConsentProductAggregates}
        renderRow={({ data: row }) => {
          const product = form.data?.newDistributorProducts?.find((it) => it.id === row?.productId);
          return (
            <tr key={row?.productId}>
              <td>{product?.name}</td>
              <td>
                <TextInput
                  name="value"
                  value={row?.description}
                  onChange={({ value }) => {
                    updateValue(value, row?.productId);
                  }}
                />
              </td>
            </tr>
          );
        }}
      />
    </FormWrapper>
  );
};

export default DealerGroupContractConsentProductAggregate;
