import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import { Fragment, useState } from 'react';
import { PricingFilters, usePriceEditor } from '../hooks/usePriceEditor';
import { usePriceSelectionTools } from '../hooks/usePriceSelectionTools';
import PriceAdjustmentEditPricingPlan from './PriceAdjustmentEditPricingPlan';
import { keys } from 'lodash';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import { inputStyles } from 'assets/components/inputs/Input';
import ProductType from 'models/enums/ProductType';

export default function PriceAdjustmentAddFilter() {
  const { translate, lang } = getLocales();
  const { filters, dataMappings, addEmptyPrices, setOverrideEmptyPrices, overrideEmptyPrice, pricingType } =
    usePriceEditor();
  const { dataTypeId, dataType } = usePriceSelectionTools();
  const [newFilters, setNewFilters] = useState<PricingFilters>({});
  function updatePartial(props: PricingFilters) {
    setNewFilters((old) => ({ ...old, ...props }));
  }
  function handleApply() {
    addEmptyPrices(newFilters);
    setNewFilters({});
  }

  const [selectedPricingPlanId, setSelectedPricingPlanId] = useState<number | null>(null);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
      {selectedPricingPlanId !== null && (
        <PriceAdjustmentEditPricingPlan
          pricingPlanId={selectedPricingPlanId}
          productId={dataTypeId}
          onCancel={() => setSelectedPricingPlanId(null)}
        />
      )}
      {pricingType !== ProductType.warrantiClassBased && (
        <CheckInput
          name="overrideEmptyPrice"
          label="Override empty prices"
          value={overrideEmptyPrice}
          onChange={({ overrideEmptyPrice }) => setOverrideEmptyPrices(!!overrideEmptyPrice)}
          class={inputStyles.check.checkbox}
          style={{ width: '200px', marginRight: 'auto' }}
        />
      )}
      <InputWrapper>
        <DataMap
          data={filters}
          render={({ data: filter }) => {
            const mappedData: Component.Input.Select.SelectionItem[] = dataMappings[filter.key]?.map((it) => ({
              id: it.value,
              title: it.label,
              disabled: filter.options.some((o) => o.value === it.value),
            }));

            return (
              <Fragment key={filter.key}>
                {filter.type === 'object' && !!mappedData?.length && (
                  <SelectInput
                    name={filter.key}
                    onChange={updatePartial as any}
                    value={newFilters[filter.key] as any}
                    label={translate(filter.key)}
                    data={mappedData}
                    onAdd={
                      filter.key === 'productPricingPlan' && dataType === 'product'
                        ? () => setSelectedPricingPlanId(0)
                        : undefined
                    }
                    onEdit={
                      filter.key === 'productPricingPlan' && dataType === 'product'
                        ? setSelectedPricingPlanId
                        : undefined
                    }
                  />
                )}
                {filter.type === 'number' && (
                  <NumberInput
                    name={filter.key}
                    onChange={updatePartial as any}
                    value={newFilters[filter.key] as any}
                    label={translate(filter.key)}
                  />
                )}
                {filter.type === 'string' && (
                  <TextInput
                    name={filter.key}
                    onChange={updatePartial as any}
                    value={newFilters[filter.key] as any}
                    label={translate(filter.key)}
                  />
                )}
              </Fragment>
            );
          }}
        />
        <Button
          media="fas-level-down-alt"
          class={ThemeButtonCircle}
          onClick={handleApply}
          active={!!keys(newFilters).length}
          disabled={!keys(newFilters).length}
          htmlElementProps={{ title: lang.apply }}
        />
      </InputWrapper>
    </div>
  );
}
