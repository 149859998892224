import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import { inputStyles } from 'assets/components/inputs/Input';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { LocationSearch } from 'assets/templates/locationSearch/LocationSearch';
import Theme from 'assets/themes/Theme.Common';
import Product from 'models/productManagement/product/Model.Product';
import { useDistributorProvider } from '../Distributor.Hooks';
import Lender from 'models/sales/lender/Model.Lender';
import ProductEnhancement from 'models/productManagement/productEnhancement/Model.ProductEnhancement';

export default function DistributorEdit() {
  const { lang } = getLocales();
  const { selected, pageRouter, saveApi } = useDistributorProvider();
  const productApi = useApi({ action: Product.list });
  const productEnhancementApi = useApi({ action: ProductEnhancement.list });
  const lenderApi = useApi({ action: Lender.list });
  return (
    <>
      <ModalForm<Api.Core.Distributor.Save.IRequest>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[selected]}
        title={lang.distributor}
        default={selected?.toJSON() || {}}
        onCancel={pageRouter.updateParams}
        onSubmit={saveApi.execute}
        validation={(data, errors) => {
          if (!data.name) errors.name = lang.mustNotBeEmpty;
        }}
        render={({ form }) => (
          <>
            <FormWrapper>
              <TextInput
                name="name"
                label={lang.name}
                value={form.data.name}
                error={form.errors.name}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <CheckInput
                name="isInactive"
                label={lang.isInactive}
                value={form.data.isInactive}
                error={form.errors.isInactive}
                onChange={form.update}
                class={inputStyles.check.switch}
                htmlElementProps={{ title: form.data.isInactive ? lang.disable : lang.enable }}
              />
              <TextInput
                name="primaryContactEmailAddress"
                label={lang.primaryContactEmailAddress}
                value={form.data.primaryContactEmailAddress}
                error={form.errors.primaryContactEmailAddress}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <SelectInput
                name="products"
                label={lang.products}
                data={productApi?.payload?.data?.map((item) => item.displayInfo)}
                isMultiselect
                value={form.data.distributorProducts?.map((it) => it.productId)}
                onChange={({ products: idList }) =>
                  form.update({
                    distributorProducts: idList.map(
                      (productId) =>
                        form.data.distributorProducts?.find((it) => it.productId === productId) ?? { productId }
                    ),
                  })
                }
                icon={<Icon class="fas-clipboard-list" />}
              />
              <SelectInput
                name="productEnhancements"
                label={lang.productEnhancements}
                data={productEnhancementApi?.payload?.data?.map((item) => item.displayInfo)}
                isMultiselect
                value={form.data.distributorProductEnhancements?.map((it) => it.productEnhancementId)}
                onChange={({ productEnhancements: idList }) =>
                  form.update({
                    distributorProductEnhancements: idList.map(
                      (productEnhancementId) =>
                        form.data.distributorProductEnhancements?.find(
                          (it) => it.productEnhancementId === productEnhancementId
                        ) ?? { productEnhancementId }
                    ),
                  })
                }
                icon={<Icon class="fas-clipboard-list" />}
              />
              <SelectInput
                name="lenders"
                label={lang.lenders}
                data={lenderApi?.payload?.data?.map((item) => item.displayInfo)}
                isMultiselect
                value={form.data.lenders?.map((it) => it.id)}
                onChange={({ lenders: idList }) =>
                  form.update({
                    lenders: idList.map((id) => form.data.lenders?.find((it) => it.id === id) ?? { id }),
                  })
                }
                icon={<Icon class="fas-clipboard-list" />}
              />
              <LocationSearch
                onSelect={(location) => {
                  form.update({
                    streetAddress: location?.Line1 || null,
                    provinceCode: location?.ProvinceCode || null,
                    city: location?.City || null,
                    countryCode: location?.CountryIso3 || null,
                    postalCode: location?.PostalCode || null,
                  });
                }}
              />
              <InputWrapper>
                <TextInput
                  name="streetAddress"
                  label={lang.streetAddress}
                  value={form.data.streetAddress}
                  error={form.errors.streetAddress}
                  onChange={form.update}
                  icon={<Icon class="if-map-pin" />}
                  readOnly
                />
                <TextInput
                  name="postalCode"
                  label={lang.postalCode}
                  value={form.data.postalCode}
                  error={form.errors.postalCode}
                  onChange={form.update}
                  icon={<Icon class="if-map-pin" />}
                  readOnly
                />
                <TextInput
                  name="city"
                  label={lang.city}
                  value={form.data.city}
                  error={form.errors.city}
                  onChange={form.update}
                  icon={<Icon class="if-map-pin" />}
                  readOnly
                />
                <TextInput
                  name="provinceCode"
                  label={lang.provinceCode}
                  value={form.data.provinceCode}
                  error={form.errors.provinceCode}
                  onChange={form.update}
                  icon={<Icon class="if-map-pin" />}
                  readOnly
                  style={{ width: Theme.Size.XXL }}
                />
                <TextInput
                  name="country"
                  label={lang.countryCode}
                  value={form.data.countryCode}
                  error={form.errors.countryCode}
                  onChange={form.update}
                  icon={<Icon class="if-map-pin" />}
                  readOnly
                  style={{ width: Theme.Size.XXL }}
                />
              </InputWrapper>
            </FormWrapper>
          </>
        )}
      />
    </>
  );
}
