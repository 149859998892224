import Collection from 'assets/components/data/Collection';
import HeaderData from 'assets/components/data/rows/HeaderData';
import HeaderRow from 'assets/components/data/rows/HeaderRow';
import Row from 'assets/components/data/rows/Row';
import RowData from 'assets/components/data/rows/RowData';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Modal from 'assets/components/page/templates/Modal';
import ThemeModalDefault from 'assets/components/page/templates/themes/Theme.Modal.Default';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import claimLegacyListApi from 'models/claims/claim/legacyList/Api.Claim.LegacyList';
import { useClaimEdit } from '../ClaimEdit.Hooks';

export default function ClaimEditLegacyClaimHistory(props: { onClose: () => void }) {
  const { lang } = getLocales();
  const { contract } = useClaimEdit();

  const { payload, isExecuting } = useApi({
    action: claimLegacyListApi,
    body: { appNumber: contract.appNumber },
  });

  return (
    <Modal
      subcomponents={ThemeModalDefault}
      header={lang.legacyClaimHistory}
      onClose={props.onClose}
      render={
        <>
          <Collection class={ThemeCollectionList} isLoading={isExecuting}>
            <HeaderRow>
              <HeaderData disableImage={true} style={{ maxWidth: '150px' }}>
                {lang.appNumber}
              </HeaderData>
              <HeaderData style={{ maxWidth: '250px' }}>{lang.email}</HeaderData>
              <HeaderData>{lang.details}</HeaderData>
            </HeaderRow>
            <DataMap
              data={payload}
              render={({ data: item }) => (
                <Row
                  key={item.id}
                  children={
                    <>
                      <RowData title={item.applicationId} subtitle={<>Id: {item.id}</>} style={{ maxWidth: '150px' }} />
                      <RowData style={{ maxWidth: '250px' }}>{item.contactEmail}</RowData>
                      <RowData>
                        {lang.province}: {item.province}
                        <br />
                        {lang.totalAmount}: {item.claimTotalCost}
                        <br />
                        {lang.serviceAdviser}: {item.serviceAdviser}
                        <br />
                        {lang.descriptionOfDamage}: {item.descriptionOfDamage}
                        <br />
                        {lang.damageOccurredLocation}: {item.whereDamageOccuredLocation}
                        <br />
                        {lang.type}: {item.typeOfClaim}
                        <br />
                        {lang.claimStatus}: {item.claimStatus}
                        <br />
                        {lang.windshieldCostOfRepair}: {item.windshieldCostOfRepair}
                      </RowData>
                    </>
                  }
                />
              )}
            />
          </Collection>
        </>
      }
    />
  );
}
