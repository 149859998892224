enum ContractStatusCode {
  OPEN_SUBMITTED = 'OPEN_SUBMITTED',
  OPEN_UNDER_REVIEW = 'OPEN_UNDER REVIEW',
  OPEN_UNPAID = 'OPEN_UNPAID',
  OPEN_WAITING_CONSENT = 'OPEN_WAITING CONSENT',
  PAID_ACTIVE = 'PAID_ACTIVE',
  PAID_EXPIRED = 'PAID_EXPIRED',
  CANCELED_VOID = 'CANCELED_VOID',
  CANCELED_REFUND_PR_REFUND = 'CANCELED_REFUND / PR REFUND',
}

export default ContractStatusCode;
export type VerifyContractStatusCode = Utils.VerifyExtends<
  Model.Enum.ContractStatusCode,
  Utils.ValueOf<typeof ContractStatusCode>
>;
