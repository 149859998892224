import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import ProductType from 'models/enums/ProductType';
import { buildPriceAdjustmentsPath } from 'module/productManagement/views/priceAdjustments/PriceAdjustments';
import { useHistory } from 'react-router';

type Props = {
  productType: Model.Enum.ProductType;
  id: number;
  label?: Utils.RenderPropsEmpty;
  class?: string;
  dataType: Module.ProductManagement.PriceAdjustments.DataType;
};
export default function ProductPricingAdjustmentButton({ id, dataType, productType, label, class: className }: Props) {
  const { permissions } = useAuthStore();
  const { lang } = getLocales();
  const history = useHistory();
  return (
    <Button
      onClick={() => {
        if (productType)
          history.push(
            buildPriceAdjustmentsPath({
              id: id.toString(),
              dataType,
            })
          );
      }}
      class={className ?? ThemeButtonCircle}
      disabled={
        !productType ||
        (productType === ProductType.custom
          ? !(
              permissions.VIEW_CUSTOMPRICEPOINT ||
              permissions.CREATE_CUSTOMPRICEPOINT ||
              permissions.EDIT_CUSTOMPRICEPOINT ||
              permissions.DELETE_CUSTOMPRICEPOINT
            )
          : productType === ProductType.nonWarranty
          ? !(
              permissions.VIEW_NONWARRANTYPRICEPOINT ||
              permissions.CREATE_NONWARRANTYPRICEPOINT ||
              permissions.EDIT_NONWARRANTYPRICEPOINT ||
              permissions.DELETE_NONWARRANTYPRICEPOINT
            )
          : productType === ProductType.warrantyTierBased
          ? !(
              permissions.VIEW_PRODUCTTIERPRICEPOINT ||
              permissions.CREATE_PRODUCTTIERPRICEPOINT ||
              permissions.EDIT_PRODUCTTIERPRICEPOINT ||
              permissions.DELETE_PRODUCTTIERPRICEPOINT
            )
          : productType === ProductType.warrantiClassBased
          ? !(
              permissions.VIEW_PRODUCTPRICINGPLAN ||
              permissions.CREATE_PRODUCTPRICINGPLAN ||
              permissions.EDIT_PRODUCTPRICINGPLAN ||
              permissions.DELETE_PRODUCTPRICINGPLAN
            )
          : true)
      }
      media={<Icon class="fas-sliders-h" />}
      render={label}
      htmlElementProps={{ title: lang.adjustments }}
    />
  );
}
