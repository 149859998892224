import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Icon from 'assets/components/icon/Icon';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { defaultInputDateFormat } from 'assets/utils/data/Date';
import { first } from 'lodash';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import PurchaseState from 'models/enums/PurchaseState';
import PurchaseType from 'models/enums/PurchaseType';
import VehicleModel from 'models/productManagement/vehicleModel/Model.VehicleModel';
import Lender from 'models/sales/lender/Model.Lender';
import { useEffect, useState } from 'react';
import { useSalesNewContractProvider } from '../Sales.NewContract.Hooks';

const hideFeature = true;
export default function SalesNewContractVehicle() {
  const { lang } = getLocales();
  const { form, searchedVin, setSearchedVin, fetchVehicles, vehicleDetails, isLoading, isWarrantyCovered } =
    useSalesNewContractProvider();
  const { user } = useAuthStore();

  const [refreshDatePicker, setRefreshDatePicker] = useState<boolean>(false);
  const distributorId =
    form.data.dealer?.representativeId ?? form.data.dealer?.representative?.id ?? form.data.distributor?.id ?? 0;
  const lenderApi = useApi({
    action: Lender.list,
    wait: true,
  });

  useEffect(() => {
    if (distributorId) {
      lenderApi.execute({
        distributorId: distributorId ? [distributorId] : undefined,
      });
    } else lenderApi.reset();
  }, [distributorId]);

  const vehicle = form?.data?.vehicle;
  const product = form?.data?.product;
  const errors = form?.errors?.vehicle;

  const mfgWholeVehicleWarrantyMonths =
    vehicle?.mfgWholeVehicleWarrantyMonths ??
    vehicle?.vehicleModel?.mfgWholeVehicleWarrantyMonths ??
    vehicle?.vehicleModel?.vehicleMake?.mfgWholeVehicleWarrantyMonths ??
    undefined;
  const mfgWholeVehicleWarrantyKm =
    vehicle?.mfgWholeVehicleWarrantyKm ??
    vehicle?.vehicleModel?.mfgWholeVehicleWarrantyKm ??
    vehicle?.vehicleModel?.vehicleMake?.mfgWholeVehicleWarrantyKm ??
    undefined;
  const mfgPowertrainWarrantyMonths =
    vehicle?.mfgPowertrainWarrantyMonths ??
    vehicle?.vehicleModel?.mfgPowertrainWarrantyMonths ??
    vehicle?.vehicleModel?.vehicleMake?.mfgPowertrainWarrantyMonths ??
    undefined;
  const mfgPowertrainWarrantyKm =
    vehicle?.mfgPowertrainWarrantyKm ??
    vehicle?.vehicleModel?.mfgPowertrainWarrantyKm ??
    vehicle?.vehicleModel?.vehicleMake?.mfgPowertrainWarrantyKm ??
    undefined;

  function updateVehicle(vehicle: Partial<Model.IVehicle>) {
    if (vehicle.posOdometerReading === null) {
      setRefreshDatePicker(!refreshDatePicker);
      vehicle.purchaseState = null;
      vehicle.inServiceDate = null;
    } else {
      if (isWarrantyCovered(mfgWholeVehicleWarrantyMonths, form.data?.vehicle?.inServiceDate)) {
        vehicle.purchaseState = PurchaseState.NEW;
      } else {
        vehicle.purchaseState = PurchaseState.PRE_OWNED;
      }
    }

    if (vehicle.posOdometerReading > parseInt(mfgWholeVehicleWarrantyKm)) {
      vehicle.purchaseState = PurchaseState.PRE_OWNED;
      vehicle.inServiceDate = null;
    }

    if (
      vehicle.inServiceDate &&
      vehicle.inServiceDate !== '' &&
      parseInt(mfgWholeVehicleWarrantyKm) > form.data.vehicle?.posOdometerReading &&
      isWarrantyCovered(mfgWholeVehicleWarrantyMonths, vehicle.inServiceDate)
    ) {
      vehicle.purchaseState = PurchaseState.NEW;
    } else {
      vehicle.purchaseState = PurchaseState.PRE_OWNED;
    }

    if (vehicle?.purchaseType === PurchaseType.CASH || vehicle?.purchaseType === PurchaseType.LINE_OF_CREDIT) {
      vehicle.financedAmount = null;
      vehicle.interestRate = null;
      vehicle.lenderId = null;
    }

    form.merge({ vehicle });
    form.update({ products: [] });
  }

  const { payload: dealersPayload } = useApi({
    action: Dealer.list,
    body: {
      id: user?.dealers?.map((d) => d.id) ?? undefined,
      distributorId: !user?.dealers?.length && user?.distributorId ? [user?.distributorId] : undefined,
      isInactive: false,
    },
  });

  useEffect(() => {
    if (refreshDatePicker) {
      setRefreshDatePicker(false);
    }
  }, [vehicle?.purchaseState]);

  useEffect(() => {
    if (dealersPayload?.count === 1 && !form.data.products?.length) {
      form.update({
        dealer: first(dealersPayload.data)?.toJSON() || null,
        products: [],
      });
    }
  }, [dealersPayload?.data]);

  useEffect(() => {
    async function getAndSetVehicleInfo() {
      if (vehicleDetails?.payload && !vehicle) {
        const vehicleInfo = vehicleDetails?.payload?.toJSON() || null;
        const vehicleModels = await VehicleModel.list({ makeCode: vehicleInfo.makeCode, modelCode: vehicleInfo.model });
        const vehicleModel = first(vehicleModels.payload?.data || [])?.toJSON();
        form.update({
          vehicle: {
            ...vehicleInfo,
            vehicleModel,
          },
        });
      }
    }
    getAndSetVehicleInfo();
  }, [vehicleDetails.payload]);

  const isWarrantyDetailsVisible =
    product?.isWarrantyDetailsVisible || form.data.dealer?.products?.some((p) => p.isWarrantyDetailsVisible);
  const isPurchaseDetailsVisible =
    product?.isPurchaseDetailsVisible || form.data.dealer?.products?.some((p) => p.isPurchaseDetailsVisible);

  return (
    <>
      <FormWrapper isLoading={isLoading}>
        <InputWrapper>
          <SelectInput
            name="dealerId"
            label={lang.dealer}
            data={dealersPayload?.data?.map((it) => ({
              ...it.displayInfo,
              id: it.id,
            }))}
            value={form.data?.dealer?.id}
            error={form.errors.dealer?._objectError}
            disabled={dealersPayload?.count === 1}
            onChange={({ dealerId }) => {
              form.update({
                dealer: dealersPayload?.data?.find((it) => it.id === dealerId)?.toJSON() || null,
                products: [],
              });
            }}
            icon={<Icon class="if-cloud" />}
          />
        </InputWrapper>
        {form.data.id ? (
          <TextInput
            name="vin"
            label={lang.vin}
            value={form.data.vehicle?.vin}
            onChange={({ vin }) => form.merge({ vehicle: { vin } })}
          />
        ) : (
          <InputWrapper>
            <TextInput
              name="vin"
              label={lang.vin}
              value={searchedVin}
              onChange={({ vin }) => setSearchedVin(vin)}
              error={errors?.vin}
            />
            <Button
              onClick={fetchVehicles}
              media="fas-search"
              class={ThemeButtonCircle}
              active
              disabled={!searchedVin}
              htmlElementProps={{ title: lang.search }}
            />
          </InputWrapper>
        )}
        <InputWrapper>
          <NumberInput
            name="year"
            label={lang.year}
            value={vehicle?.year}
            error={errors?.year}
            onChange={updateVehicle}
            readOnly
          />
          <TextInput
            name="makeCode"
            label={lang.makeCode}
            value={vehicle?.makeCode}
            error={errors?.makeCode}
            onChange={updateVehicle}
            readOnly
          />
          <TextInput
            name="model"
            label={lang.model}
            value={vehicle?.model}
            error={errors?.model}
            onChange={updateVehicle}
            readOnly
          />
        </InputWrapper>
        <InputWrapper>
          <TextInput
            name="bodyType"
            label={lang.bodyType}
            value={vehicle?.bodyType}
            error={errors?.bodyType}
            onChange={updateVehicle}
            readOnly
          />
          <TextInput
            name="engineType"
            label={lang.engineType}
            value={vehicle?.engineType}
            error={errors?.engineType}
            onChange={updateVehicle}
            readOnly
          />
          <TextInput
            name="engineInfo"
            label={lang.engineInfo}
            value={vehicle?.engineInfo}
            error={errors?.engineInfo}
            onChange={updateVehicle}
            readOnly
          />
        </InputWrapper>
        <InputWrapper>
          <TextInput
            name="trimLevel"
            label={lang.trimLevel}
            value={vehicle?.trimLevel}
            error={errors?.trimLevel}
            onChange={updateVehicle}
            readOnly
          />
          <TextInput
            name="driveLine"
            label={lang.driveLine}
            value={vehicle?.driveLine}
            error={errors?.driveLine}
            onChange={updateVehicle}
            readOnly
          />
        </InputWrapper>
        <NumberInput
          name="posOdometerReading"
          label={lang.odometerReading}
          value={vehicle?.posOdometerReading}
          error={errors?.posOdometerReading}
          onChange={updateVehicle}
        />
        {isWarrantyDetailsVisible && (
          <>
            <InputWrapper>
              <TextInput
                name="mfgPowertrainWarrantyMonths"
                value={mfgPowertrainWarrantyMonths}
                label={lang.mfgPowertrainWarrantyMonths}
                onChange={updateVehicle}
                readOnly
              />
              <TextInput
                name="mfgPowertrainWarrantyKm"
                label={lang.mfgPowertrainWarrantyKm}
                value={mfgPowertrainWarrantyKm}
                onChange={updateVehicle}
                readOnly
              />
            </InputWrapper>
            <InputWrapper>
              <TextInput
                name="mfgWholeVehicleWarrantyMonths"
                value={mfgWholeVehicleWarrantyMonths}
                label={lang.mfgWholeVehicleWarrantyMonths}
                onChange={updateVehicle}
                readOnly
              />
              <TextInput
                name="mfgWholeVehicleWarrantyKm"
                value={mfgWholeVehicleWarrantyKm}
                label={lang.mfgWholeVehicleWarrantyKm}
                onChange={updateVehicle}
                readOnly
              />
            </InputWrapper>
          </>
        )}
        {isPurchaseDetailsVisible && (
          <>
            <TextInput
              name="stockNumber"
              label={lang.stockNumber}
              value={vehicle?.stockNumber}
              error={errors?.stockNumber}
              onChange={updateVehicle}
            />
            <NumberInput
              name="purchasePrice"
              label={lang.purchasePrice}
              value={vehicle?.purchasePrice}
              error={errors?.purchasePrice}
              step={0.01}
              onChange={updateVehicle}
            />
            {vehicle?.purchaseType === PurchaseType.FINANCE && (
              <NumberInput
                name="financedAmount"
                label={lang.financedAmount}
                value={vehicle?.financedAmount}
                error={errors?.financedAmount}
                step={0.01}
                onChange={updateVehicle}
              />
            )}
            <InputWrapper>
              <SelectInput
                name="purchaseType"
                label={lang.purchaseType}
                data={Object.values(PurchaseType).map((id) => ({
                  id,
                  title: lang[id.toLowerCase().replace(/\s+/g, '')],
                }))}
                value={vehicle?.purchaseType}
                onChange={updateVehicle}
              />
              {vehicle?.purchaseType &&
                !(
                  vehicle?.purchaseType === PurchaseType.CASH || vehicle?.purchaseType === PurchaseType.LINE_OF_CREDIT
                ) && (
                  <SelectInput
                    name="lenderId"
                    label={lang.lender}
                    data={lenderApi?.payload?.data.map((it) => it.displayInfo)}
                    value={vehicle?.lenderId}
                    error={errors?.lenderId}
                    onChange={updateVehicle}
                  />
                )}
            </InputWrapper>
            {!refreshDatePicker && vehicle?.posOdometerReading <= parseInt(mfgWholeVehicleWarrantyKm) && (
              <DateInput
                name="inServiceDate"
                label={lang.vehicleOriginalInServiceDate}
                dateTimeFormat={defaultInputDateFormat}
                error={errors?.inServiceDate}
                value={vehicle?.inServiceDate}
                isLocal={true}
                onChange={updateVehicle}
                icon={<Icon class="fas-calendar-alt" />}
              />
            )}
            <TextInput
              name="purchaseState"
              label={lang.purchaseState}
              value={vehicle?.purchaseState}
              readOnly
              onChange={updateVehicle}
            />
          </>
        )}

        {!hideFeature && (
          <>
            <InputWrapper>
              <NumberInput
                name="interestRate"
                label={lang.interestRate}
                value={vehicle?.interestRate}
                error={errors?.interestRate}
                step={0.01}
                roundOnBlur
                onChange={updateVehicle}
                disabled={
                  !vehicle?.purchaseType &&
                  (vehicle?.purchaseType === PurchaseType.CASH || vehicle?.purchaseType === PurchaseType.LINE_OF_CREDIT)
                }
              />
            </InputWrapper>
            <TextInput
              name="bodyColor"
              label={lang.bodyColor}
              value={vehicle?.bodyColor}
              error={errors?.bodyColor}
              onChange={updateVehicle}
            />
          </>
        )}
      </FormWrapper>
    </>
  );
}
