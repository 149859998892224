import { addLog } from 'assets/components/feedback/Feedback';
import { ProgressKey } from 'assets/components/progressWindow/ProgressWindow';
import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { date } from 'assets/utils/data/Date';
import iterateWithProgress, { ProgressInfo } from 'assets/utils/data/Iterations';
import { combineStrings } from 'assets/utils/data/String';
import { autoTriggerDownload } from 'assets/utils/dom/Download';
import { convertProductPropertyByInputType } from 'assets/utils/other/Utils.Other.ProductInputTypeMappings';
import { defaultLimit, defaultOffset, HttpStatus } from 'config/Api.Config';
import { compact, flatten, get, isEmpty, keys, uniqBy } from 'lodash';
import User from 'models/core/user/Model.User';
import ContractStatusCode from 'models/enums/ContractStatusCode';
import InputType from 'models/enums/InputType';
import ProductDocumentPropertyPath from 'models/enums/ProductDocumentPropertyPath';
import Product from 'models/productManagement/product/Model.Product';
import Subfee from 'models/productManagement/subfee/Model.Subfee';
import contractChangeCreatorApi from 'models/sales/contract/changeCreator/Api.Contract.ChangeCreator';
import contractCreateVeraFortePolicyApi from 'models/sales/contract/createVertaforePolicy/Api.CreateVertaforePolicy';
import contractExportApi from 'models/sales/contract/export/Api.Contract.Export';
import contractGenerateDocumentsApi from 'models/sales/contract/generateDocuments/Api.Contract.GenerateDocuments';
import revisionGenerateDocumentsApi from 'models/sales/contract/generateRevisionDocuments/Api.Contract.GenerateRevisionDocuments';
import contractListApi from 'models/sales/contract/list/Api.Contract.List';
import Contract from 'models/sales/contract/Model.Contract';
import contractRevisionsApi from 'models/sales/contract/revisions/Api.Contract.Revisions';
import contractStatusFlowApi from 'models/sales/contract/statusFlow/Api.Contract.StatusFlow';
import contractConsentResendApi from 'models/sales/contractConsent/resend/Api.ContractConsent.Resend';
import ContractRevision from 'models/sales/contractRevision/Model.ContractRevision';
import Lender from 'models/sales/lender/Model.Lender';
import { useEffect, useMemo, useState } from 'react';
import { salesContractIndexRoute } from './Sales.Contract.Index';
import VertaforeDocumentStatuses from 'models/enums/VertaforeDocumentStatuses';
import { productEnhancementListApi } from 'models/productManagement/productEnhancement/Model.ProductEnhancement';

export enum SalesContractAction {
  INFO = 'info',
  ADJUST_DISTRIBUTIONS = 'adjustDistributions',
}
export enum SalesContractInfoTab {
  INFO = 'info',
  DOCUMENTS = 'documents',
  REVISIONS = 'revisions',
}
export function useSalesContractRouter() {
  return usePageRouter<Module.Sales.Contract.Params, Module.Sales.Contract.Query>({
    route: salesContractIndexRoute,
  });
}
export default function useSalesContract() {
  const { permissions, user } = useAuthStore();
  const pageRouter = useSalesContractRouter();
  const { lang } = getLocales();

  const contractStatusInfoApi = useApi({ action: contractStatusFlowApi });
  const contractStatusInfo = contractStatusInfoApi.payload;

  const canView = permissions.CREATE_CONTRACT || permissions.EDIT_CONTRACT || permissions.DELETE_CONTRACT;
  const listApi = useApi({
    action: Contract.list,
    default: {
      limit: defaultLimit,
      offset: defaultOffset,
      dealerId: user?.dealers?.map((d) => d.id) ?? [],
      distributorId: user.distributorId ?? undefined,
    },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({ ...pageRouter.params }, { ...pageRouter.query, list: req }),
    wait: !canView,
  });
  const findApi = useApi(
    {
      action: Contract.find,
      wait: !canView || !pageRouter.params.id,
      body: {
        id: Number(pageRouter.params.id),
      },
    },
    [pageRouter.params.id]
  );
  const exportApi = useApi({
    action: Contract.export,
    wait: !canView || isEmpty(listApi?.payload?.data),
  });

  const selected = useMemo(() => {
    return (
      listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString()) ?? findApi?.payload
    );
  }, [listApi.payload?.data, pageRouter.params.id, pageRouter.params.action]);

  const productApi = useApi({
    action: Product.find,
    wait: true,
  });
  useEffect(() => {
    if (selected?.productId) productApi.execute({ id: selected.productId });
    else productApi.reset(true);
  }, [selected?.productId]);

  const lenderApi = useApi({
    action: Lender.find,
    wait: true,
  });
  useEffect(() => {
    if (selected?.vehicle?.lenderId) lenderApi.execute({ id: selected.vehicle?.lenderId });
    else lenderApi.reset(true);
  }, [selected?.vehicle?.lenderId]);
  useEffect(() => {
    if (lenderApi?.payload && lenderApi?.payload?.id === selected?.vehicle?.lenderId)
      selected.vehicle.lender = new Lender(lenderApi?.payload);
  }, [lenderApi?.payload]);

  const subfeeApi = useApi({
    action: Subfee.list,
    wait: true,
  });
  useEffect(() => {
    const ids = compact(keys(selected?.subfees)).map((it) => Number(it));
    if (selected?.id && ids.length) {
      subfeeApi.execute({
        id: ids,
      });
    } else {
      subfeeApi.reset(true);
    }
  }, [selected?.id]);

  const statuses = useMemo(() => {
    return keys(contractStatusInfo).map((status) => {
      const [primary, secondary, reason] = status.split('_');
      const mainStatus = combineStrings(' - ', primary, secondary);
      return {
        id: status,
        subtitle: mainStatus,
        title: reason ?? mainStatus,
      };
    });
  }, [contractStatusInfo]);

  //#region Revisions
  const revisionsApi = useApi({ action: contractRevisionsApi, wait: true });
  useEffect(() => {
    if (selected?.id) {
      revisionsApi.execute({ id: selected?.id });
    } else {
      revisionsApi.reset(true);
    }
  }, [selected?.id]);

  const [selectedRevision, selectRevision] = useState<ContractRevision>();
  const revisions = useMemo(() => {
    return revisionsApi.payload?.map((data) => {
      return new ContractRevision({
        ...data,
        contractJson: { ...data.contractJson, product: selected?.product?.toJSON() },
      });
    });
  }, [revisionsApi.payload]);
  //#endregion

  const userListApi = useApi({
    action: User.list,
    wait: !canView,
  });

  async function resendContractConsent({
    id,
    requestType,
  }: {
    id: number;
    requestType: Model.Enum.ContractConsentRequestType;
  }) {
    const res = await contractConsentResendApi({ id, requestType });
    if (res.status === HttpStatus.ok) addLog({ success: lang.emailSuccessfullySent });
    else addLog({ error: lang.saveError });
  }

  async function exportContractsToExcel() {
    const contractExportQuery = { ...pageRouter.query.list };
    delete contractExportQuery.limit;
    delete contractExportQuery.offset;

    const res = await contractExportApi({ ...contractExportQuery });

    if (res?.status === HttpStatus.ok) {
      const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res?.payload}`;
      autoTriggerDownload({ url, name: `Contracts_${date().format('DD-MM-YY')}.xlsx` });
      addLog({ success: lang.exportSuccess });
    } else addLog({ error: lang.exportError });
  }

  async function changeContractCreator({ contractId, creatorId }: { contractId: number; creatorId: number }) {
    const res = await contractChangeCreatorApi({ contractId, creatorId });

    if (res?.status === HttpStatus.ok) {
      addLog({ success: lang.contractCreatorSuccessfullyChanged });
      listApi.execute((body) => body);
    } else addLog({ error: res.message });
  }

  //#region Veraforte
  const contractUnknownVertaforeStatusListApi = useApi({ action: Contract.getContractsWithUnknownVertaForeStatus });
  async function syncWithVertafore({ contractId }: { contractId: number }) {
    const res = await contractCreateVeraFortePolicyApi({ contractId });

    if (res?.status === HttpStatus.ok) {
      addLog({ success: lang.successfullySyncedWithVertafore });
      findApi.execute((body) => body);
    } else addLog({ error: res.message });
  }

  async function syncAllWithVertafore(onSaveProgress?: (key: ProgressKey, progressInfo: ProgressInfo) => void) {
    const contracts = await contractListApi({
      statusCode: [
        ContractStatusCode.OPEN_SUBMITTED,
        ContractStatusCode.OPEN_UNDER_REVIEW,
        ContractStatusCode.OPEN_UNPAID,
        ContractStatusCode.OPEN_WAITING_CONSENT,
        ContractStatusCode.PAID_ACTIVE,
        ContractStatusCode.PAID_EXPIRED,
      ],
    });
    const contractsToSync = contracts?.payload?.data?.filter(
      (it) =>
        it.product?.isStoredInBMS === true &&
        it.vertaforeStatus &&
        (it.vertaforeStatus === VertaforeDocumentStatuses.UNKNWON ||
          it.vertaforeStatus === VertaforeDocumentStatuses.NO_UPLOADING)
    );

    if (isEmpty(contractsToSync)) {
      addLog({ warning: 'No contracts for sync!' });
      return;
    }

    const syncedLimitCount = await iterateWithProgress(
      contractsToSync,
      async (contract) => {
        if (contract.id) await contractCreateVeraFortePolicyApi({ contractId: contract.id });
      },
      (p) => onSaveProgress('syncAllWithVertafore', p)
    );

    if (syncedLimitCount) listApi.execute((b) => b);
  }
  //#endregion

  const productEnhancementList = useApi({
    action: productEnhancementListApi,
  });
  const enhancements = productEnhancementList.payload?.data ?? [];
  const enhancementDocs = uniqBy(
    flatten(
      compact(selected?.contractEnhancements)?.map(
        (ce) => enhancements.find((e) => e?.id === ce?.productEnhancementId)?.productEnhancementDocuments
      )
    ),
    (it) => it?.id
  );

  //#region Contract documents
  async function regenerateContractDocuments(selectedContract: Contract) {
    const productDocuments = productApi.payload?.productDocuments ?? [];
    const additionalFields = flatten(
      productDocuments?.map((d) =>
        d.productAdditionalFields
          ?.filter((a) => a.defaultValue)
          ?.map((a) => ({
            productDocumentId: d.id,
            key: a.key,
            value: a.defaultValue,
          }))
      )
    );
    const enhancementAdditionalFields = flatten(
      enhancementDocs?.map((d) =>
        d.productEnhancementAdditionalFields
          ?.filter((a) => a.defaultValue)
          ?.map((a) => ({
            productEnhancementDocumentId: d.id,
            key: a.key,
            value: a.defaultValue,
          }))
      )
    );
    const finalFields = flatten(productDocuments?.map((d) => d.productAdditionalFields)).map((f) => {
      if (f.propertyPath) {
        return {
          productDocumentId: f.productDocumentId,
          key: f.key,
          value: convertProductPropertyByInputType({
            propertyPath: f.propertyPath as ProductDocumentPropertyPath,
            value: get({ contract: selectedContract }, f.propertyPath),
            inputLabel: f.label || f.key,
            inputType: f.type as InputType,
            options: {
              isPackage: selectedContract?.isPackage,
              isTaxExempt: selectedContract?.client?.taxExempt,
            },
          }),
        };
      } else {
        const additionalField = additionalFields?.find(
          (it) => it.key == f.key && it.productDocumentId === f.productDocumentId
        );
        return {
          productDocumentId: f.productDocumentId,
          key: f.key,
          value: convertProductPropertyByInputType({
            value: additionalField?.value,
            inputLabel: f.label || f.key,
            inputType: f.type as InputType,
            options: {
              isPackage: selectedContract?.isPackage,
              isTaxExempt: selectedContract?.client.taxExempt,
            },
          }),
        };
      }
    });
    const finalEnhancementFields = flatten(enhancementDocs?.map((d) => d.productEnhancementAdditionalFields)).map(
      (f) => {
        if (f.propertyPath) {
          return {
            productEnhancementDocumentId: f.productEnhancementDocumentId,
            key: f.key,
            value: convertProductPropertyByInputType({
              propertyPath: f.propertyPath as ProductDocumentPropertyPath,
              value: get({ contract: selectedContract }, f.propertyPath),
              inputLabel: f.label || f.key,
              inputType: f.type as InputType,
              options: {
                isPackage: selectedContract?.isPackage,
                isTaxExempt: selectedContract?.client?.taxExempt,
              },
            }),
          };
        } else {
          const enhancementAdditionalField = enhancementAdditionalFields?.find(
            (it) => it.key == f.key && it.productEnhancementDocumentId === f.productEnhancementDocumentId
          );
          return {
            productEnhancementDocumentId: f.productEnhancementDocumentId,
            key: f.key,
            value: convertProductPropertyByInputType({
              value: enhancementAdditionalField?.value,
              inputLabel: f.label || f.key,
              inputType: f.type as InputType,
              options: {
                isPackage: selectedContract?.isPackage,
                isTaxExempt: selectedContract?.client.taxExempt,
              },
            }),
          };
        }
      }
    );

    if (finalFields?.length) {
      const res = selectedContract?.revisionNumber
        ? await revisionGenerateDocumentsApi({
            contractId: selectedContract?.id,
            productPdfInputItems: finalFields,
            productEnhancementPdfInputItems: finalEnhancementFields,
          })
        : await contractGenerateDocumentsApi({
            contractId: selectedContract?.id,
            productPdfInputItems: finalFields,
            productEnhancementPdfInputItems: finalEnhancementFields,
          });
      if (res?.status !== HttpStatus.ok) return addLog({ error: res.message });
      else {
        addLog({ success: lang.saveSuccess });
        listApi.execute((b) => b);
      }
    }
  }
  //#endregion
  return {
    canView,
    pageRouter,
    listApi,
    exportApi,
    selected,
    selectedProduct: productApi?.payload,
    permissions,
    revisionsApi,
    subfeeApi,
    findApi,
    lender: lenderApi?.payload,
    userListApi,
    changeContractCreator,
    resendContractConsent,
    exportContractsToExcel,
    syncWithVertafore,
    syncAllWithVertafore,
    regenerateContractDocuments,
    contractUnknownVertaforeStatusListApi,
    statuses,
    revisions,
    selectedRevision,
    selectRevision,
  };
}

export function useSalesContractProvider() {
  return useDataProvider<ReturnType<typeof useSalesContract>>();
}
