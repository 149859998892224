import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import RenderProps from 'assets/components/helpers/RenderProps';
import TextInput from 'assets/components/inputs/text/TextInput';
import ThemeTableSimple2 from 'assets/components/table/themes/Theme.Table.Simple.2';
import { getLocales } from 'assets/locales/Locale';
import { combineStrings } from 'assets/utils/data/String';
import { compact, entries, flatten, groupBy, max, sum, uniq } from 'lodash';
import { Fragment } from 'react';

type ObjPaths = Array<{
  fullPath: string;
  rootPath: string | null;
  currentSegment: string;
  depth: number;
  maxDepth: number;
  paths: ObjPaths;
}>;

export function splitAndNest(paths: string[], depth: number = 1, rootPath: string | null = null) {
  const objPaths: ObjPaths = [];
  const firstPaths = groupBy(
    paths.map((path) => {
      const [firstPath, ...rest] = path.split('.');
      return { firstPath, rest: rest.join('.') };
    }),
    (it) => it.firstPath
  );
  for (const [firstPath, restPaths] of entries(firstPaths)) {
    const rest = compact(flatten(restPaths.map((it) => it.rest)));
    const fullPath = combineStrings('.', rootPath, firstPath);
    const currentObjPaths = objPaths.find((it) => it.fullPath === fullPath);
    const mappedPaths = rest.length ? splitAndNest(rest, depth + 1, fullPath) : [];
    const maxDepth = mappedPaths.length === 0 ? 1 : max(mappedPaths.map((it) => it.maxDepth)) + 1;
    if (currentObjPaths) {
      currentObjPaths.paths.push(...mappedPaths);
      currentObjPaths.maxDepth = max([currentObjPaths.maxDepth, maxDepth]);
    } else {
      objPaths.push({
        fullPath,
        rootPath,
        currentSegment: firstPath,
        depth,
        maxDepth,
        paths: mappedPaths,
      });
    }
  }
  return objPaths;
}
export function extractPaths(objPaths: ObjPaths): string[] {
  return flatten(objPaths.map((it) => (it.paths.length === 0 ? it.fullPath : extractPaths(it.paths))));
}
export function calculateColSpan(objPaths: ObjPaths): number {
  if (objPaths.length === 0) return 1;
  else return sum(objPaths.map(({ paths }) => calculateColSpan(paths)));
}

export function GenerateTableHeader({
  objPaths,
  render,
}: {
  objPaths: ObjPaths;
  render?: Utils.RenderProps<{ path: string; editableKey: string }>;
}) {
  const maxDepth = max(objPaths.map((it) => it.maxDepth));
  const nextObj = flatten(objPaths.map((it) => it.paths));
  return (
    <>
      <tr>
        <DataMap
          data={objPaths}
          render={({ data: { currentSegment, rootPath, paths }, index }) => {
            const relativeMaxDepth = max(paths.map((it) => it.maxDepth)) ?? 0;
            return (
              <th
                key={combineStrings('-', rootPath, index.toString())}
                colSpan={calculateColSpan(paths)}
                rowSpan={maxDepth - relativeMaxDepth}
              >
                {render
                  ? RenderProps({ render }, 'render', {
                      path: rootPath,
                      editableKey: currentSegment,
                    })
                  : currentSegment}
              </th>
            );
          }}
        />
      </tr>
      {!!nextObj.length && <GenerateTableHeader objPaths={nextObj} render={render} />}
    </>
  );
}
export function TemplateInput({
  path,
  editableKey,
  onUpdate,
  onAdd,
  onDelete,
}: {
  path: string;
  editableKey: string;
  onUpdate: (oldPath: string, newPath: string) => void;
  onAdd: (newPath: string) => void;
  onDelete: (newPath: string) => void;
}) {
  const { lang } = getLocales();
  return (
    <TextInput
      name="tempKey"
      value={editableKey}
      onChange={({ tempKey }) => onUpdate(combineStrings('.', path, editableKey), combineStrings('.', path, tempKey))}
      iconBefore={
        <Button
          class={ThemeButtonCircle}
          media="fas-trash"
          onClick={() => onDelete(combineStrings('.', path, editableKey))}
          htmlElementProps={{ title: lang.add }}
        />
      }
      icon={
        <Button
          class={ThemeButtonCircle}
          media="fas-arrow-down"
          onClick={() => onAdd(combineStrings('.', path, editableKey, 'TEMP_KEY'))}
          htmlElementProps={{ title: lang.add }}
        />
      }
    />
  );
}
export default function ObjectTable<D, A = unknown>(props: {
  paths: string[];
  pathAliases?: A[];
  setPaths?(paths: string[]): void;
  data?: D[];
  render?: Utils.RenderProps<{ data: D; path: string; pathAlias?: A }>;
  renderRow?: Utils.RenderProps<{ data: D }>;
  focusOnLoad?: boolean;
  style?: React.CSSProperties;
  className?: string;
  translateHeader?: boolean;
}) {
  const objPaths = splitAndNest(props.paths);
  const uniquePaths = extractPaths(objPaths);

  return (
    <table
      className={combineStrings(' ', ThemeTableSimple2, props.className)}
      style={props.style}
      ref={
        !props.focusOnLoad
          ? undefined
          : (t) => {
              if (!t?.parentElement?.matches(':focus-within'))
                t?.parentElement?.scrollIntoView({
                  behavior: 'smooth',
                  inline: 'start',
                  block: 'center',
                });
            }
      }
    >
      <thead
        ref={
          !props.translateHeader
            ? undefined
            : (t) => {
                if (t?.parentElement) t.parentElement.style.top = `calc(100% - ${t.getBoundingClientRect().height}px)`;
              }
        }
      >
        <GenerateTableHeader
          objPaths={objPaths}
          render={
            !props.setPaths
              ? undefined
              : ({ path, editableKey }) => (
                  <TemplateInput
                    path={path}
                    editableKey={editableKey}
                    onUpdate={(oldPath, newPath) =>
                      props.setPaths(
                        uniq(
                          props.paths.map((it) =>
                            new RegExp(`^${oldPath.replace('.', '\\.')}(\\.|$)`, 'g').test(it)
                              ? it.replace(new RegExp(`^${oldPath.replace('.', '\\.')}`, 'g'), newPath)
                              : it
                          )
                        )
                      )
                    }
                    onDelete={(oldPath) =>
                      props.setPaths(
                        compact(
                          props.paths.map((it) =>
                            new RegExp(`^${oldPath.replace('.', '\\.')}(\\.|$)`, 'g').test(it) ? null : it
                          )
                        )
                      )
                    }
                    onAdd={(newPath) => props.setPaths([...props.paths, newPath])}
                  />
                )
          }
        />
      </thead>
      <tbody>
        <DataMap
          data={props.data}
          render={({ data, key: dataKey }) =>
            props.renderRow ? (
              RenderProps(props, 'renderRow', {
                data,
              })
            ) : (
              <tr key={dataKey}>
                <DataMap
                  data={uniquePaths}
                  render={({ data: path, index }) => {
                    return (
                      <Fragment key={path}>
                        {RenderProps(props, 'render', {
                          data,
                          path,
                          pathAlias: props.pathAliases && props.pathAliases[index],
                        })}
                      </Fragment>
                    );
                  }}
                />
              </tr>
            )
          }
        />
      </tbody>
    </table>
  );
}
