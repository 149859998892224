import Model from 'models/Model';
import PricingDistributionTemplate from 'models/productManagement/pricingDistributionTemplate/Model.PricingDistributionTemplate';
import Product from 'models/productManagement/product/Model.Product';
import DistributorProductPricingDistribution from '../distributorProductPricingDistribution/Model.DistributorProductPricingDistribution';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import { omit, pick } from 'lodash';

export async function distributorProductSaveApi(
  body: Api.Core.DistributorProduct.Save.IRequest
): Promise<Api.Res<Api.Core.DistributorProduct.Save.IResponse>> {
  const serverResponse = await executeServerApi<
    Api.Core.DistributorProduct.Save.IRequest,
    Api.Core.DistributorProduct.Save.IResponse,
    Api.Core.DistributorProduct.Save.IHeaders
  >({
    method: body?.id ? HttpMethod.put : HttpMethod.post,
    url: '/api/ProductManagement/DistributorProduct',
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}

export async function distributorProductDeleteApi(
  body: Api.Core.DistributorProduct.Delete.IRequest
): Promise<Api.Res<Api.Core.DistributorProduct.Delete.IResponse>> {
  const serverResponse = await executeServerApi<
    Api.Core.DistributorProduct.Delete.IRequest,
    Api.Core.DistributorProduct.Delete.IResponse,
    Api.Core.DistributorProduct.Delete.IHeaders
  >({
    method: HttpMethod.delete,
    url: createPathFromRoute('/api/ProductManagement/DistributorProduct/:distributorId/:productId', body),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export async function distributorProductListApi(
  body: Api.Core.DistributorProduct.List.IRequest
): Promise<Api.Res<Api.Core.DistributorProduct.List.IResponse>> {
  return await executeServerApi<
    Omit<Api.Core.DistributorProduct.List.IRequest, 'distributorId' | 'productId'>,
    Api.Core.DistributorProduct.List.IResponse,
    Api.Core.DistributorProduct.List.IHeaders
  >({
    method: HttpMethod.get,
    url: createPathFromRoute(
      '/api/ProductManagement/DistributorProduct/:distributorId/:productId',
      pick(body, 'distributorId', 'productId')
    ),
    body: omit(body, 'distributorId', 'productId'),
    headers: getDefaultHeaders(),
  });
}

export default class DistributorProduct
  extends Model<Model.IDistributorProduct>({ icon: 'if-login' })
  implements Model.Instance<Model.IDistributorProduct>
{
  id: number;
  productId: number;
  product: Product;
  distributorId: number;
  pricingDistributionTemplateId: number;
  pricingDistributionTemplate?: PricingDistributionTemplate;
  pricingDistributions?: Array<DistributorProductPricingDistribution>;

  constructor(
    data: Partial<Model.IDistributorProduct> | Utils.FormData<Model.IDistributorProduct>,
    language?: Locale.Languages
  ) {
    super(
      {
        pricingDistributionTemplate: PricingDistributionTemplate,
        product: Product,
        pricingDistributions: [DistributorProductPricingDistribution],
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.productId,
      title: this.product.name || this.productId.toString(),
      info: this.pricingDistributionTemplate?.name,
    };
  }

  static async list(body: Api.Core.DistributorProduct.List.IRequest) {
    const { payload, ...rest } = await distributorProductListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new DistributorProduct(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await distributorProductSaveApi(this);
  }
  async delete() {
    return await distributorProductDeleteApi({ id: this.id });
  }
}
