import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Contract.CreateVertaforePolicy.IRequest;
type Response = Api.Core.Contract.CreateVertaforePolicy.IResponse;
type Headers = Api.Core.Contract.CreateVertaforePolicy.IHeaders;
const url = '/api/Sales/Contract/CreateVertaforePolicy/:contractId';
export default async function contractCreateVertaforePolicyApi(params: Request): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.post,
    url: createPathFromRoute(url, params),
    headers: getDefaultHeaders(),
  });
}
