import Box from 'assets/components/box/Box';
import DataMap from 'assets/components/dataMap/DataMap';
import { getLocales } from 'assets/locales/Locale';
import ThemeOtherVerticalDivider from 'assets/themes/other/Theme.Other.VerticalDivider';
import { entries, reduce, sortBy, values } from 'lodash';
import { Fragment } from 'react';
import { usePriceEditor } from '../hooks/usePriceEditor';

export default function PriceAdjustmentSummary() {
  const { translate } = getLocales();
  const { updateStats, savedCount, isSaving } = usePriceEditor();
  const totalSaved = reduce(values(savedCount), (p, c) => p + c, 0);
  const totalUpdated = reduce(values(updateStats), (p, c) => p + c.updatedCount, 0);
  const percentUpdated = (totalSaved / totalUpdated) * 100;
  return (
    <>
      {isSaving && (
        <>
          <DataMap
            data={sortBy(
              entries(updateStats).filter(([, s]) => s.updatedCount),
              (it) => it[0]
            )}
            render={({ data: [key, stats] }) => (
              <Fragment key={key}>
                <Box>
                  {savedCount[key]}/{stats.updatedCount} {translate(key)}
                </Box>
                <>
                  &nbsp;
                  <hr className={ThemeOtherVerticalDivider} />
                  &nbsp;
                </>
              </Fragment>
            )}
          />
          {percentUpdated.toFixed(0)}%
        </>
      )}
      {!isSaving && (
        <DataMap
          data={sortBy(
            entries(updateStats).filter(([, s]) => s.totalCount),
            (it) => it[0]
          )}
          render={({ data: [key, stats], isLast }) => (
            <Fragment key={key}>
              <Box>
                {stats.updatedCount}/{stats.totalCount} {translate(key)}
              </Box>
              {!isLast && (
                <>
                  &nbsp;
                  <hr className={ThemeOtherVerticalDivider} />
                  &nbsp;
                </>
              )}
            </Fragment>
          )}
        />
      )}
    </>
  );
}
