import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';

type PriceAdjustmentInputProps = {
  amount: number | null;
  baseAmount?: number | null;
  onAmountChange(value: number | null): void;
  onAmountCopy?(value: number | null): void;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
  discountUnit?: Utils.DiscountUnit;
  unit?: Utils.CalcUnit;
  onUnitChange?(unit: Utils.CalcUnit): void;
  onDiscountUnitChange?(unit: Utils.DiscountUnit): void;
};
export default function PriceAdjustmentInput(props: PriceAdjustmentInputProps) {
  const { lang } = getLocales();
  const delta = props.baseAmount && props.amount ? props.amount - props.baseAmount : null;
  const unit = props.unit ?? props.discountUnit;
  return (
    <NumberInput
      name="amount"
      value={props.amount}
      placeholder={props.placeholder}
      error={props.error}
      onChange={({ amount }) => props.onAmountChange(amount)}
      style={{
        formInput: {
          minWidth: '140px',
        },
        input: {
          textAlign: 'right',
        },
      }}
      min={0}
      step={0.01}
      roundOnBlur
      disabled={props.disabled}
      iconBefore={
        props.onAmountCopy && (
          <Button
            class={ThemeButtonCircle}
            media={<Icon class="fas-copy" />}
            htmlElementProps={{ title: props.baseAmount ? `Increase by: ${delta ?? 0}` : lang.copy }}
            onClick={() => {
              if (props.onAmountCopy) {
                props.onAmountCopy(props.baseAmount ? delta : props.amount);
              }
            }}
          />
        )
      }
      icon={
        !props.onUnitChange && !props.onDiscountUnitChange ? (
          <Icon class={'fas-dollar-sign'} />
        ) : (
          <DropdownButton
            horizontalPosition="auto_fixed"
            verticalPosition="auto_fixed"
            constrainment="constrained"
            subcomponents={ThemeDropdownButtonPopup}
            buttonMedia={unit === 'percent' ? '%' : unit === 'addition' ? '+-' : unit === 'replacement' ? '$' : '?'}
            dropdownOptions={
              <DataMap
                data={
                  props.onUnitChange
                    ? [
                        { id: 'percent' as Utils.DiscountUnit, title: 'Percent (%)' },
                        { id: 'addition' as Utils.DiscountUnit, title: 'Addition (+- $)' },
                        { id: 'replacement' as Utils.DiscountUnit, title: 'Replacement ($)' },
                      ]
                    : [
                        { id: 'percent' as Utils.DiscountUnit, title: 'Percent (%)' },
                        { id: 'addition' as Utils.DiscountUnit, title: 'Addition (+- $)' },
                      ]
                }
                render={({ data: unitOption }) => (
                  <Button
                    key={unitOption.id}
                    class={ThemeButtonMenuItem}
                    media={<Icon class={unitOption.id === unit ? 'fas-check-square' : 'far-square'} />}
                    render={unitOption.title}
                    onClick={props.onUnitChange ?? props.onDiscountUnitChange}
                    data={unitOption.id}
                  />
                )}
              />
            }
          />
        )
      }
    />
  );
}
