import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import React, { Fragment } from 'react';
import { ProductEnhancementEditTab, useProductEnhancementProvider } from '../../ProductEnhancement.Hooks';

const titleStyle: React.CSSProperties = { fontSize: Theme.FontSize.L, fontWeight: 'bold' };
export default function ProductEditNavigation() {
  const { lang, translate } = getLocales();
  const { pageRouter, productEnhancement, uploadedDocuments } = useProductEnhancementProvider();

  return (
    <>
      <div
        data-active={pageRouter.query.editTab === ProductEnhancementEditTab.INFO ? true : undefined}
        onClick={() => pageRouter.updateQuery({ editTab: ProductEnhancementEditTab.INFO })}
        title={lang.showMore}
      >
        <div style={titleStyle}>{lang.generalInformation}</div>
        {!productEnhancement?.id ? (
          <p style={{ lineHeight: Theme.Size.em(1.6) }}>{lang.noInfo}</p>
        ) : (
          <p style={{ lineHeight: Theme.Size.em(1.6) }}>
            <b>{lang.product}</b>:&nbsp;{productEnhancement.name}
            <br />
            <b>{lang.type}</b>: {translate(productEnhancement.productType)}
            <br />
          </p>
        )}
      </div>
      {!!productEnhancement?.id && (
        <div
          data-active={pageRouter.query.editTab === ProductEnhancementEditTab.DOCUMENTS ? true : undefined}
          onClick={() => pageRouter.updateQuery({ editTab: ProductEnhancementEditTab.DOCUMENTS })}
          title={lang.showMore}
        >
          <span style={titleStyle}>{lang.documents}</span>
          <br />
          {!uploadedDocuments.length && lang.noInfo}
          <DataMap
            data={uploadedDocuments}
            render={({ data: doc }) => (
              <Fragment key={doc.id}>
                <span
                  style={{
                    color: Theme.Color.success,
                  }}
                  title={lang.uploaded}
                >
                  <Icon class="fas-check" />
                  &nbsp;
                  {doc.mediaFile?.name || lang.unknownDocument}
                </span>
                &nbsp;&nbsp;
              </Fragment>
            )}
          />
        </div>
      )}
    </>
  );
}
