import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, salesHomeRoute } from 'config/Api.Config';
import useSalesContract from './Sales.Contract.Hooks';
import ContractInfo from './sections/Sales.Contract.Info';
import ContractList from './sections/Sales.Contract.List';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import SalesContractAdjustDistributions from './sections/Sales.Contract.AdjustDistributions';
import ContractCancellation from './sections/Sales.Contract.Cancellation';

export const salesContractIndexRoute = salesHomeRoute + '/contract/:action?/:id?';
export default function SalesContractIndex() {
  const contractHook = useSalesContract();

  if (!contractHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={contractHook}
        render={({ pageRouter }) => (
          <>
            {pageRouter.params?.id && pageRouter.params.action === 'cancellation' && <ContractCancellation />}
            {pageRouter.params?.id && pageRouter.params.action === 'info' ? <ContractInfo /> : <ContractList />}
            {pageRouter.params?.id && pageRouter.params.action === 'adjustDistributions' && (
              <SalesContractAdjustDistributions />
            )}
          </>
        )}
      />
    </>
  );
}
