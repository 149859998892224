import MediaFile from 'models/core/mediaFile/Model.MediaFile';
import Model from 'models/Model';
import ProductAdditionalField from '../productAdditionalField/Model.ProductAdditionalField';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { omit } from 'lodash';

export async function productEnhancementDocumentSaveApi(body: Api.Core.ProductEnhancementDocument.Save.IRequest) {
  const serverResponse = await executeServerApi<
    Api.Core.ProductEnhancementDocument.Save.IRequest,
    Api.Core.ProductEnhancementDocument.Save.IResponse,
    Api.Core.ProductEnhancementDocument.Save.IHeaders
  >({
    method: HttpMethod.post,
    url: createPathFromRoute('/api/ProductManagement/ProductEnhancement/:id/Documents', {
      id: body.productEnhancementId,
    }),
    body: {
      ...omit(body, 'productEnhancementAdditionalFields'),
      additionalFields: body.productEnhancementAdditionalFields,
    } as any,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export async function productEnhancementDocumentUpdateAllFieldsApi(
  body: Api.Core.ProductEnhancementDocument.UpdateAll.IRequest
) {
  const serverResponse = await executeServerApi<
    Omit<Api.Core.ProductEnhancementDocument.UpdateAll.IRequest, 'productEnhancementId'>,
    Api.Core.ProductEnhancementDocument.UpdateAll.IResponse,
    Api.Core.ProductEnhancementDocument.UpdateAll.IHeaders
  >({
    method: HttpMethod.put,
    url: createPathFromRoute('/api/ProductManagement/ProductEnhancement/:id/Documents', {
      id: body.productEnhancementId,
    }),
    body: {
      ...omit(body, 'productEnhancementAdditionalFields', 'productEnhancementId'),
      additionalFields: body.productEnhancementAdditionalFields,
    } as any,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export async function productEnhancementDocumentDeleteApi(params: Api.Core.ProductEnhancementDocument.Delete.IRequest) {
  const serverResponse = await executeServerApi<
    Api.Core.ProductEnhancementDocument.Delete.IRequest,
    Api.Core.ProductEnhancementDocument.Delete.IResponse,
    Api.Core.ProductEnhancementDocument.Delete.IHeaders
  >({
    method: HttpMethod.delete,
    url: createPathFromRoute('/api/ProductManagement/ProductEnhancement/:id/Documents/:documentId', params),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export default class ProductEnhancementDocument
  extends Model<Model.IProductEnhancementDocument>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IProductEnhancementDocument>
{
  id: number;
  productEnhancementId: number;
  mediaFileId: number;
  mediaFile: MediaFile;
  formFile?: File;
  productDocumentType: Model.Enum.EngineType;
  productEnhancementAdditionalFields: Array<ProductAdditionalField>;

  constructor(
    data: Partial<Model.IProductEnhancementDocument> | Utils.FormData<Model.IProductEnhancementDocument>,
    language?: Locale.Languages
  ) {
    super(
      {
        mediaFile: MediaFile,
        productEnhancementAdditionalFields: [ProductAdditionalField],
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.mediaFile?.name || this.id.toString(),
    };
  }
  async save() {
    return await productEnhancementDocumentSaveApi(this);
  }
  async updateAllFields() {
    return await productEnhancementDocumentSaveApi({
      productEnhancementId: this.productEnhancementId,
      productEnhancementAdditionalFields: this.productEnhancementAdditionalFields,
    });
  }
  async delete() {
    return await productEnhancementDocumentDeleteApi({
      id: this.id,
      documentId: this.mediaFileId,
    });
  }
}
