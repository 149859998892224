import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import FormatValue from 'assets/components/formatValue/FormatValue';
import Icon from 'assets/components/icon/Icon';
import { inputStyles } from 'assets/components/inputs/Input';
import ThemeTableSimple2 from 'assets/components/table/themes/Theme.Table.Simple.2';
import { getLocales } from 'assets/locales/Locale';
import { GenerateTableHeader, splitAndNest } from 'assets/templates/objectTable/ObjectTable';
import { get } from 'lodash';
import { usePriceEditor } from '../hooks/usePriceEditor';
import usePriceEditorCopy from '../hooks/usePriceEditorCopy';
import PriceAdjustmentFilterAndSort from './PriceAdjustmentFilterAndSort';
import PriceAdjustmentInput from './PriceAdjustmentInput';
import { useState } from 'react';

type PriceEditTableProps = {
  dealerId?: number;
  distributorId?: number;
};

export default function PriceEditTable({ dealerId, distributorId }: PriceEditTableProps) {
  const { lang } = getLocales();
  const { filters, prices, templates, updatePrices } = usePriceEditor();
  const { copyPrice, updateCopyPrice, currency, applyCopyChanges } = usePriceEditorCopy();
  const [areBaseDistributionsShown, setAreBaseDistributionsShown] = useState(true);
  const [areDistributorDistributionsShown, setAreDistributorDistributionsShown] = useState(true);

  return (
    <table className={ThemeTableSimple2}>
      <thead>
        <tr>
          <th style={{ textAlign: 'right', fontWeight: 'bold' }}>FILTERS</th>
          <DataMap
            data={filters}
            render={({ data: filter }) => (
              <th key={filter.key}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <PriceAdjustmentFilterAndSort filter={filter} />
                </div>
              </th>
            )}
          />
          {areBaseDistributionsShown && (
            <th colSpan={templates.base.length} style={{ padding: 0, textAlign: 'right' }}>
              <table>
                <GenerateTableHeader objPaths={splitAndNest(templates.base)} />
              </table>
            </th>
          )}
          <th style={{ textAlign: 'right' }}>
            <Button
              class={ThemeButtonCircle}
              style={{ marginLeft: 'auto' }}
              media={areBaseDistributionsShown ? 'fas-compress' : 'fas-expand'}
              onClick={setAreBaseDistributionsShown}
              data={!areBaseDistributionsShown}
              render={lang.base}
            />
          </th>
          {!!dealerId && <th style={{ textAlign: 'right' }}>{lang.adjusted}</th>}
          {!!distributorId && !!templates.distributor?.length && areDistributorDistributionsShown && (
            <th colSpan={templates.distributor.length} style={{ padding: 0, textAlign: 'right' }}>
              <table>
                <GenerateTableHeader objPaths={splitAndNest(templates.distributor)} />
              </table>
            </th>
          )}
          {!!distributorId && (
            <th style={{ textAlign: 'right' }}>
              <Button
                class={ThemeButtonCircle}
                style={{ marginLeft: 'auto' }}
                media={areDistributorDistributionsShown ? 'fas-compress' : 'fas-expand'}
                onClick={setAreDistributorDistributionsShown}
                data={!areDistributorDistributionsShown}
                render={lang.distributor}
              />
            </th>
          )}
          {!!dealerId && !!distributorId && <th style={{ textAlign: 'right' }}>{lang.adjusted}</th>}
          {!!dealerId && <th style={{ textAlign: 'center' }}>{lang.sugRetailPrice}</th>}
          {!!dealerId && <th style={{ textAlign: 'center' }}>{lang.maxAmount}</th>}
          <th style={{ textAlign: 'center' }}>{lang.actions}</th>
        </tr>
      </thead>
      <tbody>
        <DataMap
          data={prices}
          render={({ data: price, index }) => (
            <tr key={index}>
              <td style={{ textAlign: 'right', fontWeight: 'bold' }}>PRICE</td>
              <DataMap
                data={filters}
                render={({ data: filter }) => (
                  <td key={filter.key} style={{ textAlign: 'center' }}>
                    {filter.options.find((o) => o.value === price.filters[filter.key])?.label ??
                      price.filters[filter.key]}
                  </td>
                )}
              />
              {areBaseDistributionsShown && (
                <DataMap
                  data={templates.base}
                  render={({ data: templateKey }) => {
                    const discount = get(price.discount?.distributions, templateKey);
                    return (
                      <td key={templateKey} style={{ textAlign: 'right' }}>
                        <Box class={inputStyles.wrapper.inputWrapper}>
                          <PriceAdjustmentInput
                            amount={get(price.base.distributions, templateKey)?.amount}
                            onAmountChange={(amount) => {
                              updatePrices(price, {
                                base: {
                                  distributions: {
                                    [templateKey]: { amount, currency },
                                  },
                                },
                              });
                            }}
                            onAmountCopy={(amount) => {
                              updatePrices(prices, {
                                base: {
                                  distributions: {
                                    [templateKey]: { amount, currency },
                                  },
                                },
                              });
                            }}
                            //disabled={!isEditDisabled}
                          />
                          {!!dealerId && (
                            <PriceAdjustmentInput
                              placeholder={lang.adjustment}
                              amount={discount?.amount}
                              error={
                                discount
                                  ? !discount.amount || !discount.unit
                                    ? lang.mustNotBeEmpty
                                    : undefined
                                  : undefined
                              }
                              onAmountChange={(amount) => {
                                updatePrices(price, {
                                  discount: {
                                    distributions: {
                                      [templateKey]: {
                                        unit: !amount ? null : !discount?.unit ? 'percent' : discount?.unit,
                                        amount,
                                      },
                                    },
                                  },
                                });
                              }}
                              discountUnit={discount?.unit}
                              onDiscountUnitChange={(unit) =>
                                updatePrices(price, {
                                  discount: {
                                    distributions: {
                                      [templateKey]: { unit },
                                    },
                                  },
                                })
                              }
                              onAmountCopy={(amount) => {
                                updatePrices(prices, {
                                  discount: {
                                    distributions: {
                                      [templateKey]: {
                                        unit: discount?.unit,
                                        amount,
                                      },
                                    },
                                  },
                                });
                              }}
                              //disabled={!isEditDisabled}
                            />
                          )}
                        </Box>
                      </td>
                    );
                  }}
                />
              )}
              <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                <FormatValue value={!price.basePrice ? 'N/A' : `${price.basePrice.toFixed(2)}$`} />
              </td>
              {!!dealerId && (
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  <FormatValue value={!price.adjustedBasePrice ? 'N/A' : `${price.adjustedBasePrice.toFixed(2)}$`} />
                </td>
              )}
              {!!distributorId && !!templates.distributor?.length && areDistributorDistributionsShown && (
                <DataMap
                  data={templates.distributor}
                  render={({ data: templateKey }) => {
                    const discount = get(price.discount?.distributorDistributions, templateKey);
                    return (
                      <td key={templateKey} style={{ textAlign: 'right' }}>
                        <Box class={inputStyles.wrapper.inputWrapper}>
                          <PriceAdjustmentInput
                            disabled={!price.base.distributions}
                            amount={get(price.distributor?.distributions, templateKey)?.amount}
                            onAmountChange={(amount) =>
                              updatePrices(price, {
                                distributor: {
                                  distributions: {
                                    [templateKey]: { amount, currency },
                                  },
                                },
                              })
                            }
                            onAmountCopy={(amount) =>
                              updatePrices(prices, {
                                distributor: {
                                  distributions: {
                                    [templateKey]: { amount, currency },
                                  },
                                },
                              })
                            }
                            //disabled={!isEditDisabled}
                          />
                          {!!dealerId && (
                            <PriceAdjustmentInput
                              disabled={!price.base.distributions}
                              placeholder={lang.adjustment}
                              amount={discount?.amount}
                              error={
                                discount
                                  ? !discount.amount || !discount.unit
                                    ? lang.mustNotBeEmpty
                                    : undefined
                                  : undefined
                              }
                              onAmountChange={(amount) =>
                                updatePrices(price, {
                                  discount: {
                                    distributorDistributions: {
                                      [templateKey]: {
                                        unit: !amount ? null : !discount?.unit ? 'percent' : discount?.unit,
                                        amount,
                                      },
                                    },
                                  },
                                })
                              }
                              discountUnit={discount?.unit}
                              onDiscountUnitChange={(unit) =>
                                updatePrices(price, {
                                  discount: {
                                    distributorDistributions: {
                                      [templateKey]: { unit },
                                    },
                                  },
                                })
                              }
                              onAmountCopy={(amount) =>
                                updatePrices(prices, {
                                  discount: {
                                    distributorDistributions: {
                                      [templateKey]: {
                                        unit: discount?.unit,
                                        amount,
                                      },
                                    },
                                  },
                                })
                              }
                              //disabled={!isEditDisabled}
                            />
                          )}
                        </Box>
                      </td>
                    );
                  }}
                />
              )}
              {!!distributorId && (
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  <FormatValue value={!price.distributorPrice ? 'N/A' : `${price.distributorPrice.toFixed(2)}$`} />
                </td>
              )}
              {!!distributorId && !!dealerId && (
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  <FormatValue
                    value={!price.adjustedDistributorPrice ? 'N/A' : `${price.adjustedDistributorPrice.toFixed(2)}$`}
                  />
                </td>
              )}
              {!!dealerId && (
                <td>
                  <PriceAdjustmentInput
                    amount={price.limitation?.limitations.minAmount}
                    disabled={!price.base.distributions}
                    error={
                      price.limitation?.limitations?.minAmount != null
                        ? price.limitation?.limitations?.maxAmount != null &&
                          price.limitation?.limitations?.minAmount > price.limitation?.limitations?.maxAmount
                          ? lang.minMaxMissmatchMessage
                          : price.limitation?.limitations?.minAmount <= 0
                          ? lang.mustBeMoreThanAmount.replace('{amount}', '0')
                          : undefined
                        : undefined
                    }
                    onAmountChange={(minAmount) =>
                      updatePrices(price, {
                        limitation: {
                          limitations: {
                            minAmount,
                            unit: price.limitation?.limitations?.unit ?? 'replacement',
                          },
                        },
                      })
                    }
                    baseAmount={price.adjustedDistributorPrice}
                    onAmountCopy={(delta) => {
                      updatePrices(prices, (p) => ({
                        limitation: {
                          limitations: {
                            minAmount: delta ? p.adjustedDistributorPrice + delta : delta,
                            unit: p.limitation?.limitations.unit ?? 'replacement',
                          },
                        },
                      }));
                    }}
                    //disabled={!isEditDisabled}
                  />
                </td>
              )}
              {!!dealerId && (
                <td>
                  <PriceAdjustmentInput
                    amount={price.limitation?.limitations?.maxAmount}
                    disabled={!price.base.distributions}
                    error={
                      price.limitation?.limitations?.maxAmount != null
                        ? price.limitation?.limitations.minAmount != null &&
                          price.limitation?.limitations.minAmount > price.limitation?.limitations.maxAmount
                          ? lang.minMaxMissmatchMessage
                          : price.limitation?.limitations.maxAmount <= 0
                          ? lang.mustBeMoreThanAmount.replace('{amount}', '0')
                          : undefined
                        : undefined
                    }
                    onAmountChange={(maxAmount) =>
                      updatePrices(price, {
                        limitation: {
                          limitations: {
                            maxAmount,
                            unit: price.limitation?.limitations?.unit ?? 'replacement',
                          },
                        },
                      })
                    }
                    baseAmount={price.adjustedDistributorPrice}
                    onAmountCopy={(delta) => {
                      updatePrices(prices, (p) => ({
                        limitation: {
                          limitations: {
                            maxAmount: delta ? p.adjustedDistributorPrice + delta : delta,
                            unit: p.limitation?.limitations.unit ?? 'replacement',
                          },
                        },
                      }));
                    }}
                    //disabled={!isEditDisabled}
                  />
                </td>
              )}
              <td>
                <Button
                  onClick={() =>
                    updatePrices(price, {
                      base: {
                        distributions: null,
                        subfees: null,
                      },
                    })
                  }
                  class={{ button: ThemeButtonCircle }}
                  media={<Icon class="fas-trash-alt" />}
                  htmlElementProps={{ title: `${lang.clear} ${lang.price}` }}
                />
              </td>
            </tr>
          )}
        />
      </tbody>
      <tfoot>
        <tr>
          <td style={{ textAlign: 'right', fontWeight: 'bold' }}>BULK ADJUST</td>
          <DataMap
            data={filters}
            render={({ data: filter }) => (
              <td key={filter.key}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <PriceAdjustmentFilterAndSort filter={filter} isForCopy />
                </div>
              </td>
            )}
          />
          {areBaseDistributionsShown && (
            <DataMap
              data={templates.base}
              render={({ data: templateKey }) => {
                const base = get(copyPrice.base, templateKey);
                const discount = get(copyPrice.discount?.distributions, templateKey);
                return (
                  <td key={templateKey} style={{ textAlign: 'right' }}>
                    <Box class={inputStyles.wrapper.inputWrapper}>
                      <PriceAdjustmentInput
                        {...base}
                        onAmountChange={(amount) =>
                          updateCopyPrice({
                            base: {
                              [templateKey]: { amount, unit: base?.unit },
                            },
                          })
                        }
                        onUnitChange={(unit) =>
                          updateCopyPrice({
                            base: {
                              [templateKey]: { unit, amount: base?.amount },
                            },
                          })
                        }
                        //disabled={!isEditDisabled}
                      />
                      {!!dealerId && (
                        <PriceAdjustmentInput
                          {...discount}
                          onAmountChange={(amount) =>
                            updateCopyPrice({
                              discount: {
                                distributions: {
                                  [templateKey]: { amount, unit: discount?.unit },
                                },
                              },
                            })
                          }
                          onUnitChange={(unit) =>
                            updateCopyPrice({
                              discount: {
                                distributions: {
                                  [templateKey]: { amount: discount?.amount, unit },
                                },
                              },
                            })
                          }
                          //disabled={!isEditDisabled}
                        />
                      )}
                    </Box>
                  </td>
                );
              }}
            />
          )}
          <td style={{ textAlign: 'right', fontWeight: 'bold' }}></td>
          {!!dealerId && <td style={{ textAlign: 'right', fontWeight: 'bold' }}></td>}
          {!!distributorId && !!templates.distributor?.length && areDistributorDistributionsShown && (
            <DataMap
              data={templates.distributor}
              render={({ data: templateKey }) => {
                const distributor = get(copyPrice.distributor, templateKey);
                const discount = get(copyPrice.discount?.distributorDistributions, templateKey);
                return (
                  <td key={templateKey} style={{ textAlign: 'right' }}>
                    <Box class={inputStyles.wrapper.inputWrapper}>
                      <PriceAdjustmentInput
                        {...distributor}
                        onAmountChange={(amount) =>
                          updateCopyPrice({
                            distributor: {
                              [templateKey]: { amount, unit: distributor?.unit },
                            },
                          })
                        }
                        onUnitChange={(unit) =>
                          updateCopyPrice({
                            discount: {
                              distributorDistributions: {
                                [templateKey]: { amount: distributor?.amount, unit },
                              },
                            },
                          })
                        }
                        //disabled={!isEditDisabled}
                      />
                      {!!dealerId && (
                        <PriceAdjustmentInput
                          {...discount}
                          onAmountChange={(amount) => {
                            updateCopyPrice({
                              discount: {
                                distributorDistributions: {
                                  [templateKey]: { amount, unit: discount?.unit },
                                },
                              },
                            });
                          }}
                          onUnitChange={(unit) =>
                            updateCopyPrice({
                              discount: {
                                distributorDistributions: {
                                  [templateKey]: { amount: discount?.amount, unit },
                                },
                              },
                            })
                          }
                          //disabled={!isEditDisabled}
                        />
                      )}
                    </Box>
                  </td>
                );
              }}
            />
          )}
          {!!distributorId && <td style={{ textAlign: 'right', fontWeight: 'bold' }}></td>}
          {!!distributorId && !!dealerId && <td style={{ textAlign: 'right', fontWeight: 'bold' }}></td>}
          {!!dealerId && (
            <td>
              <PriceAdjustmentInput
                {...copyPrice.limitation?.minAmount}
                onAmountChange={(amount) =>
                  updateCopyPrice({
                    limitation: { minAmount: { amount, unit: copyPrice.limitation?.minAmount?.unit } },
                  })
                }
                onUnitChange={(unit) =>
                  updateCopyPrice({
                    limitation: { minAmount: { amount: copyPrice.limitation?.minAmount?.amount, unit } },
                  })
                }
                //disabled={!isEditDisabled}
              />
            </td>
          )}
          {!!dealerId && (
            <td>
              <PriceAdjustmentInput
                {...copyPrice.limitation?.maxAmount}
                onAmountChange={(amount) =>
                  updateCopyPrice({
                    limitation: { maxAmount: { amount, unit: copyPrice.limitation?.maxAmount?.unit } },
                  })
                }
                onUnitChange={(unit) =>
                  updateCopyPrice({
                    limitation: { maxAmount: { amount: copyPrice.limitation?.maxAmount?.amount, unit } },
                  })
                }
                //disabled={!isEditDisabled}
              />
            </td>
          )}
          <td>
            <Button
              onClick={applyCopyChanges}
              class={{ button: ThemeButtonCircle }}
              media={<Icon class="fas-level-up-alt" />}
              htmlElementProps={{ title: lang.apply }}
            />
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
