import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import ObjectTable from 'assets/templates/objectTable/ObjectTable';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDealerProvider } from '../../Dealer.Hooks';
import { DealerForm } from '../Dealer.Edit';

const DealerContractConsentProductAggregate = () => {
  const { selected } = useDealerProvider();
  const { form } = useModalForm<DealerForm>();

  const updateValue = (value: string, id: number) => {
    const aggregates = form.data?.contractConsentProductAggregates?.map((item) =>
      id === item?.productId ? { ...item, description: value } : item
    );
    form.update({ contractConsentProductAggregates: aggregates });
  };

  useEffect(() => {
    const currentAggregates =
      form.data?.contractConsentProductAggregates ??
      selected?.products
        ?.filter((it) => it.isCustomerConsentRequired)
        ?.map((it) => ({ productId: it.id, description: undefined } as Model.IContractConsentProductAggregate));

    const updatedAggregates = currentAggregates?.filter((curr) =>
      form.data?.products?.some((p) => p.id === curr.productId)
    );

    form.update({ contractConsentProductAggregates: isEmpty(updatedAggregates) ? null : updatedAggregates });
  }, [form.data?.products]);

  return (
    <FormWrapper>
      <ObjectTable
        paths={[]}
        data={form?.data?.contractConsentProductAggregates}
        renderRow={({ data: row }) => {
          const product = selected?.products?.find((it) => it.id === row?.productId);
          return (
            <tr key={row?.productId}>
              <td>{product?.name}</td>
              <td>
                <TextInput
                  name="value"
                  value={row.description}
                  onChange={({ value }) => {
                    updateValue(value, row?.productId);
                  }}
                />
              </td>
            </tr>
          );
        }}
      />
    </FormWrapper>
  );
};

export default DealerContractConsentProductAggregate;
