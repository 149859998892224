import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import Icon from 'assets/components/icon/Icon';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import Distributor from 'models/sales/distributor/Model.Distributor';
import React, { useEffect } from 'react';
import { DealerGroupForm } from '../DealerGroup.Edit';
import dealerListApi from 'models/dealerManagement/dealer/list/Api.Dealer.List';
import productListApi from 'models/productManagement/product/list/Api.Product.List';
import { isEmpty } from 'lodash';

const DealerGroupGeneralForm: React.FC = () => {
  const { lang } = getLocales();
  const { isAdministrator } = useAuthStore();
  const { form } = useModalForm<DealerGroupForm>();
  const distributorApi = useApi({ action: Distributor.list });
  useEffect(() => {
    (async () => {
      const { payload: dealersPayload } = await dealerListApi({
        distributorId: form.data?.distributorId ? [form.data?.distributorId] : null,
      });
      const dealerIds = dealersPayload?.data?.map((it) => it.id);
      const { payload: productsPayload } = await productListApi({ dealerId: dealerIds });
      const newProducts = productsPayload?.data?.filter((it) => it.isCustomerConsentRequired);

      const currentAggregates = form.data?.contractConsentProductAggregates ?? [];
      const mergedAggregates = newProducts.map((p) => {
        const existingAggregate = currentAggregates.find((agg) => agg.productId === p.id);
        return existingAggregate
          ? { ...existingAggregate }
          : ({ productId: p.id, description: undefined } as Model.IContractConsentProductAggregate);
      });

      form.update({
        contractConsentProductAggregates: isEmpty(mergedAggregates) ? null : mergedAggregates,
        newDistributorProducts: newProducts,
      });
    })();
  }, [form.data?.distributorId]);

  return (
    <FormWrapper>
      <h3>{lang.group}</h3>
      <TextInput
        name="name"
        label={lang.name}
        value={form.data.name}
        error={form.errors.name}
        onChange={form.update}
        icon={<Icon class="if-quill-pen" />}
      />
      <SelectInput
        name="distributorId"
        label={lang.distributor}
        data={distributorApi.payload?.data?.map((it) => it?.displayInfo)}
        value={form.data?.distributorId}
        error={form.errors?.distributorId}
        onChange={form.update}
        disabled={!isAdministrator}
        icon={<Icon class="fas-globe" />}
      />
    </FormWrapper>
  );
};
export default DealerGroupGeneralForm;
