import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useEffect, useState } from 'react';
import { AllPricingOptions, PricesGetFunction, PricingFilterMappings, UsedPricingFilters } from './usePriceEditor';
import Product from 'models/productManagement/product/Model.Product';
import {
  dealerDistributionDiscountDeleteApi,
  dealerDistributionDiscountListApi,
  dealerDistributionDiscountSaveApi,
} from 'models/dealerManagement/dealerDistributionDiscount/Model.DealerDistributionDiscount';
import {
  dealerDistributionLimitationDeleteApi,
  dealerDistributionLimitationListApi,
  dealerDistributionLimitationSaveApi,
} from 'models/dealerManagement/dealerDistributionLimitation/Model.DealerDistributionLimitation';
import { compact, first, reduce } from 'lodash';
import customPricePointListApi from 'models/productManagement/customPricePoint/list/Api.CustomPricePoint.List';
import nonWarrantyPricePointListApi from 'models/productManagement/nonWarrantyPricePoint/list/Api.NonWarrantyPricePoint.List';
import productPricingPlanClassListApi from 'models/productManagement/productPricingPlanClass/list/Api.ProductPricingPlanClass.List';
import tierListApi from 'models/productManagement/tier/list/Api.Tier.List';
import vehicleClassListApi from 'models/productManagement/vehicleClass/list/Api.VehicleClass.List';
import productPricingPlanListApi from 'models/productManagement/productPricingPlan/list/Api.ProductPricingPlan.List';
import { getLocales } from 'assets/locales/Locale';
import { combineStrings } from 'assets/utils/data/String';
import ProductEnhancement from 'models/productManagement/productEnhancement/Model.ProductEnhancement';
import {
  dealerEnhancementDistributionDiscountDeleteApi,
  dealerEnhancementDistributionDiscountListApi,
  dealerEnhancementDistributionDiscountSaveApi,
} from 'models/dealerManagement/dealerEnhancementDistributionDiscount/Model.DealerEnhancementDistributionDiscount';
import {
  dealerEnhancementDistributionLimitationDeleteApi,
  dealerEnhancementDistributionLimitationListApi,
  dealerEnhancementDistributionLimitationSaveApi,
} from 'models/dealerManagement/dealerEnhancementDistributionLimitation/Model.DealerEnhancementDistributionLimitation';
import {
  distributorProductEnhancementListApi,
  distributorProductEnhancementSaveApi,
} from 'models/sales/distributorProductEnhancement/Model.ProductEnhancementDistributor';
import {
  distributorProductListApi,
  distributorProductSaveApi,
} from 'models/sales/distributorProduct/Model.ProductDistributor';
import {
  productTierPricePointDeleteApi,
  productTierPricePointListApi,
  productTierPricePointSaveApi,
} from 'models/productManagement/productTierPricePoint/Model.ProductTierPricePoint';
import {
  productEnhancementTierPricePointDeleteApi,
  productEnhancementTierPricePointListApi,
  productEnhancementTierPricePointSaveApi,
} from 'models/productManagement/productEnhancementTierPricePoint/Model.ProductEnhancementTierPricePoint';
import pricingLimitTemplateListApi from 'models/productManagement/pricingLimitTemplate/list/Api.PricingLimitTemplate.List';
import customPricePointSaveApi from 'models/productManagement/customPricePoint/save/Api.CustomPricePoint.Save';
import customPricePointDeleteApi from 'models/productManagement/customPricePoint/delete/Api.CustomPricePoint.Delete';
import {
  distributorProductPricingDistributionDeleteApi,
  distributorProductPricingDistributionSaveApi,
} from 'models/sales/distributorProductPricingDistribution/Model.DistributorProductPricingDistribution';
import nonWarrantyPricePointDeleteApi from 'models/productManagement/nonWarrantyPricePoint/delete/Api.NonWarrantyPricePoint.Delete';
import nonWarrantyPricePointSaveApi from 'models/productManagement/nonWarrantyPricePoint/save/Api.NonWarrantyPricePoint.Save';
import productPricingPlanClassSaveApi from 'models/productManagement/productPricingPlanClass/save/Api.ProductPricingPlanClass.Save';
import productPricingPlanClassDeleteApi from 'models/productManagement/productPricingPlanClass/delete/Api.ProductPricingPlanClass.Delete';
import {
  distributorProductEnhancementPricingDistributionDeleteApi,
  distributorProductEnhancementPricingDistributionSaveApi,
} from 'models/sales/distributorProductEnhancementPricingDistribution/Model.DistributorProductEnhancementPricingDistribution';

type ProductPricesProps = {
  id?: number;
  dealerId?: number;
  distributorId?: number;
  dataType: Module.ProductManagement.PriceAdjustments.DataType;
};
type ProductPriceInfo = {
  type?: Utils.PricePointType;
  prices: Array<AllPricingOptions> | PricesGetFunction;
  usedFilters: UsedPricingFilters;
  dataMappings: PricingFilterMappings;
  distributorTemplateId?: number;
  hasAccess?: boolean;
  isLoading?: boolean;
  distributorProductId?: number;
  templateKeys?: string[];
  title?: string;
  pricingLimitTemplates?: Model.IPricingLimitTemplate[];
};
export default function useProductPrices({ id, dataType, dealerId, distributorId }: ProductPricesProps) {
  const { permissions, isDealer } = useAuthStore();
  const { lang } = getLocales();
  const [reloadCount, setReloadCount] = useState(0);

  const [productPriceInfo, setProductPriceInfo] = useState<ProductPriceInfo>({
    isLoading: !!id && !!dataType,
    prices: [],
    dataMappings: {},
    usedFilters: {},
  });
  useEffect(() => {
    (async () => {
      if (!id || dataType !== 'product') {
        return;
      }
      const productId = id;
      setProductPriceInfo((old) => ({ ...old, isLoading: true }));
      const canFetchDealer = !!dealerId;
      const canFetchDistributor = !!distributorId && !isDealer;
      const [product, discounts, limitations, distributorProducts] = await Promise.all([
        Product.find({ id: productId }),
        canFetchDealer ? dealerDistributionDiscountListApi({ productId: [productId], dealerId }) : undefined,
        canFetchDealer ? dealerDistributionLimitationListApi({ productId: productId, dealerId }) : undefined,
        canFetchDistributor ? distributorProductListApi({ distributorId, productId }) : undefined,
      ]);

      const distributorProduct = first(distributorProducts?.payload?.data ?? []);

      function getAdditionalPricingInfo(id: number, type: Utils.PricePointType) {
        const discount = discounts?.payload?.data?.find((d) => d.pricePointId === id && d.pricePointType === type);
        const limitation = limitations?.payload?.data?.find((d) => d.pricePointId === id && d.pricePointType === type);
        const distributorDistributions = distributorProduct?.pricingDistributions?.find(
          (d) => d.pricePointId === id && d.pricePointType === type
        );

        const partialPricingInfo: Partial<AllPricingOptions> = {
          discount: discount
            ? {
                id: discount.id,
                distributions: discount.distributionDiscount,
                distributorDistributions: discount.distributorDistributionDiscount,
              }
            : undefined,
          limitation: limitation
            ? {
                id: limitation.id,
                limitations: limitation.limitations,
              }
            : undefined,
          distributor: distributorProduct
            ? {
                id: distributorDistributions?.id,
                distributions: distributorDistributions?.distributions,
              }
            : undefined,
        };
        return partialPricingInfo;
      }
      const title = combineStrings(
        ' ',
        product.payload.category?.name,
        product.payload.name,
        product.payload.code ? `(${product.payload.code})` : undefined
      );

      if (product.payload?.productType === 'CUSTOM') {
        const pricePoints = await customPricePointListApi({ productId: [productId] });
        const type: Utils.PricePointType = 'CustomPricePoint';
        setProductPriceInfo({
          distributorProductId: distributorProduct?.id,
          isLoading: false,
          type,
          templateKeys: product.payload.templateKeys,
          title,
          distributorTemplateId: distributorProduct?.pricingDistributionTemplateId,
          prices: pricePoints.payload?.data?.map((p) => ({
            id: p.id,
            type: type,
            filters: {
              termInMonths: p.termInMonths,
              customInfo: p.customInfo,
              customInfoGroup: p.customInfoGroup,
            },
            base: {
              distributions: p.pricingDistributions,
              subfees: p.subfees,
            },
            ...getAdditionalPricingInfo(p.id, type),
          })),
          usedFilters: {
            termInMonths: 'local',
            customInfo: 'local',
            customInfoGroup: 'local',
          },
          dataMappings: {},
          hasAccess:
            permissions.VIEW_CUSTOMPRICEPOINT ||
            permissions.CREATE_CUSTOMPRICEPOINT ||
            permissions.EDIT_CUSTOMPRICEPOINT ||
            permissions.DELETE_CUSTOMPRICEPOINT,
        });
      } else if (product.payload?.productType === 'NON-WARRANTY') {
        const pricePoints = await nonWarrantyPricePointListApi({ productId: [productId] });
        const type: Utils.PricePointType = 'NonWarrantyPricePoint';
        setProductPriceInfo({
          distributorProductId: distributorProduct?.id,
          isLoading: false,
          type,
          templateKeys: product.payload.templateKeys,
          title,
          distributorTemplateId: distributorProduct?.pricingDistributionTemplateId,
          prices: pricePoints.payload?.data?.map((p) => ({
            id: p.id,
            type: type,
            filters: {
              termInMonths: p.termInMonths,
              minVehiclePriceCAD: p.minVehiclePriceCAD,
              maxVehiclePriceCAD: p.maxVehiclePriceCAD,
            },
            base: {
              distributions: p.pricingDistributions,
              subfees: p.subfees,
            },
            ...getAdditionalPricingInfo(p.id, type),
          })),
          usedFilters: {
            termInMonths: 'local',
            minVehiclePriceCAD: 'local',
            maxVehiclePriceCAD: 'local',
          },
          dataMappings: {},
          hasAccess:
            permissions.VIEW_NONWARRANTYPRICEPOINT ||
            permissions.CREATE_NONWARRANTYPRICEPOINT ||
            permissions.EDIT_NONWARRANTYPRICEPOINT ||
            permissions.DELETE_NONWARRANTYPRICEPOINT,
        });
      } else if (product.payload?.productType === 'WARRANTY_TIER-BASED') {
        const pricePoints = await productTierPricePointListApi({ productId: [productId] });
        const type: Utils.PricePointType = 'ProductTierPricePoint';
        const tiers = await tierListApi();
        setProductPriceInfo({
          distributorProductId: distributorProduct?.id,
          isLoading: false,
          type,
          templateKeys: product.payload.templateKeys,
          title,
          distributorTemplateId: distributorProduct?.pricingDistributionTemplateId,
          prices: pricePoints.payload?.data?.map((p) => ({
            id: p.id,
            type: type,
            filters: {
              termInMonths: p.termInMonths,
              tier: p.tierId,
            },
            base: {
              distributions: p.pricingDistributions,
              subfees: p.subfees,
            },
            ...getAdditionalPricingInfo(p.id, type),
          })),
          usedFilters: {
            termInMonths: 'local',
            tier: 'local',
          },
          dataMappings: {
            tier: compact(tiers?.payload?.data?.map((t) => ({ value: t.id, label: t.name }))),
          },
          hasAccess:
            permissions.VIEW_PRODUCTTIERPRICEPOINT ||
            permissions.CREATE_PRODUCTTIERPRICEPOINT ||
            permissions.EDIT_PRODUCTTIERPRICEPOINT ||
            permissions.DELETE_PRODUCTTIERPRICEPOINT,
        });
      } else if (product.payload?.productType === 'WARRANTY_CLASS-BASED') {
        const type: Utils.PricePointType = 'ProductPricingPlanClass';
        const vehicleClasses = await vehicleClassListApi();
        const pricingPlans = await productPricingPlanListApi({ productId: [productId] });
        const pricingLimitTemplates = await pricingLimitTemplateListApi();

        setProductPriceInfo({
          pricingLimitTemplates: pricingLimitTemplates?.payload?.data,
          distributorProductId: distributorProduct?.id,
          isLoading: false,
          type,
          templateKeys: product.payload.templateKeys,
          title,
          distributorTemplateId: distributorProduct?.pricingDistributionTemplateId,
          prices: async (pricingFilters) => {
            const selectedPlanIds = pricingFilters
              ?.find((it) => it.key === 'productPricingPlan')
              ?.options?.filter((it) => it.isSelected)
              ?.map((it) => it.value) as number[];

            const pricePoints = await productPricingPlanClassListApi({
              productId: [productId],
              productPricingPlanId: selectedPlanIds,
            });
            return pricePoints.payload?.data?.map((p) => ({
              id: p.id,
              type: type,
              filters: {
                productPricingPlan: p.productPricingPlanId,
                vehicleClass: p.vehicleClassId,
                deductible: p.deductible,
                customInfo: p.customInfo,
                limits: pricingLimitTemplates?.payload?.data?.find(
                  (t) => t.maxKm === p.maxKm && t.term === p.termInMonths
                )?.id,
              },
              base: {
                distributions: p.pricingDistributions,
                subfees: p.subfees,
              },
              ...getAdditionalPricingInfo(p.id, type),
            }));
          },
          usedFilters: {
            productPricingPlan: 'server',
            vehicleClass: 'local',
            limits: 'local',
            deductible: 'local',
            customInfo: 'local',
          },
          dataMappings: {
            limits: compact(
              pricingLimitTemplates?.payload?.data?.map((t) => ({
                value: t.id,
                label: `${t.term}m/${t.maxKm}km`,
              }))
            ),
            vehicleClass: compact(
              vehicleClasses?.payload?.data?.map((c) => ({
                value: c.id,
                label: combineStrings(' - ', c.primaryCode, c.secondaryCode),
              }))
            ),
            productPricingPlan: compact(
              pricingPlans?.payload?.data?.map((pp) => ({
                value: pp.id,
                label: combineStrings(
                  ' | ',
                  pp.name,
                  pp.isForNewVehicle ? lang.new : lang.preOwned,
                  pp.warrantyOption,
                  `${pp.minOdometerKm}km`,
                  `${pp.maxOdometerKm}km`
                ),
              }))
            ),
          },
          hasAccess:
            permissions.VIEW_PRODUCTPRICINGPLAN ||
            permissions.CREATE_PRODUCTPRICINGPLAN ||
            permissions.EDIT_PRODUCTPRICINGPLAN ||
            permissions.DELETE_PRODUCTPRICINGPLAN,
        });
      }
    })();
  }, [id, dataType, permissions, isDealer, dealerId, distributorId, reloadCount]);

  useEffect(() => {
    (async () => {
      if (!id || dataType !== 'productEnhancement') {
        return;
      }
      const productEnhancementId = id;
      setProductPriceInfo((old) => ({ ...old, isLoading: true }));
      const canFetchDealer = !!dealerId;
      const canFetchDistributor = !!distributorId && !isDealer;
      const [productEnhancement, discounts, limitations, distributorProducts] = await Promise.all([
        ProductEnhancement.find({ id: productEnhancementId }),
        canFetchDealer
          ? dealerEnhancementDistributionDiscountListApi({ productEnhancementId: [productEnhancementId], dealerId })
          : undefined,
        canFetchDealer
          ? dealerEnhancementDistributionLimitationListApi({ productEnhancementId: productEnhancementId, dealerId })
          : undefined,
        canFetchDistributor ? distributorProductEnhancementListApi({ distributorId, productEnhancementId }) : undefined,
      ]);

      const distributorProduct = first(distributorProducts?.payload?.data ?? []);

      function getAdditionalPricingInfo(id: number, type: Utils.PricePointType) {
        const discount = discounts?.payload?.data?.find((d) => d.pricePointId === id && d.pricePointType === type);
        const limitation = limitations?.payload?.data?.find((d) => d.pricePointId === id && d.pricePointType === type);
        const distributorDistributions = distributorProduct?.pricingDistributions?.find(
          (d) => d.pricePointId === id && d.pricePointType === type
        );

        const partialPricingInfo: Partial<AllPricingOptions> = {
          discount: discount
            ? {
                id: discount.id,
                distributions: discount.distributionDiscount,
                distributorDistributions: discount.distributorDistributionDiscount,
              }
            : undefined,
          limitation: limitation
            ? {
                id: limitation.id,
                limitations: limitation.limitations,
              }
            : undefined,
          distributor: distributorDistributions
            ? {
                id: distributorDistributions.id,
                distributions: distributorDistributions.distributions,
              }
            : undefined,
        };
        return partialPricingInfo;
      }

      const title = productEnhancement.payload.name;

      if (
        !productEnhancement.payload?.productType ||
        productEnhancement.payload?.productType === 'WARRANTY_TIER-BASED'
      ) {
        const pricePoints = await productEnhancementTierPricePointListApi({
          productEnhancementId: [productEnhancementId],
        });
        const type: Utils.PricePointType = 'ProductEnhancementTierPricePoint';
        const tiers = await tierListApi();
        setProductPriceInfo({
          distributorProductId: distributorProduct?.id,
          isLoading: false,
          type,
          title,
          templateKeys: productEnhancement.payload.pricingDistributionTemplate?.keys ?? [],
          distributorTemplateId: distributorProduct?.pricingDistributionTemplateId,
          prices: pricePoints.payload?.data?.map((p) => ({
            id: p.id,
            type: type,
            filters: {
              termInMonths: p.termInMonths,
              tier: p.tierId,
            },
            base: {
              distributions: p.pricingDistributions,
            },
            ...getAdditionalPricingInfo(p.id, type),
          })),
          usedFilters: {
            termInMonths: 'local',
            tier: 'local',
          },
          dataMappings: {
            tier: compact(tiers?.payload?.data?.map((t) => ({ value: t.id, label: t.name }))),
          },
          hasAccess:
            permissions.VIEW_PRODUCTTIERPRICEPOINT ||
            permissions.CREATE_PRODUCTTIERPRICEPOINT ||
            permissions.EDIT_PRODUCTTIERPRICEPOINT ||
            permissions.DELETE_PRODUCTTIERPRICEPOINT,
        });
      }
    })();
  }, [id, dataType, permissions, isDealer, dealerId, distributorId, reloadCount]);

  async function save(price: AllPricingOptions) {
    async function saveDistributor(price: AllPricingOptions) {
      let savedId = productPriceInfo?.distributorProductId;
      if (price.distributor?.isUpdated) {
        const response = await distributorProductSaveApi({
          id: productPriceInfo?.distributorProductId,
          productId: id,
          distributorId,
          pricingDistributionTemplateId: productPriceInfo?.distributorTemplateId,
        });
        if (response.payload) savedId = response.payload;
        if (!price.distributor.distributions && price.distributor.id) {
          await distributorProductPricingDistributionDeleteApi(price.distributor.id);
        } else {
          await distributorProductPricingDistributionSaveApi({
            id: price.distributor.id,
            pricePointId: price.id,
            pricePointType: productPriceInfo.type,
            distributions: price.distributor.distributions,
            distributorProductId: savedId,
          });
        }
      }
    }
    async function saveDiscounts(price: AllPricingOptions) {
      if (price.discount?.isUpdated) {
        if (!price.discount.distributions && !price.discount.distributorDistributions && price.discount.id) {
          await dealerDistributionDiscountDeleteApi(price.discount.id);
        } else {
          await dealerDistributionDiscountSaveApi({
            productId: id,
            dealerId,
            distributorId,
            id: price.discount.id,
            pricePointId: price.id,
            pricePointType: productPriceInfo.type,
            distributionDiscount: price.discount.distributions,
            distributorDistributionDiscount: price.discount.distributorDistributions,
          });
        }
      }
    }
    async function saveLimits(price: AllPricingOptions) {
      if (price.limitation?.isUpdated) {
        if (
          price.limitation.limitations?.maxAmount == null &&
          price.limitation.limitations?.minAmount == null &&
          price.limitation.id
        ) {
          await dealerDistributionLimitationDeleteApi(price.limitation.id);
        } else {
          await dealerDistributionLimitationSaveApi({
            productId: id,
            dealerId,
            id: price.limitation.id,
            pricePointId: price.id,
            pricePointType: productPriceInfo.type,
            limitations: price.limitation.limitations,
          });
        }
      }
    }
    if (dataType === 'product') {
      const priceCAD = price.base.distributions
        ? reduce(price.base.distributions, (p, c) => p + (c.amount ?? 0), 0)
        : null;
      if (productPriceInfo.type === 'CustomPricePoint') {
        if (price.base.isUpdated) {
          if (priceCAD == null && price.id) {
            await customPricePointDeleteApi(price.id);
          } else if (priceCAD != null) {
            const customPricePointSaveResponse = await customPricePointSaveApi({
              id: price.id,
              priceCAD,
              productId: id,
              pricingDistributions: price.base.distributions,
              subfees: price.base.subfees,

              termInMonths: price.filters.termInMonths,
              customInfo: price.filters.customInfo,
              customInfoGroup: price.filters.customInfoGroup,
              //productProvinceGroupId,
            });
            price.id = price.id ?? customPricePointSaveResponse.payload;
          }
        }
      } else if (productPriceInfo.type === 'NonWarrantyPricePoint') {
        if (price.base.isUpdated) {
          if (priceCAD == null && price.id) {
            await nonWarrantyPricePointDeleteApi(price.id);
          } else if (priceCAD != null) {
            const customPricePointSaveResponse = await nonWarrantyPricePointSaveApi({
              id: price.id,
              priceCAD,
              productId: id,
              pricingDistributions: price.base.distributions,
              subfees: price.base.subfees,

              termInMonths: price.filters.termInMonths,
              maxVehiclePriceCAD: price.filters.maxVehiclePriceCAD,
              minVehiclePriceCAD: price.filters.minVehiclePriceCAD,
              //productProvinceGroupId,
            });
            price.id = price.id ?? customPricePointSaveResponse.payload;
          }
        }
      } else if (productPriceInfo.type === 'ProductTierPricePoint') {
        if (price.base.isUpdated) {
          if (priceCAD == null && price.id) {
            await productTierPricePointDeleteApi({ id: price.id });
          } else if (priceCAD != null) {
            const customPricePointSaveResponse = await productTierPricePointSaveApi({
              id: price.id,
              priceCAD,
              productId: id,
              pricingDistributions: price.base.distributions,
              subfees: price.base.subfees,

              termInMonths: price.filters.termInMonths,
              tierId: price.filters.tier,
              //productProvinceGroupId,
            });
            price.id = price.id ?? customPricePointSaveResponse.payload;
          }
        }
      } else if (productPriceInfo.type === 'ProductPricingPlanClass') {
        if (price.base.isUpdated) {
          if (priceCAD == null && price.id) {
            await productPricingPlanClassDeleteApi(price.id);
          } else if (priceCAD != null) {
            const limit = productPriceInfo.pricingLimitTemplates?.find((l) => l.id === price.filters.limits);
            const customPricePointSaveResponse = await productPricingPlanClassSaveApi({
              id: price.id,
              priceCAD,
              pricingDistributions: price.base.distributions,
              subfees: price.base.subfees,

              termInMonths: limit.term,
              customInfo: price.filters.customInfo,
              maxKm: limit.maxKm,
              vehicleClassId: price.filters.vehicleClass,
              productPricingPlanId: price.filters.productPricingPlan,
              deductible: price.filters.deductible,
              //productProvinceGroupId,
            });
            price.id = price.id ?? customPricePointSaveResponse.payload;
          }
        }
      }
      await Promise.all([saveDistributor(price), saveDiscounts(price), saveLimits(price)]);
    }

    async function saveEnhancementDistributor(price: AllPricingOptions) {
      let savedId = productPriceInfo?.distributorProductId;
      if (price.distributor?.isUpdated) {
        const response = await distributorProductEnhancementSaveApi({
          id: productPriceInfo?.distributorProductId,
          productEnhancementId: id,
          distributorId,
          pricingDistributionTemplateId: productPriceInfo?.distributorTemplateId,
        });
        if (response.payload) savedId = response.payload;
        if (!price.distributor.distributions && price.distributor.id) {
          await distributorProductEnhancementPricingDistributionDeleteApi(price.distributor.id);
        } else {
          await distributorProductEnhancementPricingDistributionSaveApi({
            id: price.distributor.id,
            pricePointId: price.id,
            pricePointType: productPriceInfo.type,
            distributions: price.distributor.distributions,
            distributorProductEnhancementId: savedId,
          });
        }
      }
    }
    async function saveEnhancementDiscounts(price: AllPricingOptions) {
      if (price.discount?.isUpdated) {
        if (!price.discount.distributions && !price.discount.distributorDistributions && price.discount.id) {
          await dealerEnhancementDistributionDiscountDeleteApi(price.discount.id);
        } else {
          await dealerEnhancementDistributionDiscountSaveApi({
            productEnhancementId: id,
            dealerId,
            distributorId,
            id: price.discount.id,
            pricePointId: price.id,
            pricePointType: productPriceInfo.type,
            distributionDiscount: price.discount.distributions,
            distributorDistributionDiscount: price.discount.distributorDistributions,
          });
        }
      }
    }
    async function saveEnhancementLimits(price: AllPricingOptions) {
      if (price.limitation?.isUpdated) {
        if (
          price.limitation.limitations?.maxAmount == null &&
          price.limitation.limitations?.minAmount == null &&
          price.limitation.id
        ) {
          await dealerEnhancementDistributionLimitationDeleteApi(price.limitation.id);
        } else {
          await dealerEnhancementDistributionLimitationSaveApi({
            productEnhancementId: id,
            dealerId,
            id: price.limitation.id,
            pricePointId: price.id,
            pricePointType: productPriceInfo.type,
            limitations: price.limitation.limitations,
          });
        }
      }
    }
    if (dataType === 'productEnhancement') {
      const priceCAD = price.base.distributions
        ? reduce(price.base.distributions, (p, c) => p + (c.amount ?? 0), 0)
        : null;
      if (productPriceInfo.type === 'ProductEnhancementTierPricePoint') {
        if (price.base.isUpdated) {
          if (priceCAD == null && price.id) {
            await productEnhancementTierPricePointDeleteApi({ id: price.id });
          } else if (priceCAD != null) {
            const customPricePointSaveResponse = await productEnhancementTierPricePointSaveApi({
              id: price.id,
              priceCAD,
              productEnhancementId: id,
              pricingDistributions: price.base.distributions,

              termInMonths: price.filters.termInMonths,
              tierId: price.filters.tier,
              //productProvinceGroupId,
            });
            price.id = price.id ?? customPricePointSaveResponse.payload;
          }
        }

        await Promise.all([
          saveEnhancementDistributor(price),
          saveEnhancementDiscounts(price),
          saveEnhancementLimits(price),
        ]);
      }
    }
  }
  function reload() {
    setReloadCount((old) => old + 1);
  }

  return {
    productPriceInfo,
    reload,
    save,
  };
}
