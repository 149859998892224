import Model from 'models/Model';
import ProductEnhancement from 'models/productManagement/productEnhancement/Model.ProductEnhancement';

export default class ContractEnhancement
  extends Model<Model.IContractEnhancement>({ icon: 'if-login' })
  implements Model.Instance<Model.IContractEnhancement>
{
  priceCAD: number;
  originalPriceCAD: number;
  discountPriceCAD: number;
  distributorPriceCAD: number;
  pricingDistributions?: Utils.PricingDistribution;
  distributorDistributions?: Utils.PricingDistribution;
  productEnhancementId?: number;
  productEnhancement?: ProductEnhancement;

  constructor(
    data: Partial<Model.IContractEnhancement> | Utils.FormData<Model.IContractEnhancement>,
    language?: Locale.Languages
  ) {
    super(
      {
        productEnhancement: ProductEnhancement,
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.productEnhancementId,
      title: this.productEnhancement?.name || this.productEnhancementId.toString(),
    };
  }
}
