import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, productManagementHomeRoute } from 'config/Api.Config';
import useProductEnhancement from './ProductEnhancement.Hooks';
import ProductEnhancementDelete from './sections/ProductEnhancement.Delete';
import ProductEnhancementEdit from './sections/ProductEnhancement.Edit';
import ProductEnhancementList from './sections/ProductEnhancement.List';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';

export const productEnhancementIndexRoute = productManagementHomeRoute + '/productEnhancement/:action?/:id?';
export default function ProductEnhancementIndex() {
  const productEnhancementHook = useProductEnhancement();

  if (!productEnhancementHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={productEnhancementHook}
        render={({ views }) => (
          <>
            {views.edit ? (
              <ProductEnhancementEdit />
            ) : (
              <>
                {views.delete && <ProductEnhancementDelete />}
                <ProductEnhancementList />
              </>
            )}
          </>
        )}
      />
    </>
  );
}
