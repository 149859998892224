import MediaFile from 'models/core/mediaFile/Model.MediaFile';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import Model from 'models/Model';
import Client from '../../claims/client/Model.Client';
import Product from '../../productManagement/product/Model.Product';
import Vehicle from '../../productManagement/vehicle/Model.Vehicle';
import contractFindApi from './find/Api.Contract.Find';
import contractListApi from './list/Api.Contract.List';
import contractListPublicApi from './listPublic/Api.Contract.ListPublic';
import contractSaveApi from './save/Api.Contract.Save';
import ContractProductDocument from '../contractProductDocuments/ContractProductDocument';
import User from 'models/core/user/Model.User';
import ContractDocument from './contractDocument/ContractDocument';
import contractSendConsentApi from './sendConsent/Api.Contract.SendConsent';
import ContractConsent from '../contractConsent/Model.ContractConsent';
import contractExportApi from './export/Api.Contract.Export';
import contractSaveUnrestrictedApi from './saveUnrestricted/Api.Contract.SaveUnrestricted';
import contractUnknownVertaforeStatusListApi from './unknownVertaforeStatusList/Api.Contract.UnknownVertaforeStatusList';
import contractListWithLegacyApi from './legacy/listWIthLegacy/Api.Contract.ListWithLegacy';
import ContractEnhancement from '../contractEnhancement/Model.ContractEnhancement';

export default class Contract
  extends Model<Model.IContract>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IContract> {
  id: number;
  legacyId: number;
  appNumber?: string;
  revisionNumber?: number;
  creator?: string;
  statusCode?: Model.Enum.ContractStatusCode;
  cancellationDate?: string;
  cancellationOdometerReading?: number;
  cancellationReason?: string;
  cancellationReasonDescription?: string;
  documentTemplate?: string;
  effectiveDate: string;
  expiryDate: string;
  createdAtLocal: string;
  createdAtUtc: string;
  modifiedAtUtc: string;
  productId: number;
  product: Product;
  coApplicantFirstName?: string;
  coApplicantLastName?: string;
  dealerId: number;
  dealer?: Dealer;
  clientId: number;
  client: Client;
  vehicleId: number;
  vehicle: Vehicle;
  termInMonths?: number;
  maxKm?: number;
  deductible?: number;
  customInfo?: string;
  customInfoGroup?: string;
  maxVehiclePriceCAD?: number;
  minVehiclePriceCAD?: number;
  tier?: string;
  class?: string;
  warrantyOption?: string;
  pricingDistributions?: Utils.PricingDistribution;
  distributorDistributions?: Utils.PricingDistribution;
  subfees?: Array<Utils.SurchargeInfo>;
  documents?: MediaFile[];
  productDocuments?: ContractProductDocument[];
  createdBy: User;
  createdById: number;

  contractConsentId?: number;
  contractConsent?: ContractConsent;

  paidOnDate?: string;
  isPackage?: boolean;
  isPayed?: boolean;
  isSigned?: boolean;
  isSentForSigning?: boolean;
  documentationAggregateId?: number;
  documentationAggregate?: MediaFile;
  contractDocuments?: ContractDocument[];
  contractEnhancements?: ContractEnhancement[];

  priceCAD: number;
  originalPriceCAD: number;
  discountPriceCAD: number;
  distributorPriceCAD: number;
  surchargesPriceCAD: number;

  provincialSalesTax: number;
  gstHst: number;

  salePriceCAD: number;
  postSale?: boolean;

  //#region Vreaforte
  vertaforeStatus?: Model.Enum.VertaforeDocumentStatuses;
  //#endregion

  readonly salePriceWithTaxCAD: number;
  readonly totalTaxCAD: number;

  readonly paymentAmountCAD: number; //originalPriceCAD - discountAmountCAD + distributorAmountCAD + surchargesAmountCAD
  readonly paymentTaxCAD: number; //calc same as totalTaxCAD but from paymentAmountCAD
  readonly paymentAmountWithTaxCAD: number; //paymentAmountCAD + paymentTaxCAD

  readonly coverageKm: number; // vehicle.posOdometerReading + maxKm

  readonly dealerCost: number;
  readonly dealerCostTaxAmt: number;
  readonly dealerCostTotal: number;
  readonly dealerCost75Percent: number;
  readonly dealerProfit: number;
  readonly dealerProfitTaxAmt: number;

  constructor(data: Partial<Model.IContract> | Utils.FormData<Model.IContract>, language?: Locale.Languages) {
    super(
      {
        dealer: Dealer,
        client: Client,
        vehicle: Vehicle,
        product: Product,
        createdBy: User,
        documents: [MediaFile],
        contractDocuments: [ContractDocument],
        documentationAggregate: MediaFile,
        productDocuments: [ContractProductDocument],
        contractConsent: ContractConsent,
        contractEnhancements: [ContractEnhancement],
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: `${this?.appNumber}/${this?.client?.displayInfo?.title}/${this?.vehicle?.vin}` || this.id.toString(),
      info: this.statusCode,
      subtitle: this.dealer?.displayInfo.title,
    };
  }

  get taxExemptPrices() {
    if (this.client.taxExempt) {
      return {
        salePriceTotalCAD: this.salePriceCAD,
        paymentAmountTotalCAD: this.paymentAmountCAD,
      };
    } else {
      return {
        salePriceTotalCAD: this.salePriceWithTaxCAD,
        paymentAmountTotalCAD: this.paymentAmountWithTaxCAD,
      };
    }
  }
  get calculateTotalPrice(): number {
    if (this.client.taxExempt) {
      return this.salePriceCAD;
    } else {
      return this.salePriceCAD + this.salePriceCAD * (this.gstHst + this.provincialSalesTax);
    }
  }

  static async find(params: { id: number }) {
    const { payload, ...rest } = await contractFindApi(params);
    return {
      ...rest,
      payload: new Contract(payload),
    };
  }

  static async list(body?: Api.Core.Contract.List.IRequest) {
    const { payload, ...rest } = await contractListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Contract(it)),
        count: payload.count,
      },
    };
  }

  static async listPublic(body?: Api.Core.Contract.ListPublic.IRequest) {
    const { payload, ...rest } = await contractListPublicApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Contract(it)),
        count: payload.count,
      },
    };
  }

  static async listWithLegacy(body?: Api.Core.Contract.ListWithLegacy.IRequest) {
    const { payload, ...rest } = await contractListWithLegacyApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data,
        count: payload.count,
      },
    };
  }

  static async export(query: Api.Core.Contract.Export.IRequest) {
    return await contractExportApi(query);
  }

  static async sendConsent(params: Api.Core.Contract.SendConsent.IRequest) {
    return await contractSendConsentApi(params);
  }

  static async getContractsWithUnknownVertaForeStatus() {
    return await contractUnknownVertaforeStatusListApi();
  }

  async save() {
    return await contractSaveApi(this);
  }
  async saveUnrestricted() {
    return await contractSaveUnrestrictedApi(this);
  }
}
