import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Collection from 'assets/components/data/Collection';
import ThemeCollectionTable from 'assets/components/data/themes/Theme.Collection.Table';
import DataMap from 'assets/components/dataMap/DataMap';
import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import Icon from 'assets/components/icon/Icon';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { sortBy } from 'lodash';
import React, { useState } from 'react';

export default function ClaimTypeEditAggregates() {
  const { lang } = getLocales();
  const { form } = useModalForm<Api.Core.ClaimType.Save.IRequest>();
  const [currentPriority, setPriority] = useState('maxNumberOfClaimsPriority');

  return (
    <>
      <Collection class={ThemeCollectionTable}>
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DataMap
            data={['maxNumberOfClaimsPriority', 'maxClaimAmountPriority', 'totalAggregatePriority']}
            render={({ data: priority }) => (
              <Button
                class={ThemeButtonCircle}
                render={lang[priority]}
                active={currentPriority === priority}
                data={priority}
                onClick={setPriority}
              />
            )}
          />
        </Box>
        <DataMap
          data={sortBy(form.data?.claimTypeAggregates || [], (it) => it[currentPriority])}
          render={({ data: aggregate }) => (
            <Box
              key={`${aggregate.id}_${currentPriority}`}
              style={{ display: 'flex' }}
              render={
                <>
                  {aggregate.insurer.name}
                  <NumberInput
                    label={lang.amount}
                    name={currentPriority.replace('Priority', '')}
                    value={aggregate[currentPriority.replace('Priority', '')]}
                    onChange={(d) => {
                      aggregate[currentPriority.replace('Priority', '')] = d[currentPriority.replace('Priority', '')];
                      form.update({ claimTypeAggregates: form.data.claimTypeAggregates });
                    }}
                    style={{
                      formInput: { width: Theme.Size.pixel(300), marginLeft: 'auto' },
                      input: { textAlign: 'right' },
                    }}
                    step={currentPriority === 'maxNumberOfClaimsPriority' ? 1 : 0.01}
                    icon={currentPriority === 'maxNumberOfClaimsPriority' ? null : <Icon class="fas-dollar-sign" />}
                  />
                  &nbsp;
                  <NumberInput
                    name={currentPriority}
                    value={aggregate[currentPriority]}
                    step={1}
                    onChange={(d) => {
                      aggregate[currentPriority] = d[currentPriority];
                      form.update({ claimTypeAggregates: form.data.claimTypeAggregates });
                    }}
                    style={{
                      formInput: { width: Theme.Size.pixel(80), marginTop: 'auto' },
                      input: { textAlign: 'center', padding: '0' },
                    }}
                  />
                </>
              }
            />
          )}
        />
      </Collection>
    </>
  );
}
