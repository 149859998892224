import { inputStyles } from 'assets/components/inputs/Input';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import { getLocales } from 'assets/locales/Locale';
import { defaultDateFormat, defaultDateTimeFormat } from 'assets/utils/data/Date';
import InputType from 'models/enums/InputType';
import { ContractProduct } from '../../Sales.NewContract.Hooks';

type AdditionalFieldProps = {
  product: ContractProduct;
  productEnhancementDocumentId: number;
  additionalField: Model.IProductEnhancementAdditionalField;
  updateProduct(product: ContractProduct): void;
};

export function EnhancementAdditionalField({
  product,
  productEnhancementDocumentId,
  additionalField,
  updateProduct,
}: AdditionalFieldProps) {
  const { lang } = getLocales();
  const formAdditionalField = product.enhancementAdditionalFields?.find(
    (f) => f.productEnhancementDocumentId === productEnhancementDocumentId && f.key === additionalField.key
  ) || {
    productEnhancementDocumentId,
    key: additionalField.key,
    value: null,
  };
  function updateAdditionalField({ [additionalField.key]: value }: any) {
    const enhancementAdditionalFields = product.enhancementAdditionalFields || [];
    const index = enhancementAdditionalFields.findIndex(
      (f) => f.productEnhancementDocumentId === productEnhancementDocumentId && f.key === additionalField.key
    );
    if (index < 0) {
      enhancementAdditionalFields.push({
        ...formAdditionalField,
        value,
      });
    } else {
      enhancementAdditionalFields.splice(index, 1, {
        ...formAdditionalField,
        value,
      });
    }
    updateProduct({
      ...product,
      enhancementAdditionalFields,
    });
  }
  return (
    <>
      {additionalField.type === InputType.text && (
        <TextInput
          name={additionalField.key}
          label={additionalField.label || additionalField.key}
          value={formAdditionalField?.value}
          error={!formAdditionalField?.value && additionalField.isRequired ? lang.mustNotBeEmpty : undefined}
          onChange={updateAdditionalField}
        />
      )}
      {additionalField.type === InputType.longText && (
        <MultilineTextInput
          name={additionalField.key}
          label={additionalField.label || additionalField.key}
          value={formAdditionalField?.value}
          error={!formAdditionalField?.value && additionalField.isRequired ? lang.mustNotBeEmpty : undefined}
          onChange={updateAdditionalField}
        />
      )}
      {additionalField.type === InputType.boolean && (
        <CheckInput
          name={additionalField.key}
          label={additionalField.label || additionalField.key}
          value={formAdditionalField?.value}
          error={!formAdditionalField?.value && additionalField.isRequired ? lang.mustNotBeEmpty : undefined}
          onChange={updateAdditionalField}
          class={inputStyles.check.switch}
          htmlElementProps={{ title: formAdditionalField?.value ? lang.disable : lang.enable }}
        />
      )}
      {(additionalField.type === InputType.date || additionalField.type === InputType.dateTime) && (
        <DateInput
          name={additionalField.key}
          label={additionalField.label || additionalField.key}
          value={formAdditionalField?.value}
          error={!formAdditionalField?.value && additionalField.isRequired ? lang.mustNotBeEmpty : undefined}
          onChange={updateAdditionalField}
          dateTimeFormat={additionalField.type === InputType.dateTime ? defaultDateTimeFormat : defaultDateFormat}
        />
      )}
      {(additionalField.type === InputType.number || additionalField.type === InputType.decimal) && (
        <NumberInput
          name={additionalField.key}
          label={additionalField.label || additionalField.key}
          value={formAdditionalField?.value}
          error={!formAdditionalField?.value && additionalField.isRequired ? lang.mustNotBeEmpty : undefined}
          step={additionalField.type === InputType.number ? 1 : 0.01}
          onChange={updateAdditionalField}
        />
      )}
      {additionalField.type === InputType.year && (
        <NumberInput
          name={additionalField.key}
          label={additionalField.label || additionalField.key}
          value={formAdditionalField?.value}
          error={!formAdditionalField?.value && additionalField.isRequired ? lang.mustNotBeEmpty : undefined}
          step={1}
          min={1000}
          max={9999}
          onChange={updateAdditionalField}
        />
      )}
      {additionalField.type === InputType.month && (
        <NumberInput
          name={additionalField.key}
          label={additionalField.label || additionalField.key}
          value={formAdditionalField?.value}
          error={!formAdditionalField?.value && additionalField.isRequired ? lang.mustNotBeEmpty : undefined}
          step={1}
          min={1}
          max={12}
          onChange={updateAdditionalField}
        />
      )}
      {additionalField.type === InputType.day && (
        <NumberInput
          name={additionalField.key}
          label={additionalField.label || additionalField.key}
          value={formAdditionalField?.value}
          error={!formAdditionalField?.value && additionalField.isRequired ? lang.mustNotBeEmpty : undefined}
          step={1}
          min={1}
          max={31}
          onChange={updateAdditionalField}
        />
      )}
    </>
  );
}
