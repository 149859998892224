import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { LocationSearch } from 'assets/templates/locationSearch/LocationSearch';
import Theme from 'assets/themes/Theme.Common';
import { formatFormPhone } from 'assets/utils/forms/formatFormPhone';
import { isEmail, isValidPhoneNumber } from 'assets/utils/parsersAndValidation/Validators';
import { set } from 'lodash';
import PayeeType from 'models/claims/payeeType/Model.PayeeType';
import { CountryCode } from 'models/enums/CountryCode';
import PaymentType from 'models/enums/PaymentType';
import { usePayeeProvider } from '../Payee.Hooks';

type Props = {
  onSubmit: (data: Api.Core.Payee.Save.IRequest) => void;
  onCancel: () => void;
};
export default function PayeeEdit(props: Props) {
  const { lang } = getLocales();
  const { selected } = usePayeeProvider();
  const payeeTypeApi = useApi({ action: PayeeType.list });

  return (
    <ModalForm<Api.Core.Payee.Save.IRequest>
      subcomponents={ThemeModalFormDefault}
      dependencyArray={[selected]}
      title={lang.payee}
      default={selected?.toJSON() || {}}
      onCancel={props.onCancel}
      onSubmit={props.onSubmit}
      validation={(data, errors) => {
        if (!data.name) set(errors, 'name', lang.mustNotBeEmpty);
        if (!data.payeeTypeId) set(errors, 'payeeTypeId', lang.mustNotBeEmpty);
        if (!data.email) set(errors, 'email', lang.mustNotBeEmpty);
        if (!isEmail(data.email))
          set(errors, 'email', lang.invalidFormatRequires.replace('{format}', 'example@example.com'));
        if (data.phoneNumber && !isValidPhoneNumber(data.phoneNumber))
          set(errors, 'phoneNumber', lang.invalidFormatRequires.replace('{format}', '###-###-####'));
      }}
      render={({ form }) => (
        <FormWrapper>
          <TextInput
            name="name"
            label={lang.name}
            value={form.data.name}
            error={form.errors.name}
            onChange={form.update}
            icon={<Icon class="if-quill-pen" />}
          />
          <SelectInput
            name="payeeTypeId"
            label={lang.type}
            data={payeeTypeApi?.payload?.data
              ?.filter((it) => it.id !== PayeeType.DEALER_ID && it.id !== PayeeType.CLIENT_ID)
              .map((it) => it.displayInfo)}
            error={form.errors.payeeTypeId}
            value={form.data?.payeeTypeId}
            onChange={form.update}
          />
          <InputWrapper>
            <TextInput
              name="phoneNumber"
              label={lang.phoneNumber}
              value={form.data.phoneNumber}
              error={form.errors.phoneNumber}
              onChange={({ phoneNumber }) =>
                form.update({ phoneNumber: formatFormPhone(form.data.phoneNumber, phoneNumber) })
              }
              icon={<Icon class="if-quill-pen" />}
            />
            <TextInput
              name="email"
              label={lang.email}
              value={form.data.email}
              error={form.errors.email}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
          </InputWrapper>
          <SelectInput
            name="countryCode"
            label={lang.countryCode}
            error={form.errors.countryCode}
            data={Object.values(CountryCode).map((it) => ({
              id: it,
              title: it,
            }))}
            value={form.data?.countryCode}
            onChange={({ countryCode }) => {
              form.update({
                streetAddress: null,
                provinceCode: null,
                city: null,
                postalCode: null,
                countryCode,
              });
            }}
          />
          {form.data?.countryCode === CountryCode.CAN && (
            <LocationSearch
              onSelect={(location) => {
                form.update({
                  streetAddress: location?.Line1 || null,
                  provinceCode: location?.ProvinceCode || null,
                  city: location?.City || null,
                  countryCode: location?.CountryIso3 || null,
                  postalCode: location?.PostalCode || null,
                });
              }}
            />
          )}
          {form.data?.countryCode && (
            <InputWrapper>
              <TextInput
                name="streetAddress"
                label={lang.streetAddress}
                value={form.data.streetAddress}
                error={form.errors.streetAddress}
                onChange={form.update}
                icon={<Icon class="if-map-pin" />}
                readOnly={form.data?.countryCode === CountryCode.CAN}
              />
              <TextInput
                name="postalCode"
                label={form.data?.countryCode === CountryCode.CAN ? lang.postalCode : lang.zipCode}
                value={form.data.postalCode}
                error={form.errors.postalCode}
                onChange={form.update}
                icon={<Icon class="if-map-pin" />}
                readOnly={form.data?.countryCode === CountryCode.CAN}
              />
              <TextInput
                name="city"
                label={lang.city}
                value={form.data.city}
                error={form.errors.city}
                onChange={form.update}
                icon={<Icon class="if-map-pin" />}
                readOnly={form.data?.countryCode === CountryCode.CAN}
              />
              <TextInput
                name="provinceCode"
                label={form.data?.countryCode === CountryCode.CAN ? lang.provinceCode : lang.state}
                value={form.data.provinceCode}
                error={form.errors.provinceCode}
                onChange={form.update}
                icon={<Icon class="if-map-pin" />}
                style={{ width: Theme.Size.XXL }}
                readOnly={form.data?.countryCode === CountryCode.CAN}
              />
              <TextInput
                name="countryCode"
                label={lang.countryCode}
                value={form.data.countryCode}
                error={form.errors.countryCode}
                onChange={form.update}
                icon={<Icon class="if-map-pin" />}
                style={{ width: Theme.Size.XXL }}
                readOnly
              />
            </InputWrapper>
          )}
          <NumberInput
            name="labourRate"
            label={lang.labourRate}
            value={form.data.labourRate}
            error={form.errors.labourRate}
            onChange={form.update}
            step={0.01}
            style={{ input: { textAlign: 'right' } }}
            icon={<Icon class="icon fas-dollar-sign" />}
          />
          <SelectInput
            name="paymentMethodCode"
            label={lang.paymentMethod}
            error={form.errors.paymentMethodCode}
            data={Object.values(PaymentType).map((it) => ({
              id: it,
              title: it,
            }))}
            value={form.data?.paymentMethodCode}
            onChange={form.update}
          />
        </FormWrapper>
      )}
    />
  );
}
