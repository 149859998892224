import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { HttpMethod, getDefaultHeaders } from 'config/Api.Config';
import Model from 'models/Model';

export async function dealerEnhancementDistributionDiscountSaveApi(
  body?: Api.Core.DealerEnhancementDistributionDiscount.Save.IRequest
) {
  const serverResponse = await executeServerApi<
    Api.Core.DealerEnhancementDistributionDiscount.Save.IRequest,
    Api.Core.DealerEnhancementDistributionDiscount.Save.IResponse,
    Api.Core.DealerEnhancementDistributionDiscount.Save.IHeaders
  >({
    method: body?.id ? HttpMethod.put : HttpMethod.post,
    url: '/api/DealerManagement/DealerEnhancementDistributionDiscount',
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export async function dealerEnhancementDistributionDiscountListApi(
  body?: Api.Core.DealerEnhancementDistributionDiscount.List.IRequest
) {
  return await executeServerApi<
    Api.Core.DealerEnhancementDistributionDiscount.List.IRequest,
    Api.Core.DealerEnhancementDistributionDiscount.List.IResponse,
    Api.Core.DealerEnhancementDistributionDiscount.List.IHeaders
  >({
    method: HttpMethod.get,
    url: createPathFromRoute('/api/DealerManagement/DealerEnhancementDistributionDiscount'),
    body,
    headers: getDefaultHeaders(),
  });
}
export async function dealerEnhancementDistributionDiscountDeleteApi(id: number) {
  const serverResponse = await executeServerApi<
    Api.Core.DealerEnhancementDistributionDiscount.Delete.IRequest,
    Api.Core.DealerEnhancementDistributionDiscount.Delete.IResponse,
    Api.Core.DealerEnhancementDistributionDiscount.Delete.IHeaders
  >({
    method: HttpMethod.delete,
    url: createPathFromRoute('/api/DealerManagement/DealerEnhancementDistributionDiscount/:id', { id }),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export default class DealerEnhancementDistributionDiscount
  extends Model<Model.IDealerEnhancementDistributionDiscount>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IDealerEnhancementDistributionDiscount>
{
  id: number;
  productEnhancementId: number;
  dealerId: number;
  distributionDiscount: Utils.DiscountDistribution;
  distributorDistributionDiscount: Utils.DiscountDistribution;

  pricePointId: number;
  pricePointType: Utils.PricePointType;

  constructor(
    data:
      | Partial<Model.IDealerEnhancementDistributionDiscount>
      | Utils.FormData<Model.IDealerEnhancementDistributionDiscount>,
    language?: Locale.Languages
  ) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.id.toString(),
    };
  }

  static async list(body?: Api.Core.DealerEnhancementDistributionDiscount.List.IRequest) {
    const { payload, ...rest } = await dealerEnhancementDistributionDiscountListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new DealerEnhancementDistributionDiscount(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await dealerEnhancementDistributionDiscountSaveApi(this);
  }
  async delete() {
    return await dealerEnhancementDistributionDiscountDeleteApi(this.id);
  }
}
