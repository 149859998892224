import { date, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { combineStrings } from 'assets/utils/data/String';
import Model from 'models/Model';
import Lender from 'models/sales/lender/Model.Lender';
import VehicleModel from '../vehicleModel/Model.VehicleModel';
import vehicleDetailsApi from './details/Api.Vehicle.Details';

export default class Vehicle
  extends Model<Model.IVehicle>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IVehicle>
{
  id: number;
  vin?: string;
  year: number;
  makeCode?: string;
  model?: string;
  bodyType?: string;
  bodyColor?: string;
  vehicleConditionCode?: string;
  posOdometerReading?: number;
  stockNumber?: string;

  engineSize: number;
  purchaseDate: string;
  deliveryDate: string;
  classDate: string;
  purchasePrice: number;
  inServiceDate: string;
  mfgPowertrainWarrantyMonths?: string;
  mfgPowertrainWarrantyKm?: string;
  mfgWholeVehicleWarrantyMonths?: string;
  mfgWholeVehicleWarrantyKm?: string;

  trimLevel?: string;
  engineType?: Model.Enum.EngineType;
  engineInfo?: string;
  driveLine?: string;
  purchaseState?: string;
  purchaseType?: string;
  financedAmount?: number;
  interestRate?: number;
  lenderId?: number;
  lender?: Lender;

  constructor(data: Partial<Model.IVehicle> | Utils.FormData<Model.IVehicle>, language?: Locale.Languages) {
    super({ lender: Lender, vehicleModel: VehicleModel }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title:
        combineStrings(
          ' ',
          this.model,
          this.makeCode,
          this.year && date(this.year, defaultServerDateTimeFormat).format('YYYY')
        ) || this.id.toString(),
      info: this.vin,
    };
  }

  static async details(body: Api.Core.Vehicle.Details.IRequest) {
    const { payload, ...rest } = await vehicleDetailsApi(body);

    return {
      ...rest,
      payload: new Vehicle({
        ...payload,
        vin: body.vin,
      }),
    };
  }
}
