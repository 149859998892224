import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import { inputStyles } from 'assets/components/inputs/Input';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import productPricingPlanSaveApi from 'models/productManagement/productPricingPlan/save/Api.ProductPricingPlan.Save';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import WarrantyOptions from 'models/enums/WarrantyOptions';
import useApi from 'assets/hooks/api/useApi';
import productPricingPlanListApi from 'models/productManagement/productPricingPlan/list/Api.ProductPricingPlan.List';
import productListApi from 'models/productManagement/product/list/Api.Product.List';
import productFindApi from 'models/productManagement/product/find/Api.Product.Find';
import { useMemo } from 'react';
type PriceAdjustmentEditPricingPlanProps = {
  productId: number;
  pricingPlanId: number;
  productProvinceGroupId?: number;
  onCancel(): void;
};
export default function PriceAdjustmentEditPricingPlan(props: PriceAdjustmentEditPricingPlanProps) {
  const { lang } = getLocales();

  const pricingPlans = useApi({
    action: productPricingPlanListApi,
    body: { productId: [props.productId] },
  });

  const product = useApi(
    {
      action: productFindApi,
      body: { id: props.productId },
      wait: !!props.pricingPlanId,
    },
    [props.pricingPlanId]
  );

  const products = useApi(
    {
      action: productListApi,
      body: { templateId: product.payload?.pricingDistributionTemplateId, productType: product.payload?.productType },
      wait: !(product.payload?.pricingDistributionTemplateId && product.payload?.productType),
    },
    [product.payload?.pricingDistributionTemplateId, product.payload?.productType]
  );

  const similarPricingPlans = useApi(
    {
      action: productPricingPlanListApi,
      body: {
        productId: products.payload?.data?.map((it) => it.id),
      },
      wait: !products.payload?.data,
    },
    [products.payload?.data]
  );

  const plan = pricingPlans?.payload?.data?.find((p) => p.id === props.pricingPlanId);
  const finalPricingPlans = useMemo(() => {
    return similarPricingPlans.payload?.data
      ?.filter((pp) => !!pp.pricingPlanClasses?.length)
      ?.map((pp) => ({
        ...pp,
        product: products.payload?.data?.find((p) => p.id === pp.productId),
      }));
  }, [similarPricingPlans.payload?.data, products.payload?.data]);

  return (
    <>
      <ModalForm<Api.Core.ProductPricingPlan.Save.IRequest & { copyPricePlanId?: number }>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[plan]}
        title={lang.product}
        default={plan ?? { productId: props.productId, productProvinceGroupId: props.productProvinceGroupId }}
        onCancel={props.onCancel}
        onSubmit={async ({ copyPricePlanId, ...data }) => {
          const saveResponse = await productPricingPlanSaveApi(data);
          if (saveResponse?.payload) {
            if (copyPricePlanId) {
              //await productPricingPlanCopyApi({ fromPlanId: copyPricePlanId, toPlanId: saveResponse?.payload });
            }
            location.reload();
          }
        }}
        validation={(data, errors) => {
          if (!data.name) errors.name = lang.mustNotBeEmpty;
        }}
        render={({ form }) => (
          <>
            <FormWrapper>
              <TextInput
                name="name"
                label={lang.name}
                value={form.data.name}
                error={form.errors.name}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <MultilineTextInput
                name="primaryDescription"
                label={lang.description}
                value={form.data.primaryDescription}
                error={form.errors.primaryDescription}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <MultilineTextInput
                name="secondaryDescription"
                label={lang.secondaryDescription}
                value={form.data.secondaryDescription}
                error={form.errors.secondaryDescription}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <CheckInput
                class={inputStyles.check.switch}
                name="isForNewVehicle"
                label={lang.isForNewVehicle}
                value={form.data.isForNewVehicle}
                error={form.errors.isForNewVehicle}
                onChange={form.update}
              />
              <InputWrapper>
                <NumberInput
                  name="minOdometerKm"
                  label={lang.minOdometer}
                  value={form.data.minOdometerKm}
                  error={form.errors.minOdometerKm}
                  onChange={form.update}
                />
                <NumberInput
                  name="maxOdometerKm"
                  label={lang.maxOdometer}
                  value={form.data.maxOdometerKm}
                  error={form.errors.maxOdometerKm}
                  onChange={form.update}
                />
              </InputWrapper>
              <InputWrapper>
                <NumberInput
                  name="minPriceCAD"
                  label={lang.minPrice}
                  value={form.data.minPriceCAD}
                  error={form.errors.minPriceCAD}
                  onChange={form.update}
                />
                <NumberInput
                  name="maxPriceCAD"
                  label={lang.maxPrice}
                  value={form.data.maxPriceCAD}
                  error={form.errors.maxPriceCAD}
                  onChange={form.update}
                />
              </InputWrapper>
              <SelectInput
                name="warrantyOption"
                label={lang.warrantyOptions}
                error={form.errors.warrantyOption}
                data={Object.values(WarrantyOptions).map((it) => ({
                  id: it,
                  title: it,
                }))}
                value={form.data.warrantyOption}
                onChange={form.update}
              />
              {!!finalPricingPlans?.length && !props.pricingPlanId && (
                <SelectInput
                  name="copyPricePlanId"
                  label={`${lang.copy} ${lang.pricingPlan.toLowerCase()}`}
                  data={finalPricingPlans?.map((it) => ({
                    id: it.id,
                    title: it.name,
                    subtitle: it.product?.name,
                  }))}
                  value={form.data.copyPricePlanId}
                  onChange={form.update}
                />
              )}
            </FormWrapper>
          </>
        )}
      />
    </>
  );
}
