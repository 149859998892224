import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Contract.ImportLegacyContract.IRequest;
type Response = Api.Core.Contract.ImportLegacyContract.IResponse;
type Headers = Api.Core.Contract.ImportLegacyContract.IHeaders;
const url = '/api/Sales/Contract/ImportLegacyContract';
export default async function importLegacyContractApi(params: Request): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.post,
    url: createPathFromRoute(url),
    body: params,
    headers: getDefaultHeaders(),
  });
}
