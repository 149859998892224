import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import { getLocales } from 'assets/locales/Locale';
import ContractCancellationReasonForm from './cancellation/Contract.CancellationReason';
import { useSalesContractProvider } from '../Sales.Contract.Hooks';
import CancellationReason from 'models/enums/CancellationReason';
import contractSetStatusApi from 'models/sales/contract/setStatus/Api.Contract.SetStatus';
import ContractStatusCode from 'models/enums/ContractStatusCode';
import { HttpStatus } from 'config/Api.Config';

export type CancellationForm = Api.Core.Contract.SetStatus.IRequest;

export default function ContractCancellation() {
  const { lang } = getLocales();
  const { selected, pageRouter, listApi } = useSalesContractProvider();

  return (
    <>
      <ModalForm<CancellationForm>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[selected]}
        title={lang.refundProcess}
        onCancel={pageRouter.updateParams}
        onSubmit={async (data) => {
          const respons = await contractSetStatusApi(parseInt(pageRouter.params.id), data);

          if (respons.status === HttpStatus.ok) {
            pageRouter.updateParams();
            listApi.execute((b) => b);
          }
        }}
        default={{ newStatus: ContractStatusCode.CANCELED_REFUND_PR_REFUND }}
        validation={(data, errors) => {
          errors.contractCancelationRequest = errors.contractCancelationRequest || {};

          if (!data.contractCancelationRequest?.cancellationDate) {
            errors.contractCancelationRequest.cancellationDate = lang.mustNotBeEmpty;
          }

          if (!data.contractCancelationRequest?.cancellationOdometerReading) {
            errors.contractCancelationRequest.cancellationOdometerReading = lang.mustNotBeEmpty;
          }

          if (!data.contractCancelationRequest?.cancellationReason) {
            errors.contractCancelationRequest.cancellationReason = lang.mustNotBeEmpty;
          }

          if (
            data.contractCancelationRequest?.cancellationReason === CancellationReason.OTHER &&
            !data.contractCancelationRequest?.cancellationReasonDescription
          ) {
            errors.contractCancelationRequest.cancellationReasonDescription = lang.mustNotBeEmpty;
          }
        }}
        tabs={[{ id: 'cancellationReason', text: lang.details }]}
        render={({ activeTab }) => (activeTab?.id === 'cancellationReason' ? <ContractCancellationReasonForm /> : null)}
      />
    </>
  );
}
