import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Dealer.UploadProductEnhancementDocument.IRequest;
type Response = Api.Core.Dealer.UploadProductEnhancementDocument.IResponse;
type Headers = Api.Core.Dealer.UploadProductEnhancementDocument.IHeaders;
const apiDealerUploadProductEnhancementDocumentPath =
  '/api/DealerManagement/Dealer/:id/UploadProductEnhancementDealerDocument/:productEnhancementId/:productEnhancementDocumentId';
export default async function dealerUploadProductEnhancementDocumentApi(
  params: { id: number; productEnhancementId: number; productEnhancementDocumentId: number },
  request: Request
): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    body: request,
    url: createPathFromRoute(apiDealerUploadProductEnhancementDocumentPath, params),
    method: HttpMethod.post,
    headers: getDefaultHeaders(),
  });
}
