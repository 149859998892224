import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Response = Api.Core.Dealer.DeleteProductEnhancementDocument.IResponse;
type Headers = Api.Core.Dealer.DeleteProductEnhancementDocument.IHeaders;
const apiDealerDeleteProductEnhancementDocumentPath =
  '/api/DealerManagement/Dealer/:id/DeleteProductEnhancementDealerDocument/:productEnhancementDealerDocumnetId';
export default async function dealerDeleteProductEnhancementDocumentApi(params: {
  id: number;
  productEnhancementDealerDocumentId: number;
}): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    url: createPathFromRoute(apiDealerDeleteProductEnhancementDocumentPath, {
      id: params.id,
      productEnhancementDealerDocumnetId: params.productEnhancementDealerDocumentId,
    }),
    method: HttpMethod.delete,
    headers: getDefaultHeaders(),
  });
}
