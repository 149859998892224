import { addLog } from 'assets/components/feedback/Feedback';
import useForm from 'assets/components/form/hooks/Form';
import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import ProviderBuilder from 'assets/providers/ProviderBuilder';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { date, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { scrollToElement } from 'assets/utils/dom/Scroll';
import { HttpStatus, OrderType } from 'config/Api.Config';
import { AuthPermission } from 'config/Auth.Config';
import { isEmpty, set } from 'lodash';
import AuditLog from 'models/audit/auditLog/Model.AuditLog';
import Claim from 'models/claims/claim/Model.Claim';
import claimSaveApi from 'models/claims/claim/save/Api.Claim.Save';
import claimSetStatusApi from 'models/claims/claim/setStatus/Api.Claim.SetStatus';
import ClaimComment from 'models/claims/claimComment/ClaimComment';
import claimCommentDeleteApi from 'models/claims/claimComment/delete/Api.ClaimComment.Delete';
import claimCommentLikeApi from 'models/claims/claimComment/like/Api.ClaimComment.Like';
import claimCommentSaveApi from 'models/claims/claimComment/save/Api.ClaimComment.Save';
import claimEmailSendEmailApi from 'models/claims/claimEmail/sendEmail/Api.ClaimEmail.SendEmail';
import claimJobListApi from 'models/claims/claimJob/list/Api.ClaimJob.List';
import claimJobSaveApi from 'models/claims/claimJob/save/Api.ClaimJob.Save';
import ClaimType from 'models/claims/claimType/Model.ClaimType';
import EmailTemplate from 'models/core/claimEmailTemplate/Model.ClaimEmailTemplate';
import ClaimCommentType from 'models/enums/ClaimCommentType';
import ClaimStatusCode from 'models/enums/ClaimStatusCode';
import PaymentStatus from 'models/enums/PaymentStatus';
import Product from 'models/productManagement/product/Model.Product';
import Contract from 'models/sales/contract/Model.Contract';
import { useEffect, useMemo, useState } from 'react';
import { claimEditIndexRoute } from './ClaimEdit.Index';

export interface ClaimParams {
  claimId: string;
}
export interface ClaimQuery {
  list?: Api.Core.Claim.List.IRequest;
  newClaimId?: number;
  kickedBy?: string;
}
export type ClaimTab = 'info' | 'jobs' | 'documents' | 'invoices' | 'comments' | 'logs' | 'emails';
export type ClaimEditActions = 'editClientInfo' | 'editContactInfo';
export type EmailAction = 'sendEmail' | 'changeStatusSendEmail';

export function useClaimEditPageRouter() {
  return usePageRouter<ClaimParams, ClaimQuery>({ route: claimEditIndexRoute });
}
export function useClaimEditorCore() {
  const pageRouter = useClaimEditPageRouter();
  const { lang } = getLocales();
  const { userId, currentPermissions: currentUserPermissions } = useAuthStore();
  const [selectedClaim, selectClaim] = useState<Model.IClaim>(undefined);
  const [emailAction, setEmailAction] = useState<EmailAction>();
  const [claimEditAction, setClaimEditAction] = useState<ClaimEditActions>();

  const { permissions } = useAuthStore();
  const isEditable = permissions?.EDIT_CLAIM;
  const isClaimAdmin = permissions?.CLAIM_ADMIN;
  const isDeletable = permissions?.DELETE_CLAIM;
  const canEditClientInfo = permissions?.EDIT_CLAIMCUSTOMERINFO;
  const hasAnyPermission = isClaimAdmin || isEditable || isDeletable || canEditClientInfo;
  const claimId = parseInt(pageRouter.params.claimId);

  function navigateToTab(tab: ClaimTab) {
    setTimeout(() => scrollToElement({ querySelector: `#${tab}`, options: { block: 'start' } }), 0);
  }

  const findClaimApi = useApi(
    {
      action: Claim.find,
      body: { id: claimId },
      default: null,
      wait: !pageRouter.params.claimId,
    },
    [pageRouter.params.claimId]
  );
  const reloadClaim = () => findClaimApi.execute((b) => b);

  const findProductApi = useApi(
    {
      action: Product.find,
      wait: !findClaimApi.payload?.contract?.productId,
      body: { id: findClaimApi.payload?.contract?.productId },
    },
    [findClaimApi.payload?.contract?.productId]
  );

  const claimTypesApi = useApi({ action: ClaimType.list });
  const claimTypes = claimTypesApi.payload?.data;

  const findContractApi = useApi(
    {
      action: Contract.find,
      wait: !findClaimApi.payload?.contract?.id,
      body: { id: findClaimApi.payload?.contract?.id },
    },
    [findClaimApi.payload?.contract?.id]
  );

  const listCommentsApi = useApi(
    {
      action: ClaimComment.list,
      body: !claimId ? null : { claimId },
      wait: !claimId || !permissions?.EXTERNAL_COMMENT || !permissions?.INTERNAL_COMMENT,
      keepResponse: true,
    },
    [claimId, permissions?.EXTERNAL_COMMENT, permissions?.INTERNAL_COMMENT]
  );

  const listClaimsApi = useApi(
    {
      action: Claim.list,
      body: !findClaimApi.payload?.contract?.id ? null : { contractId: [findClaimApi.payload?.contract?.id] },
      wait: !findClaimApi.payload?.contract?.id,
    },
    [findClaimApi.payload?.contract?.id]
  );

  const listLogsApi = useApi(
    {
      action: AuditLog.list,
      body: {
        orderBy: 'changedAtUtc',
        orderType: OrderType.asc,
        entityId: claimId,
        permissionKey: [AuthPermission.editClaim, AuthPermission.createClaim, AuthPermission.deleteClaim],
      },
      wait: !claimId || !permissions?.VIEW_AUDITLOGS,
    },
    [claimId, permissions?.VIEW_AUDITLOGS]
  );
  const logs = listLogsApi.payload?.data;

  const emailTemplatesApi = useApi({ action: EmailTemplate.list });
  const emailTemplates = emailTemplatesApi.payload?.data;

  const statusInfoApi = useApi({ action: Claim.getStatusFlowAndRequirements });
  const statusInfo = statusInfoApi.payload;

  async function sendEmail(emailTemplate: Model.IClaimEmailTemplate) {
    const res = await claimEmailSendEmailApi({
      params: { claimId: parseInt(pageRouter.params?.claimId) },
      body: emailTemplate,
    });

    if (res.status === HttpStatus.ok) {
      addLog({ success: 'Email successfully sent to all email recipients.' });
      setEmailAction(null);
      reloadClaim();
    } else {
      addLog({ error: res.message });
    }
  }

  function reload() {
    findClaimApi.execute((body) => body);
    listCommentsApi.execute((body) => body);
    listLogsApi.execute((body) => body);
  }

  const claim = findClaimApi.payload;
  const contract = findContractApi.payload ?? claim?.contract;
  const product = findProductApi.payload ?? contract?.product;
  const internalComments = listCommentsApi.payload?.data?.filter(
    (it) => it.claimCommentType === ClaimCommentType.internal
  );
  const externalComments = listCommentsApi.payload?.data?.filter(
    (it) => it.claimCommentType === ClaimCommentType.external
  );

  const claimTypeWithReport = useMemo(
    () => claimTypes?.find((it) => it.product?.id === (contract?.product?.id || contract?.productId)),
    [claimTypes, contract]
  );

  const isClaimOwner = claim?.ownerId && claim.ownerId === userId;

  //#region FORM
  const form = useForm<Utils.FormData<Model.IClaim> & { emailTemplate?: Model.IClaimEmailTemplate }>(
    {
      default: claim || {},
      onSubmit: async ({ emailTemplate, statusCode: status, ownerId, ...formClaim }) => {
        const finalClaim = {
          ...formClaim,
          ownerId: claim?.ownerId,
          statusCode: claim?.statusCode || ClaimStatusCode.OPEN_ACTIVE_IN_PROGRESS,
        };
        const claimSaveResponse = await claimSaveApi(finalClaim);
        const claimId = finalClaim?.id || claimSaveResponse.payload;
        const hasStatusChanged = status && status !== finalClaim?.statusCode;
        const hasOwnerChanged = ownerId && ownerId !== finalClaim?.ownerId;
        if (
          hasStatusChanged &&
          (status === ClaimStatusCode.PAID_CLOSED_PAID || status === ClaimStatusCode.PAID_APPEAL_APPROVED)
        ) {
          const {
            payload: { data: claimJobs },
          } = await claimJobListApi({ claimId });

          if (!isEmpty(claimJobs)) {
            for (const claimJob of claimJobs) {
              const updatedItems = [];
              for (const claimJobItem of claimJob.claimJobItems ?? []) {
                if (claimJobItem.statusCode === PaymentStatus.unpaid) {
                  updatedItems.push({ ...claimJobItem, statusCode: PaymentStatus.paid });
                } else {
                  updatedItems.push({ ...claimJobItem });
                }
              }
              await claimJobSaveApi({ ...claimJob, claimJobItems: updatedItems });
            }
          }
        }
        if (hasStatusChanged && claimId && ownerId) {
          const res = await claimSetStatusApi({ claimId, status, ownerId, emailTemplate });
          if (res.status === HttpStatus.ok) addLog({ success: lang.claimStatusSuccesfullyChanged });
          else addLog({ error: res.message });
        } else if (hasOwnerChanged && claimId) await Claim.changeOwnership({ claimId, ownerId });

        if (claimId?.toString() === pageRouter.params.claimId) {
          reload();
          selectClaim(undefined);
        } else {
          pageRouter.updateParams({
            claimId: claimId?.toString(),
          });
          navigateToTab('info');
        }
      },
      validation: (data, errors) => {
        if (data.emailTemplate && !data.emailTemplate.name)
          set(errors, 'emailTemplate.name', `${lang.mustNotBeEmpty}: ${lang.name}`);
        if (data.emailTemplate && !data.emailTemplate.senderEmail)
          set(errors, 'emailTemplate.senderEmail', `${lang.mustNotBeEmpty}: ${lang.senderEmail}`);
        if (data.emailTemplate && !data.emailTemplate.senderName)
          set(errors, 'emailTemplate.senderName', `${lang.mustNotBeEmpty}: ${lang.senderName}`);
        if (data.emailTemplate && !data.emailTemplate.htmlBody)
          set(errors, 'emailTemplate.htmlBody', `${lang.mustNotBeEmpty}: ${lang.htmlBody}`);
      },
    },
    [claim]
  );
  //#endregion

  const nextStatus = form.data.statusCode;
  const currentStatus = claim?.statusCode || ClaimStatusCode.OPEN_ACTIVE_IN_PROGRESS;
  const isStatusChanged = nextStatus && nextStatus !== currentStatus;
  async function changeStatus({
    nextStatus,
    ownerId,
    owner,
  }: {
    nextStatus: Model.Enum.ClaimStatusCode;
    ownerId?: number;
    owner?: Model.IUser;
  }) {
    const emailTemplate = emailTemplates?.find((it) => it.claimStatus === nextStatus);
    form.update({
      emailTemplate: emailTemplate?.toJSON(),
      statusCode: nextStatus,
      ownerId,
      owner,
    });
  }

  //#region TABS
  const tabs: Array<{ value: ClaimTab; label: string; count?: number }> = [
    { value: 'info', label: lang.info },
    { value: 'jobs', label: lang.jobs, count: claim?.claimJobs?.length },
    { value: 'comments', label: lang.comments, count: externalComments?.length },
    { value: 'documents', label: lang.documents, count: claim?.claimDocuments?.length },
    { value: 'invoices', label: lang.invoices, count: claim?.claimInvoices?.length },
    { value: 'emails', label: lang.emails, count: claim?.claimEmails?.length },
    { value: 'logs', label: lang.logs, count: logs?.length },
  ];

  function hasTabPermission(tab: string) {
    switch (tab) {
      case 'comments':
        return permissions.EXTERNAL_COMMENT;
      default:
        return true;
    }
  }
  const tabsWithPermissions = tabs.filter((tab) => hasTabPermission(tab.value));
  //#endregion

  const isClaimCanceled = claim?.statusCode === ClaimStatusCode.CANCELED_VOID;
  const isClaimJobItemEditable =
    claim?.statusCode === ClaimStatusCode.PAID_CLOSED_PAID ||
    claim?.statusCode === ClaimStatusCode.PAID_APPEAL_APPROVED ||
    claim?.statusCode === ClaimStatusCode.DENIED_DECLINED ||
    claim?.statusCode === ClaimStatusCode.DENIED_DECLINED_APPEAL ||
    isClaimCanceled;

  const isClaimInvoiceEditableByStatus =
    claim?.statusCode === ClaimStatusCode.OPEN_APPROVED_TO_PAY || isClaimJobItemEditable;

  const isClaimJobEditableByStatus =
    claim?.statusCode === ClaimStatusCode.OPEN_APPROVED || isClaimInvoiceEditableByStatus;

  return {
    form,
    hasReports: !!claimTypeWithReport?.claimTypeReportSettings?.length,
    isLoading:
      findClaimApi.isExecuting ||
      findContractApi.isExecuting ||
      findProductApi.isExecuting ||
      listCommentsApi.isExecuting ||
      listLogsApi.isExecuting,
    isLoadingComments: listCommentsApi.isExecuting,
    pageRouter,
    hasAnyPermission,
    isDeletable,
    isEditable,
    isClaimAdmin,
    isClaimOwner,
    isClaimCanceled,
    claim,
    product,
    contract,
    internalComments,
    externalComments,
    logs,
    emailTemplates,
    statusInfo,
    nextStatus,
    currentStatus,
    isStatusChanged,
    tabs: tabsWithPermissions,
    claimHistory: listClaimsApi.payload?.data,
    changeStatus,
    reload,
    reloadClaim,
    reloadComments: () => listCommentsApi.execute((b) => b),
    navigateToTab,
    selectClaim,
    selectedClaim,
    claimEditAction,
    setClaimEditAction,
    canEditClientInfo: isClaimAdmin || (isClaimOwner && canEditClientInfo),
    permissions,
    emailAction,
    setEmailAction,
    sendEmail,
    currentUserPermissions,
    isClaimInvoiceEditableByStatus,
    isClaimJobEditableByStatus,
    isClaimJobItemEditable,
  };
}

const { useProvider: useClaimEdit, Provider: ClaimEditProvider } = ProviderBuilder(useClaimEditorCore);

export { ClaimEditProvider, useClaimEdit };

type ClaimCommentsProps = {
  commentType: ClaimCommentType;
};
export function useClaimComments(props: ClaimCommentsProps) {
  const { lang } = getLocales();
  const { claim, reloadComments, externalComments, isLoadingComments, isClaimAdmin, isClaimOwner, isEditable } =
    useClaimEdit();
  const auth = useAuthStore();
  const [action, setAction] = useState<'edit' | 'delete'>();
  const form = useForm<Utils.FormData<Model.IClaimComment>>({
    default: {
      claimId: claim?.id,
      userId: auth.userId,
      claimCommentType: props.commentType,
      comment: '',
    },
    validation: (data, errors) => {
      if (!data.comment) errors.comment = lang.required;
    },
    onSubmit: async (data) => {
      const response = await claimCommentSaveApi({
        ...data,
        modifiedAtUtc: date().tz('UTC').format(defaultServerDateTimeFormat),
      });
      if (!response) addLog({ error: lang.unknownError });
      else {
        reloadComments();
      }
    },
  });
  useEffect(() => {
    if (claim?.id) {
      form.update({ claimId: claim?.id });
    }
  }, [claim?.id]);
  useEffect(() => {
    if (form.isSubmitted) {
      setAction(null);
      form.reload();
    }
  }, [form.isSubmitted]);

  const deleteApi = useApi({
    action: claimCommentDeleteApi,
    callback: () => {
      setAction(null);
      reloadComments();
    },
    wait: true,
  });
  const likeApi = useApi({
    action: claimCommentLikeApi,
    callback: () => reloadComments(),
    wait: true,
  });

  return {
    form,
    deleteApi,
    likeApi,
    comments: props.commentType === ClaimCommentType.external && externalComments,
    isLoadingComments,
    action,
    setAction,
    canEditComments: isClaimAdmin || (isClaimOwner && isEditable),
  };
}
