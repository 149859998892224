import { addLog } from 'assets/components/feedback/Feedback';
import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { combineStrings } from 'assets/utils/data/String';
import { HttpStatus } from 'config/Api.Config';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import dealerSaveApi from 'models/dealerManagement/dealer/save/Api.Dealer.Save';
import dealerGroupDeleteProductDocumentApi from 'models/dealerManagement/dealerGroup/deleteProductDocument/Api.DealerGroup.DeleteProductDocument';
import dealerGroupDeleteUiFileApi from 'models/dealerManagement/dealerGroup/deleteUiFile/Api.DealerGroup.DeleteUiFile';
import dealerGroupSaveApi from 'models/dealerManagement/dealerGroup/save/Api.DealerGroup.Save';
import dealerGroupUploadProductDocumentApi from 'models/dealerManagement/dealerGroup/uploadProductDocument/Api.DealerGroup.UploadProductDocument';
import dealerGroupUploadUiFileApi from 'models/dealerManagement/dealerGroup/uploadUiFile/Api.DealerGroup.UploadUiFile';
import { useDealerGroupProvider } from '../DealerGroup.Hooks';
import DealerGroupContractConsentProductAggregate from './edit/DealerGroup.ContractConsentProductAggregate';
import DealerGroupGeneralForm from './edit/DealerGroup.General';
import DealerGroupProductDocumentForm from './edit/DealerGroup.ProductDocumentForm';
import DealerGroupUiSettingsForm from './edit/DealerGroup.UiSettingsForm';

export type DealerGroupForm = Omit<Api.Core.DealerGroup.Save.IRequest, 'productDealerGroupDocuments'> & {
  logo: Hooks.Input.File.Info;
  productDealerGroupDocuments: Array<
    Utils.Unpacked<Api.Core.DealerGroup.Save.IRequest['productDealerGroupDocuments']> & {
      formFile?: Hooks.Input.File.Info;
      isDeleted?: boolean;
    }
  >;
} & {
  newDistributorProducts: Model.IProduct[];
};
export default function DealerGroupEdit() {
  const { lang } = getLocales();
  const { user, isDistributor, isDealerOrDistributor, isAdministrator } = useAuthStore();
  const { selected, pageRouter, listApi } = useDealerGroupProvider();
  const { payload: dealersPayload } = useApi({ action: Dealer.list });

  return (
    <ModalForm<DealerGroupForm>
      subcomponents={ThemeModalFormDefault}
      dependencyArray={[selected]}
      title={lang.group}
      default={
        selected
          ? {
              ...selected.toJSON(),
              logo: selected.uiSettingsFile
                ? {
                    id: selected.uiSettingsFile.id,
                    dataUrl: selected.uiSettingsFile.url,
                    name: selected.uiSettingsFile.name,
                    type: selected.uiSettingsFile.contentType as Utils.File.MimeType,
                  }
                : null,
            }
          : {
              distributorId: user?.distributorId,
            }
      }
      onCancel={pageRouter.updateParams}
      onSubmit={async ({ dealers, logo, productDealerGroupDocuments, ...data }) => {
        const response = await dealerGroupSaveApi(data);

        if (response.status === HttpStatus.ok) {
          let totalFiles = 0;
          let uploadedFiles = 0;
          let deletedFiles = 0;
          let totalDeletedFiles = 0;
          for (const dealer of dealers ?? []) {
            const selected = dealersPayload?.data?.find((d) => d.id === dealer.id);
            await dealerSaveApi(selected);
          }
          for (const productDealerGroupDoc of productDealerGroupDocuments || []) {
            if (productDealerGroupDoc.isDeleted && productDealerGroupDoc.id) {
              const docDeleteReponse = await dealerGroupDeleteProductDocumentApi({
                productDealerGroupDocumentId: productDealerGroupDoc.id,
              });
              if (docDeleteReponse.status === HttpStatus.ok) {
                deletedFiles += 1;
              }
              totalDeletedFiles += 1;
            } else if (productDealerGroupDoc.formFile) {
              const docUploadResponse = await dealerGroupUploadProductDocumentApi(
                {
                  id: response.payload || data.id,
                  productId: productDealerGroupDoc.productDocument.productId,
                  productDocumentId: productDealerGroupDoc.productDocumentId,
                },
                { formFile: productDealerGroupDoc.formFile.original }
              );
              if (docUploadResponse.status === HttpStatus.ok) {
                uploadedFiles += 1;
              }
              totalFiles += 1;
            }
          }
          if (logo?.id !== selected?.uiSettingsFile?.id) {
            const logoUploadResponse = await dealerGroupDeleteUiFileApi(response.payload || data.id);
            if (logoUploadResponse.status === HttpStatus.ok) {
              deletedFiles += 1;
            }
            totalDeletedFiles += 1;
          }
          if (logo && !logo?.id) {
            const logoUploadResponse = await dealerGroupUploadUiFileApi(response.payload || data.id, {
              formFile: logo.original,
            });
            if (logoUploadResponse.status === HttpStatus.ok) {
              uploadedFiles += 1;
            }
            totalFiles += 1;
          }
          listApi.execute((body) => body);
          if (uploadedFiles !== totalFiles || deletedFiles !== totalDeletedFiles) {
            addLog({
              warning: combineStrings(
                ' ',
                `Dealer group was successfully saved but some files did not get deleted or uploaded!`,
                totalFiles > 0 ? `${uploadedFiles}/${totalFiles} files uploaded` : null,
                totalDeletedFiles > 0 ? `${deletedFiles}/${totalDeletedFiles} files got deleted!` : null
              ),
            });
          } else {
            addLog({ success: lang.saveSuccess });
          }
        } else {
          addLog({ error: response.message });
        }
        listApi.execute((body) => body);
      }}
      validation={(data, errors) => {
        if (!data.name) errors.name = lang.mustNotBeEmpty;
        if (!data.distributorId) errors.distributorId = lang.mustNotBeEmpty;
      }}
      tabs={[
        ...(isDistributor || isDealerOrDistributor || isAdministrator
          ? [{ id: 'info', icon: 'fas-info-circle', text: lang.info }]
          : []),
        ...(isDistributor || isDealerOrDistributor || isAdministrator
          ? [{ id: 'productDocuments', icon: 'fas-file', text: lang.productDocuments }]
          : []),
        ...(isDistributor || isDealerOrDistributor || isAdministrator
          ? [
              {
                id: 'contractConsentProductAggregates',
                icon: 'fas-database',
                text: lang.contractConsentProductAggregates,
              },
            ]
          : []),
        ...(isDistributor || isDealerOrDistributor || isAdministrator
          ? [{ id: 'uiSettings', icon: 'fas-paint-roller', text: lang.uiSettings }]
          : []),
      ]}
      render={({ activeTab }) =>
        activeTab?.id === 'info' || !activeTab?.id ? (
          <DealerGroupGeneralForm />
        ) : activeTab?.id === 'uiSettings' ? (
          <DealerGroupUiSettingsForm />
        ) : activeTab?.id === 'group' ? (
          <DealerGroupGeneralForm />
        ) : activeTab?.id === 'productDocuments' ? (
          <DealerGroupProductDocumentForm />
        ) : activeTab?.id === 'contractConsentProductAggregates' ? (
          <DealerGroupContractConsentProductAggregate />
        ) : null
      }
    />
  );
}
