import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { HttpMethod, getDefaultHeaders } from 'config/Api.Config';
import { omit, pick } from 'lodash';
import Model from 'models/Model';

export async function dealerEnhancementDistributionLimitationSaveApi(
  body?: Api.Core.DealerEnhancementDistributionLimitation.Save.IRequest
) {
  const serverResponse = await executeServerApi<
    Api.Core.DealerEnhancementDistributionLimitation.Save.IRequest,
    Api.Core.DealerEnhancementDistributionLimitation.Save.IResponse,
    Api.Core.DealerEnhancementDistributionLimitation.Save.IHeaders
  >({
    method: body?.id ? HttpMethod.put : HttpMethod.post,
    url: '/api/DealerManagement/DealerEnhancementDistributionLimitation',
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export async function dealerEnhancementDistributionLimitationListApi(
  body?: Api.Core.DealerEnhancementDistributionLimitation.List.IParams &
    Api.Core.DealerEnhancementDistributionLimitation.List.IRequest
) {
  return await executeServerApi<
    Api.Core.DealerEnhancementDistributionLimitation.List.IRequest,
    Api.Core.DealerEnhancementDistributionLimitation.List.IResponse,
    Api.Core.DealerEnhancementDistributionLimitation.List.IHeaders
  >({
    method: HttpMethod.get,
    url: createPathFromRoute(
      '/api/DealerManagement/DealerEnhancementDistributionLimitation/:dealerId/:productEnhancementId',
      pick(body, 'dealerId', 'productEnhancementId'),
      omit(body, 'dealerId', 'productEnhancementId')
    ),
    body: omit(body, 'dealerId', 'productEnhancementId'),
    headers: getDefaultHeaders(),
  });
}
export async function dealerEnhancementDistributionLimitationDeleteApi(id: number) {
  const serverResponse = await executeServerApi<
    Api.Core.DealerEnhancementDistributionLimitation.Delete.IRequest,
    Api.Core.DealerEnhancementDistributionLimitation.Delete.IResponse,
    Api.Core.DealerEnhancementDistributionLimitation.Delete.IHeaders
  >({
    method: HttpMethod.delete,
    url: createPathFromRoute('/api/DealerManagement/DealerEnhancementDistributionLimitation/:id', { id }),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export default class DealerEnhancementDistributionLimitation
  extends Model<Model.IDealerEnhancementDistributionLimitation>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IDealerEnhancementDistributionLimitation>
{
  id: number;
  productEnhancementId: number;
  dealerId: number;
  pricePointId: number;
  pricePointType: Utils.PricePointType;
  limitations: Utils.DistributionLimitation;

  constructor(
    data:
      | Partial<Model.IDealerEnhancementDistributionLimitation>
      | Utils.FormData<Model.IDealerEnhancementDistributionLimitation>,
    language?: Locale.Languages
  ) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.id.toString(),
    };
  }

  static async list(
    body?: Api.Core.DealerEnhancementDistributionLimitation.List.IParams &
      Api.Core.DealerEnhancementDistributionLimitation.List.IRequest
  ) {
    const { payload, ...rest } = await dealerEnhancementDistributionLimitationListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new DealerEnhancementDistributionLimitation(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await dealerEnhancementDistributionLimitationSaveApi(this);
  }
  async delete() {
    return await dealerEnhancementDistributionLimitationDeleteApi(this.id);
  }
}
