import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import DataMap from 'assets/components/dataMap/DataMap';
import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import ThemeTableSimple from 'assets/components/table/themes/Theme.Table.Simple';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import Contract from 'models/sales/contract/Model.Contract';
import useClaimCreator from 'module/claims/hooks/useClaimCreator';
import { useState } from 'react';
import { allowedContractStatusesForClaimCreation, useClaimProvider } from '../Claim.Hooks';
import ClaimSimpleClaimForm from '../simpleClaim/Claim.SimpleClaimForm';
import dayjs from 'dayjs';
import ContractStatusCode from 'models/enums/ContractStatusCode';
import { combineStrings } from 'assets/utils/data/String';
import importLegacyContractApi from 'models/sales/contract/legacy/importLegacyContract/Api.ImportLegacyContract';
import { addLog } from 'assets/components/feedback/Feedback';

export default function ClaimAdd() {
  const { lang } = getLocales();
  const { pageRouter } = useClaimProvider();
  const [simpleClaimData, setSimpleClaimData] = useState(undefined);

  const contractWithLegacyApi = useApi({
    action: Contract.listWithLegacy,
    wait: true,
  });
  const { canCreateClaim, canCreateSimpleClaim, createClaim } = useClaimCreator();

  const handleCreateClaim = async (data: Model.IContract) => {
    // Api call
    let createClaimPayload = data;
    if (!data.id) {
      const res = await importLegacyContractApi(data);

      if (!res.payload?.id) {
        addLog({ error: res.message });
        return;
      }

      createClaimPayload = res.payload;
    }
    createClaim(createClaimPayload);
  };

  return (
    <ModalForm<Api.Core.Contract.ListWithLegacy.IRequest>
      subcomponents={ThemeModalFormDefault}
      dependencyArray={[contractWithLegacyApi.body]}
      title={lang.claim}
      default={contractWithLegacyApi.body}
      onCancel={pageRouter.updateParams}
      onSubmit={(data) => contractWithLegacyApi.execute(data)}
      submitButtonText={lang.search}
      render={({ form }) => (
        <>
          <FormWrapper>
            <Card
              class={ThemeCardRow}
              style={{
                card: { flexDirection: 'column', padding: 0 },
                cardSubtitle: { fontSize: Theme.FontNestedSize.M },
              }}
              title={lang.submitANewClaim}
              subtitle={lang.searchContractEnteringVinAndContractNumberOrClientLastName}
            />
            &nbsp;
            <TextInput
              name="contractNumber"
              label={lang.contractNumber}
              value={form.data?.contractNumber}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
            <TextInput
              name="vin"
              label={lang.vin}
              value={form.data?.vin}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
            <TextInput
              name="clientName"
              label={lang.lastName}
              value={form.data?.clientName}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
          </FormWrapper>
          <table className={ThemeTableSimple}>
            <thead>
              <tr>
                <th children={lang.contractNumber} />
                <th children={lang.status} />
                <th children={lang.clientName} />
                <th children={lang.vin} />
                <th children={lang.expiryDate} />
                <th children={lang.actions} style={{ textAlign: 'center' }} />
              </tr>
            </thead>
            <tbody>
              <DataMap
                data={contractWithLegacyApi.payload?.data}
                render={({ data }) => (
                  <tr key={data.id ?? data.appNumber}>
                    <td children={data.appNumber} />
                    <td children={data.statusCode} />
                    <td children={combineStrings(' ', data.client?.firstName, data.client?.lastName) || ''} />
                    <td children={data.vehicle?.vin} />
                    <td
                      children={dayjs(data?.expiryDate, defaultServerDateTimeFormat)?.format(defaultDateTimeFormat)}
                    />
                    <td style={{ textAlign: 'center' }}>
                      <Button
                        media={<Icon class="fas-cloud-upload-alt" />}
                        class={ThemeButtonCircle}
                        htmlElementProps={{ title: lang.createSimpleClaim }}
                        style={{ display: 'inline-block' }}
                        disabled={
                          (data.id &&
                            !allowedContractStatusesForClaimCreation.includes(data.statusCode as ContractStatusCode)) ||
                          !canCreateSimpleClaim
                        }
                        onClick={setSimpleClaimData}
                        data={data}
                      />
                      <Button
                        media={<Icon class="fas-plus" />}
                        class={ThemeButtonCircle}
                        htmlElementProps={{ title: lang.createClaimForContract }}
                        style={{ display: 'inline-block' }}
                        onClick={handleCreateClaim}
                        data={data}
                        disabled={
                          (data.id &&
                            !allowedContractStatusesForClaimCreation.includes(data.statusCode as ContractStatusCode)) ||
                          !canCreateClaim
                        }
                      />
                    </td>
                  </tr>
                )}
              />
            </tbody>
          </table>
          {simpleClaimData && (
            <ClaimSimpleClaimForm contract={simpleClaimData} onClose={() => setSimpleClaimData(undefined)} />
          )}
        </>
      )}
    />
  );
}
