import Model from 'models/Model';
import PricingDistributionTemplate from 'models/productManagement/pricingDistributionTemplate/Model.PricingDistributionTemplate';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import { omit, pick } from 'lodash';
import DistributorProductEnhancementPricingDistribution from '../distributorProductEnhancementPricingDistribution/Model.DistributorProductEnhancementPricingDistribution';
import ProductEnhancement from 'models/productManagement/productEnhancement/Model.ProductEnhancement';

export async function distributorProductEnhancementSaveApi(
  body: Api.Core.DistributorProductEnhancement.Save.IRequest
): Promise<Api.Res<Api.Core.DistributorProductEnhancement.Save.IResponse>> {
  const serverResponse = await executeServerApi<
    Api.Core.DistributorProductEnhancement.Save.IRequest,
    Api.Core.DistributorProductEnhancement.Save.IResponse,
    Api.Core.DistributorProductEnhancement.Save.IHeaders
  >({
    method: body?.id ? HttpMethod.put : HttpMethod.post,
    url: '/api/ProductManagement/DistributorProductEnhancement',
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}

export async function distributorProductEnhancementDeleteApi(
  body: Api.Core.DistributorProductEnhancement.Delete.IRequest
): Promise<Api.Res<Api.Core.DistributorProductEnhancement.Delete.IResponse>> {
  const serverResponse = await executeServerApi<
    Api.Core.DistributorProductEnhancement.Delete.IRequest,
    Api.Core.DistributorProductEnhancement.Delete.IResponse,
    Api.Core.DistributorProductEnhancement.Delete.IHeaders
  >({
    method: HttpMethod.delete,
    url: createPathFromRoute(
      '/api/ProductManagement/DistributorProductEnhancement/:distributorId/:productEnhancementId',
      body
    ),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export async function distributorProductEnhancementListApi(
  body: Api.Core.DistributorProductEnhancement.List.IRequest
): Promise<Api.Res<Api.Core.DistributorProductEnhancement.List.IResponse>> {
  return await executeServerApi<
    Omit<Api.Core.DistributorProductEnhancement.List.IRequest, 'distributorId' | 'productEnhancementId'>,
    Api.Core.DistributorProductEnhancement.List.IResponse,
    Api.Core.DistributorProductEnhancement.List.IHeaders
  >({
    method: HttpMethod.get,
    url: createPathFromRoute(
      '/api/ProductManagement/DistributorProductEnhancement/:distributorId/:productEnhancementId',
      pick(body, 'distributorId', 'productEnhancementId')
    ),
    body: omit(body, 'distributorId', 'productEnhancementId'),
    headers: getDefaultHeaders(),
  });
}

export default class DistributorProductEnhancement
  extends Model<Model.IDistributorProductEnhancement>({ icon: 'if-login' })
  implements Model.Instance<Model.IDistributorProductEnhancement>
{
  id: number;
  productEnhancementId: number;
  productEnhancement: ProductEnhancement;
  distributorId: number;
  pricingDistributionTemplateId: number;
  pricingDistributionTemplate?: PricingDistributionTemplate;
  pricingDistributions?: Array<DistributorProductEnhancementPricingDistribution>;

  constructor(
    data: Partial<Model.IDistributorProductEnhancement> | Utils.FormData<Model.IDistributorProductEnhancement>,
    language?: Locale.Languages
  ) {
    super(
      {
        pricingDistributionTemplate: PricingDistributionTemplate,
        productEnhancement: ProductEnhancement,
        pricingDistributions: [DistributorProductEnhancementPricingDistribution],
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.productEnhancementId,
      title: this.productEnhancement.name || this.productEnhancementId.toString(),
      info: this.pricingDistributionTemplate?.name,
    };
  }

  static async list(body: Api.Core.DistributorProductEnhancement.List.IRequest) {
    const { payload, ...rest } = await distributorProductEnhancementListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new DistributorProductEnhancement(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await distributorProductEnhancementSaveApi(this);
  }
  async delete() {
    return await distributorProductEnhancementDeleteApi({ id: this.id });
  }
}
