import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { get } from 'lodash';
import ContractStatusCode from 'models/enums/ContractStatusCode';
import contractSetStatusApi from 'models/sales/contract/setStatus/Api.Contract.SetStatus';
import { useSalesContractProvider } from '../Sales.Contract.Hooks';

type Props = {
  statusInfo: Api.Core.Contract.StatusFlow.IResponse;
  disabled?: boolean;
  currentStatus: Model.Enum.ContractStatusCode;
  contractId: number;
  onChange(): void;
};
export default function ContractStatusStateMachine({
  statusInfo,
  currentStatus,
  contractId,
  disabled,
  onChange,
}: Props) {
  const { pageRouter } = useSalesContractProvider();
  const { permissions } = useAuthStore();
  const { lang } = getLocales();

  const nextStatusList = get(statusInfo, currentStatus);

  return (
    <DropdownButton
      buttonMedia={<Icon class="fas-check-double" />}
      horizontalPosition="auto_fixed"
      verticalPosition="auto_fixed"
      subcomponents={ThemeDropdownButtonPopup}
      htmlElementProps={{ title: lang.changeStatus }}
      disabled={!permissions.UPDATE_CONTRACT_STATUS || !nextStatusList?.length || disabled}
      tabIndex={null}
      dropdownOptions={({ animateOut }) => (
        <DataMap
          data={nextStatusList}
          render={({ data: status }) => (
            <Button
              key={status}
              onClick={async (newStatus) => {
                animateOut();

                if (newStatus !== ContractStatusCode.CANCELED_REFUND_PR_REFUND) {
                  const data: Api.Core.Contract.SetStatus.IRequest = {
                    newStatus: newStatus,
                  };

                  await contractSetStatusApi(contractId, data);

                  onChange();
                } else {
                  pageRouter.updateParams({ id: contractId.toString(), action: 'cancellation' });
                }
              }}
              data={status}
              render={status?.replace('_', ' - ')}
              class={ThemeButtonMenuItem}
            />
          )}
        />
      )}
    />
  );
}
