import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { HttpMethod, getDefaultHeaders } from 'config/Api.Config';
import Model from 'models/Model';
import PricingDistributionTemplate from 'models/productManagement/pricingDistributionTemplate/Model.PricingDistributionTemplate';
import Product from 'models/productManagement/product/Model.Product';

export async function distributorProductEnhancementPricingDistributionSaveApi(
  body?: Api.Core.DistributorProductEnhancementPricingDistribution.Save.IRequest
) {
  const serverResponse = await executeServerApi<
    Api.Core.DistributorProductEnhancementPricingDistribution.Save.IRequest,
    Api.Core.DistributorProductEnhancementPricingDistribution.Save.IResponse,
    Api.Core.DistributorProductEnhancementPricingDistribution.Save.IHeaders
  >({
    method: body?.id ? HttpMethod.put : HttpMethod.post,
    url: '/api/ProductManagement/DistributorProductEnhancement/DistributorProductEnhancementPricingDistribution',
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export async function distributorProductEnhancementPricingDistributionListApi(
  body?: Api.Core.DistributorProductEnhancementPricingDistribution.List.IRequest
) {
  return await executeServerApi<
    Api.Core.DistributorProductEnhancementPricingDistribution.List.IRequest,
    Api.Core.DistributorProductEnhancementPricingDistribution.List.IResponse,
    Api.Core.DistributorProductEnhancementPricingDistribution.List.IHeaders
  >({
    method: HttpMethod.get,
    url: createPathFromRoute(
      '/api/ProductManagement/DistributorProductEnhancement/DistributorProductEnhancementPricingDistribution'
    ),
    body,
    headers: getDefaultHeaders(),
  });
}
export async function distributorProductEnhancementPricingDistributionDeleteApi(id: number) {
  const serverResponse = await executeServerApi<
    Api.Core.DistributorProductEnhancementPricingDistribution.Delete.IRequest,
    Api.Core.DistributorProductEnhancementPricingDistribution.Delete.IResponse,
    Api.Core.DistributorProductEnhancementPricingDistribution.Delete.IHeaders
  >({
    method: HttpMethod.delete,
    url: createPathFromRoute(
      '/api/ProductManagement/DistributorProductEnhancement/DistributorProductEnhancementPricingDistribution/:id',
      {
        id,
      }
    ),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export default class DistributorProductEnhancementPricingDistribution
  extends Model<Model.IDistributorProductEnhancementPricingDistribution>({ icon: 'if-login' })
  implements Model.Instance<Model.IDistributorProductEnhancementPricingDistribution>
{
  id: number;
  pricePointId: number;
  pricePointType: Utils.PricePointType;
  distributions: Utils.PricingDistribution;
  distributorProductEnhancementId: number;

  constructor(
    data: Partial<Model.IDistributorProduct> | Utils.FormData<Model.IDistributorProduct>,
    language?: Locale.Languages
  ) {
    super({ pricingDistributionTemplate: PricingDistributionTemplate, product: Product }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.pricePointType || this.id.toString(),
    };
  }
}
