import Icon from 'assets/components/icon/Icon';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import ThemeWrapperPaper from 'assets/themes/wrappers/Theme.Wrapper.Paper';
import ProductType from 'models/enums/ProductType';
import { useProductEnhancementProvider } from '../../ProductEnhancement.Hooks';
import useApi from 'assets/hooks/api/useApi';
import PricingDistributionTemplate from 'models/productManagement/pricingDistributionTemplate/Model.PricingDistributionTemplate';
import Product from 'models/productManagement/product/Model.Product';

export default function ProductEnhancementEditInfo() {
  const { form } = useProductEnhancementProvider();
  const { lang } = getLocales();
  const { payload: distributionsPayload } = useApi({ action: PricingDistributionTemplate.list });
  const { payload: productsPayload } = useApi({
    action: Product.list,
    body: {
      productType: 'WARRANTY_TIER-BASED',
    },
  });

  return (
    <FormWrapper class={ThemeWrapperPaper}>
      <div style={{ fontSize: Theme.FontSize.L, fontWeight: 'bold' }}>{lang.generalInformation}</div>
      <br />
      <TextInput
        name="name"
        label={lang.name}
        value={form.data.name}
        error={form.errors.name}
        onChange={form.update}
        icon={<Icon class="if-quill-pen" />}
      />
      <SelectInput
        name="productType"
        label={lang.type}
        data={Object.values(ProductType).map((id) => ({
          id,
          title: lang[id] || id,
        }))}
        value={form.data.productType}
        error={form.errors.productType}
        onChange={({ productType }) => form.update({ productType })}
        icon={<Icon class="if-cloud" />}
        disabled
      />
      <SelectInput
        name="pricingDistributionTemplateId"
        label={lang.pricingDistributionTemplate}
        data={distributionsPayload?.data?.filter((it) => !it.distributorId).map((it) => it.displayInfo)}
        value={form.data.pricingDistributionTemplateId}
        error={form.errors.pricingDistributionTemplateId}
        onChange={form.update}
        icon={<Icon class="fas-balance-scale" />}
      />
      <SelectInput
        name="products"
        label={lang.products}
        data={productsPayload?.data.map((it) => it.displayInfo)}
        error={form.errors.products?._objectError}
        isMultiselect
        icon={<Icon class="fas-box" />}
        value={form.data.products?.map((it) => it.id)}
        onChange={(selectData) =>
          form.update({
            products: selectData.products
              .map((it) => productsPayload?.data?.find((d) => d.id === it))
              ?.filter((it) => (it ? true : false)),
          })
        }
      />
    </FormWrapper>
  );
}
