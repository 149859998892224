import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import Modal from 'assets/components/page/templates/Modal';
import ThemeModalDefault from 'assets/components/page/templates/themes/Theme.Modal.Default';
import ThemeTableSimple from 'assets/components/table/themes/Theme.Table.Simple';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { formatNumberWithSeparator } from 'assets/utils/data/Number';
import { omit, sumBy } from 'lodash';
import ClaimJob from 'models/claims/claimJob/Model.ClaimJob';
import ClaimJobItemPayee from 'models/claims/claimJobItemPayee/Model.ClaimJobItemPayee';
import ClaimStatusCode from 'models/enums/ClaimStatusCode';
import PaymentType from 'models/enums/PaymentType';
import { useState } from 'react';
import { useClaimEdit } from '../../ClaimEdit.Hooks';
import { useClaimJobEdit } from './ClaimEdit.Job.Hooks';
import ClaimEditJobSendPaymentRequestEmail from './claimJobEmail/ClaimEdit.Job.SendPaymentRequestEmail';

type PaymentData = {
  claimJobId: number;
  claimInvoiceId: number;
  payeeId: number;
  clientId: number;
  dealerId: number;
  email: string;
};
export type ClaimJobAggregateItem = {
  key: string;
  jobItemId: number;
  jobId: number;
  payee: ClaimJobItemPayee;
  contactEmail: string;
  jobItemTypeCode: string;
  currency: string;
  paymentMethodCode: string;
  parts: number;
  labour: number;
  other: number;
  deduct: number;
  readonly ttl: number;
  gstHst: number;
  provincialSalesTax: number;
  readonly total: number;
  pendingPaymentAmount: number;
  paidAmount: number;
  usBankVirtualCardId: string;
} & { claimInvoiceId: number };

export type PaymentRequestEmail = {
  claimJobItem: ClaimJobAggregateItem;
  emailData?: Utils.Email.Data;
};

type Props = {
  aggregates: ReturnType<typeof ClaimJob.aggregates>;
  currency: string;
  onUpdate(data: Utils.FormData<Model.IClaimJobItem>): void;
  onPaymentInit?(data: PaymentData): void;
};
export default function ClaimEditJobAggregateTable({ aggregates, currency, onUpdate, onPaymentInit }: Props) {
  const { lang } = getLocales();
  const { form, isClaimJobItemEditable, isAnyClaimJobItemPaid } = useClaimJobEdit();
  const { currentStatus, claim } = useClaimEdit();
  const isActive = !form.hasErrors && form.isUpdated;
  const [pendingPayment, setPendingPayment] = useState<PaymentData & { payeeName?: string }>();
  const [paymentRequestEmailData, setPaymentRequestEmailData] = useState<PaymentRequestEmail>(undefined);

  return (
    <>
      {pendingPayment && (
        <Modal
          header={lang.userConfirmationRequired}
          subcomponents={ThemeModalDefault}
          onClose={() => setPendingPayment(undefined)}
          render={
            <FormWrapper>
              <div style={{ textAlign: 'center', fontWeight: 600 }}>{lang.sendPaymentRequestTo}:</div>
              <p style={{ textAlign: 'center' }}>{pendingPayment?.payeeName}</p>
              <SelectInput
                name="claimInvoiceId"
                label={lang.invoices}
                data={claim?.claimInvoices?.map((it) => it.displayInfo)}
                value={pendingPayment?.claimInvoiceId}
                error={!pendingPayment?.claimInvoiceId && lang.required}
                onChange={({ claimInvoiceId }) => setPendingPayment((prev) => ({ ...prev, claimInvoiceId }))}
              />
              <TextInput
                name="email"
                label={lang.email}
                value={pendingPayment?.email}
                error={!pendingPayment?.email && lang.required}
                onChange={({ email }) => setPendingPayment((prev) => ({ ...prev, email }))}
              />
            </FormWrapper>
          }
          footer={
            <Button
              class={ThemeButtonCircle}
              render={lang.sendPaymentRequest}
              disabled={!pendingPayment?.claimInvoiceId}
              onClick={() => onPaymentInit(omit(pendingPayment, 'payeeName'))}
              active
            />
          }
        />
      )}
      {paymentRequestEmailData && (
        <ClaimEditJobSendPaymentRequestEmail
          paymentRequestEmailData={paymentRequestEmailData}
          setPaymentRequestEmailData={setPaymentRequestEmailData}
        />
      )}
      <div style={{ marginLeft: Theme.Size.XL, marginRight: Theme.Size.XL }}>
        <h3>{lang.payees}</h3>
      </div>
      <table className={ThemeTableSimple} style={{ gridColumn: '-1/1', fontSize: Theme.FontSize.S }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }}>#</th>
            <th style={{ textAlign: 'center' }}>{lang.payee}</th>
            <th style={{ textAlign: 'center' }}>{lang.currency}</th>
            <th style={{ textAlign: 'center' }}>{lang.paymentMethod}</th>
            <th style={{ textAlign: 'center' }}>{lang.parts}</th>
            <th style={{ textAlign: 'center' }}>{lang.labour}</th>
            <th style={{ textAlign: 'center' }}>{lang.other}</th>
            <th style={{ textAlign: 'center' }}>{lang.ttl}</th>
            <th style={{ textAlign: 'center' }}>{lang.deduct}</th>
            <th style={{ textAlign: 'center' }}>{currency === 'CAD' ? 'GST/HST' : lang.tax}</th>
            {currency === 'CAD' && <th style={{ textAlign: 'center' }}>PST</th>}
            <th style={{ textAlign: 'center' }}>{lang.totalTax}</th>
            <th style={{ textAlign: 'center' }}>{lang.total}</th>
            <th style={{ textAlign: 'center' }}>{lang.pending}</th>
            <th style={{ textAlign: 'center' }}>{lang.paidAmount}</th>
            <th style={{ textAlign: 'center' }}></th>
          </tr>
        </thead>
        <tbody>
          <DataMap
            data={aggregates.filter((it) => it.payee?.payeeId || it.payee?.dealerId || it.payee?.clientId)}
            render={({ data: item, index }) => (
              <tr key={index}>
                <td style={{ textAlign: 'center' }}>{index + 1}</td>
                <td style={{ textAlign: 'center' }}>{item.payee?.displayInfo?.title}</td>
                <td style={{ textAlign: 'center' }}>{item.currency}</td>
                <td style={{ textAlign: 'center' }}>
                  <SelectInput
                    name="paymentMethodCode"
                    data={Object.values(PaymentType).map((it) => ({
                      id: it,
                      title: it,
                    }))}
                    disabled={isAnyClaimJobItemPaid || isClaimJobItemEditable}
                    value={item.paymentMethodCode ?? item.payee?.payee?.paymentMethodCode}
                    onChange={({ paymentMethodCode }) =>
                      onUpdate({
                        paymentMethodCode: paymentMethodCode,
                        claimJobItemPayee: item.payee,
                      })
                    }
                    style={{ maxWidth: '120px' }}
                  />
                </td>
                <td style={{ textAlign: 'center' }}>{formatNumberWithSeparator(item.parts.toFixed(2))}</td>
                <td style={{ textAlign: 'center' }}>{formatNumberWithSeparator(item.labour.toFixed(2))}</td>
                <td style={{ textAlign: 'center' }}>{formatNumberWithSeparator(item.other.toFixed(2))}</td>
                <td style={{ textAlign: 'center' }}>{formatNumberWithSeparator(item.ttl.toFixed(2))}</td>
                <td style={{ textAlign: 'center' }}>{formatNumberWithSeparator(item.deduct.toFixed(2))}</td>
                <td style={{ textAlign: 'center' }}>{formatNumberWithSeparator(item.gstHst.toFixed(2))}</td>
                {currency === 'CAD' && (
                  <td style={{ textAlign: 'center' }}>
                    {formatNumberWithSeparator(item.provincialSalesTax.toFixed(2))}
                  </td>
                )}
                <td style={{ textAlign: 'center' }}>{formatNumberWithSeparator(item.totalTaxAmount.toFixed(2))}</td>
                <td style={{ textAlign: 'center' }}>{formatNumberWithSeparator(item.total.toFixed(2))}</td>
                <td style={{ textAlign: 'center' }}>
                  {formatNumberWithSeparator(item.pendingPaymentAmount.toFixed(2))}
                </td>
                <td style={{ textAlign: 'center' }}>{formatNumberWithSeparator(item.paidAmount.toFixed(2))}</td>
                <td style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
                  {item.paymentMethodCode === PaymentType.usBank && (
                    <Button
                      class={ThemeButtonCircle}
                      media={<Icon class="fas-money-check-alt" />}
                      htmlElementProps={{
                        button: {
                          title:
                            item.paymentMethodCode !== PaymentType.usBank || item.currency !== 'CAD'
                              ? lang.paymentIsAvailableOnlyFor
                              : currentStatus !== 'OPEN_APPROVED-TO-PAY'
                              ? lang.paymentRequiresClaimToBeInCorrectStatus
                              : !item.jobId || isActive
                              ? lang.pleaseSaveJobToDoPayment
                              : item.pendingPaymentAmount
                              ? lang.youAlreadyHavePendingPayment
                              : item.total - item.paidAmount <= 0
                              ? lang.alreadyPaid
                              : lang.sendPaymentRequest,
                        },
                      }}
                      disabled={
                        !(
                          onPaymentInit &&
                          currentStatus == 'OPEN_APPROVED-TO-PAY' &&
                          item.currency === 'CAD' &&
                          item.jobId &&
                          item.total - item.paidAmount > 0 &&
                          !item.pendingPaymentAmount &&
                          item.paymentMethodCode === PaymentType.usBank &&
                          !isActive
                        )
                      }
                      onClick={setPendingPayment}
                      data={{
                        email: item.payee?.dealer?.claimPaymentEmail ?? claim?.createdByPayee?.email,
                        claimJobId: item.jobId,
                        claimInvoiceId: undefined,
                        payeeId: item.payee?.payeeId,
                        dealerId: item.payee?.dealerId,
                        clientId: item.payee?.clientId,
                        payeeName: item.payee?.displayInfo?.title
                          ? `${item.payee?.displayInfo?.title} (${item.total - item.paidAmount} ${item.currency})`
                          : undefined,
                      }}
                    />
                  )}
                  {(item.paymentMethodCode === PaymentType.eft || item.paymentMethodCode === PaymentType.cheque) && (
                    <Button
                      class={ThemeButtonCircle}
                      media={<Icon class="fas-money-check-alt" />}
                      htmlElementProps={{
                        button: {
                          title:
                            (item.paymentMethodCode === PaymentType.eft ||
                              item.paymentMethodCode === PaymentType.cheque) &&
                            currentStatus !== ClaimStatusCode.OPEN_APPROVED_TO_PAY
                              ? lang.paymentRequiresClaimToBeInCorrectStatus
                              : !item.jobId || isActive
                              ? lang.pleaseSaveJobToDoPayment
                              : lang.sendPaymentRequestEmail,
                        },
                      }}
                      disabled={
                        !(
                          currentStatus === ClaimStatusCode.OPEN_APPROVED_TO_PAY &&
                          (item.paymentMethodCode === PaymentType.eft || item.paymentMethodCode === PaymentType.cheque)
                        )
                      }
                      onClick={setPaymentRequestEmailData}
                      data={{
                        claimJobItem: { ...item, claimInvoiceId: undefined },
                        emailData: {
                          senderEmail: 'notifications@goautoshield.com',
                          senderName: 'Notifications',
                          subject: 'Claims - Payment request',
                          attachments: [],
                        },
                      }}
                    />
                  )}
                </td>
              </tr>
            )}
          />
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4} style={{ textAlign: 'right', fontWeight: 'bold' }}>
              Current total
            </td>
            <td style={{ textAlign: 'center' }}>
              {formatNumberWithSeparator(sumBy(aggregates, (it) => it.parts).toFixed(2))}
            </td>
            <td style={{ textAlign: 'center' }}>
              {formatNumberWithSeparator(sumBy(aggregates, (it) => it.labour).toFixed(2))}
            </td>
            <td style={{ textAlign: 'center' }}>
              {formatNumberWithSeparator(sumBy(aggregates, (it) => it.other).toFixed(2))}
            </td>
            <td style={{ textAlign: 'center' }}>
              {formatNumberWithSeparator(sumBy(aggregates, (it) => it.ttl).toFixed(2))}
            </td>
            <td style={{ textAlign: 'center' }}>
              {formatNumberWithSeparator(sumBy(aggregates, (it) => it.deduct).toFixed(2))}
            </td>
            <td style={{ textAlign: 'center' }}>
              {formatNumberWithSeparator(sumBy(aggregates, (it) => it.gstHst).toFixed(2))}
            </td>
            {currency === 'CAD' && (
              <td style={{ textAlign: 'center' }}>
                {formatNumberWithSeparator(sumBy(aggregates, (it) => it.provincialSalesTax).toFixed(2))}
              </td>
            )}
            <td style={{ textAlign: 'center' }}>
              {formatNumberWithSeparator(sumBy(aggregates, (it) => it.totalTaxAmount).toFixed(2))}
            </td>
            <td style={{ textAlign: 'center' }}>
              {formatNumberWithSeparator(sumBy(aggregates, (it) => it.total).toFixed(2))}
            </td>
            <td style={{ textAlign: 'center' }}>
              {formatNumberWithSeparator(sumBy(aggregates, (it) => it.pendingPaymentAmount).toFixed(2))}
            </td>
            <td style={{ textAlign: 'center' }}>
              {formatNumberWithSeparator(sumBy(aggregates, (it) => it.paidAmount).toFixed(2))}
            </td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}
