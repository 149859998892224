import { addLog } from 'assets/components/feedback/Feedback';
import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import { inputStyles } from 'assets/components/inputs/Input';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import { date } from 'assets/utils/data/Date';
import { autoTriggerDownload } from 'assets/utils/dom/Download';
import { HttpStatus } from 'config/Api.Config';
import productPricingPlanExportApi from 'models/productManagement/productPricingPlan/export/Api.ProductPricingPlan.Export';
import { useWarrantyClassPricingProvider } from '../WarrantyClassPricing.Hooks';
import { useEffect, useState } from 'react';

const WarrantyClassPricingExport = () => {
  const { lang } = getLocales();
  const { productApi, provinceGroup, distributorPricingDistributions, dealerPricingSettings, setIsExporting } =
    useWarrantyClassPricingProvider();
  const [defaultFilters, setDefaultFilters] = useState<Api.Core.ProductPricingPlan.Export.IRequest>({
    productId: productApi.payload?.id,
    provinceGroupId: provinceGroup?.id,
    pricingDistributionTemplateId: distributorPricingDistributions.productRelation?.pricingDistributionTemplateId,
    distributorId: distributorPricingDistributions.distributorId,
    includeDistributorPricing: false,
  });

  useEffect(() => {
    if (
      distributorPricingDistributions.productRelation?.pricingDistributionTemplateId !==
      defaultFilters.pricingDistributionTemplateId
    ) {
      setDefaultFilters((prevFilters) => ({
        ...prevFilters,
        pricingDistributionTemplateId: distributorPricingDistributions.productRelation?.pricingDistributionTemplateId,
      }));
    }
  }, [distributorPricingDistributions.productRelation?.pricingDistributionTemplateId]);

  return (
    <ModalForm<Api.Core.ProductPricingPlan.Export.IRequest>
      subcomponents={ThemeModalFormDefault}
      dependencyArray={[]}
      title={lang.export}
      default={{
        ...defaultFilters,
      }}
      onCancel={() => setIsExporting(false)}
      onSubmit={async (data) => {
        const res = await productPricingPlanExportApi({ ...data });

        if (res?.status === HttpStatus.ok) {
          const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res?.payload}`;
          autoTriggerDownload({
            url,
            name: `P${productApi.payload?.id}_WarrantyClassPricing_${date().format('DD-MM-YY')}.xlsx`,
          });
          addLog({ success: lang.exportSuccess });
        } else addLog({ error: lang.exportError });

        setIsExporting(false);
      }}
      render={({ form }) => (
        <>
          <FormWrapper>
            <CheckInput
              class={inputStyles.check.switch}
              name="includeDistributorPricing"
              label={lang.includeDistributorPricing}
              value={form.data.includeDistributorPricing}
              onChange={form.update}
            />
            &nbsp;
            {form.data.includeDistributorPricing && (
              <InputWrapper>
                {distributorPricingDistributions.distributors?.length &&
                  !distributorPricingDistributions.isDealerOrDistributor && (
                    <SelectInput
                      name="distributorId"
                      value={form.data.distributorId}
                      label={lang.distributors}
                      data={distributorPricingDistributions.distributors?.map((item) => ({
                        ...item.displayInfo,
                        disabled:
                          !!dealerPricingSettings.dealerId &&
                          item.id !== dealerPricingSettings.dealer?.representativeId,
                      }))}
                      onChange={({ distributorId }) => {
                        distributorPricingDistributions.setDistributorId(distributorId);
                        form.update({ distributorId });
                      }}
                      style={{ width: '200px' }}
                    />
                  )}
                <SelectInput
                  name="pricingDistributionTemplateId"
                  value={form.data.pricingDistributionTemplateId}
                  label={lang.pricingDistributionTemplate}
                  data={distributorPricingDistributions.templates?.map((item) => item.displayInfo)}
                  onChange={({ pricingDistributionTemplateId }) => {
                    distributorPricingDistributions.setDistributorData({
                      templateId: pricingDistributionTemplateId,
                      isUpdated: true,
                      distributions: [],
                      template: distributorPricingDistributions.templates?.find(
                        (it) => it.id === pricingDistributionTemplateId
                      ),
                    });
                    form.update({
                      pricingDistributionTemplateId,
                    });
                  }}
                  disabled={!!distributorPricingDistributions.productRelation?.pricingDistributionTemplateId}
                />
              </InputWrapper>
            )}
          </FormWrapper>
        </>
      )}
    />
  );
};

export default WarrantyClassPricingExport;
