import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Contract.GenerateRevisionDocuments.IRequest;
type Response = Api.Core.Contract.GenerateRevisionDocuments.IResponse;
type Headers = Api.Core.Contract.GenerateRevisionDocuments.IHeaders;
const url = '/api/Sales/Revision/:contractId/GenerateDocuments';
export default async function revisionGenerateDocumentsApi({
  contractId,
  productPdfInputItems,
  productEnhancementPdfInputItems,
}: Request): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Omit<Request, 'contractId'>, Response, Headers>({
    method: HttpMethod.post,
    url: createPathFromRoute(url, { contractId }),
    body: {
      productPdfInputItems,
      productEnhancementPdfInputItems,
    },
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
