import ProviderBuilder from 'assets/providers/ProviderBuilder';
import { convertToArray } from 'assets/utils/data/Data';
import { isEmpty, isValidValue } from 'assets/utils/parsersAndValidation/Validators';
import dayjs from 'dayjs';
import { cloneDeep, compact, entries, filter, get, keys, merge, orderBy, reduce, sortBy, uniq, uniqBy } from 'lodash';
import ProductType from 'models/enums/ProductType';
import { useEffect, useMemo, useState } from 'react';

type UpdateStat = {
  updatedCount: number;
  totalCount: number;
};

export type PricingFilters = {
  termInMonths?: number;
  customInfo?: string;
  customInfoGroup?: string;
  maxVehiclePriceCAD?: number;
  minVehiclePriceCAD?: number;
  tier?: number;
  vehicleClass?: number;
  productPricingPlan?: number;
  deductible?: number;
  productProvinceGroup?: number;
  limits?: number;
};
type PricingFilterType = 'string' | 'number' | 'object';
type SaveablePricingSections = 'base' | 'limitation' | 'discount' | 'distributor';
type PricingFilterSource = 'local' | 'server';

const FILTER_TYPES: Utils.Modifier<PricingFilters, PricingFilterType> = {
  termInMonths: 'number',
  customInfo: 'string',
  customInfoGroup: 'string',
  maxVehiclePriceCAD: 'number',
  minVehiclePriceCAD: 'number',
  tier: 'object',
  vehicleClass: 'object',
  productPricingPlan: 'object',
  deductible: 'number',
  productProvinceGroup: 'number',
  limits: 'object',
};

const FILTER_ORDER: Array<keyof PricingFilters> = [
  'productPricingPlan',
  'customInfo',
  'customInfoGroup',
  'tier',
  'vehicleClass',
  'termInMonths',
  'deductible',
  'minVehiclePriceCAD',
  'maxVehiclePriceCAD',
  'limits',
  'productProvinceGroup',
];

export type PricingFilterValue = string | number;

export type PricingFilter = {
  key: keyof PricingFilters;
  order: 'asc' | 'desc';
  source: PricingFilterSource;
  timestamp: number;
  type: PricingFilterType;
  options: Array<{
    value: PricingFilterValue;
    label: string;
    isSelected: boolean;
    isEnabledForCopy: boolean;
  }>;
};

type PriceInfo = {
  distributions: Utils.PricingDistribution | null;
  subfees?: Array<Utils.SurchargeInfo> | null;
  isUpdated?: boolean;
};

type DiscountInfo = {
  id?: number;
  distributions: Utils.DiscountDistribution;
  distributorDistributions: Utils.DiscountDistribution;
  isUpdated?: boolean;
};

type LimitationInfo = {
  id?: number;
  limitations: Utils.DistributionLimitation;
  isUpdated?: boolean;
};

type DistributorInfo = {
  id?: number;
  distributions: Utils.PricingDistribution;
  isUpdated?: boolean;
};

export type AllPricingOptions = {
  id: number;
  type: Utils.PricePointType;

  filters: PricingFilters;
  base: PriceInfo;
  discount?: DiscountInfo;
  limitation?: LimitationInfo;
  distributor?: DistributorInfo;
};

type PriceSummary = {
  basePrice: number;
  distributorPrice: number;
  adjustedBasePrice: number;
  adjustedDistributorPrice: number;
};

type PriceWithSummary = AllPricingOptions & PriceSummary;

export type UsedPricingFilters = Utils.Modifier<PricingFilters, PricingFilterSource>;
export type PricingFilterMappings = Utils.Modifier<PricingFilters, Array<{ value: PricingFilterValue; label: string }>>;
export type PricesGetFunction = (pricingFilters: PricingFilter[]) => Promise<Array<AllPricingOptions>>;

type PriceEditorProps = {
  type: Utils.PricePointType;
  usedFilters: UsedPricingFilters;
  dataMappings: PricingFilterMappings;
  templates: {
    base: string[];
    distributor?: string[];
  };
  prices: Array<AllPricingOptions> | PricesGetFunction;
  onSave(prices: AllPricingOptions): Promise<void>;
  onSaved(): void;
};

function usePriceEditorCore(props: PriceEditorProps) {
  //#region PRICES
  const [prices, setPrices] = useState<Array<AllPricingOptions>>([]);
  const [overrideEmptyPrice, setOverrideEmptyPrices] = useState(false);

  function isPriceMatch(filters: PricingFilters, price: AllPricingOptions) {
    const filterKeys = keys(filters);
    return filterKeys.every((k) => price.filters[k] === filters[k]);
  }

  function calculatePrice(pricingDistributions?: Utils.PricingDistribution) {
    return reduce(pricingDistributions || {}, (prev, curr) => prev + curr.amount, 0);
  }
  function calculateDiscountedPrice(
    pricingDistributions: Utils.PricingDistribution,
    discountDistribution: Utils.DiscountDistribution
  ) {
    let discountPrice = 0;
    const suggestedPrice = reduce(pricingDistributions, (p, c) => p + (c.amount ?? 0), 0);
    const allKeys = compact(keys(discountDistribution ?? {}));
    for (const key of allKeys) {
      const distributionPrice = get(pricingDistributions, key);
      const discount = get(discountDistribution, key);
      const hasDiscount = !!discount?.amount && !!discount?.unit;
      if (isValidValue(distributionPrice?.amount) && hasDiscount) {
        const discountAmount =
          discount.unit === 'percent' ? distributionPrice.amount * (discount.amount / 100) : discount.amount;

        discountPrice += discountAmount;
      }
    }

    return suggestedPrice + discountPrice;
  }
  function calculatePrices(price: AllPricingOptions): PriceWithSummary {
    const basePrice = calculatePrice(price.base.distributions);
    const distributorPrice = basePrice + calculatePrice(price.distributor?.distributions);
    const adjustedBasePrice = calculateDiscountedPrice(price.base.distributions, price.discount?.distributions);
    const adjustedDistributorPrice =
      adjustedBasePrice +
      calculateDiscountedPrice(price.distributor?.distributions, price.discount?.distributorDistributions);

    return {
      ...price,
      basePrice,
      distributorPrice,
      adjustedBasePrice,
      adjustedDistributorPrice,
    };
  }

  function createNewPrice(filters: PricingFilters): AllPricingOptions {
    return {
      id: undefined,
      type: props.type,
      filters,
      base: {
        distributions: null,
        isUpdated: false,
      },
    };
  }

  function updatePrices(
    updatedPrices: Utils.SingleOrArray<AllPricingOptions>,
    mapper: Utils.DeepPartial<AllPricingOptions> | ((price: PriceWithSummary) => Utils.DeepPartial<AllPricingOptions>)
  ) {
    setPrices((old) => {
      const _updatedPrices = convertToArray(updatedPrices);

      return old.map((it) => {
        const isMatch = _updatedPrices.some((p) => isPriceMatch(p.filters, it));
        if (isMatch) {
          if (!overrideEmptyPrice && it.base.distributions == null && Array.isArray(updatedPrices)) return it;

          const newPrice = typeof mapper == 'function' ? mapper(cloneDeep(calculatePrices(it))) : mapper;

          if (newPrice.base) newPrice.base.isUpdated = true;
          if (newPrice.discount) newPrice.discount.isUpdated = true;
          if (newPrice.distributor) newPrice.distributor.isUpdated = true;
          if (newPrice.limitation) newPrice.limitation.isUpdated = true;

          const finalPrice = merge({}, it, newPrice);

          for (const [k, v] of entries(finalPrice.discount?.distributorDistributions)) {
            if ((!v || (!v.amount && !v.unit)) && finalPrice.discount?.distributorDistributions) {
              delete finalPrice.discount.distributorDistributions[k];
            }
          }
          for (const [k, v] of entries(finalPrice.discount?.distributions)) {
            if ((!v || (!v.amount && !v.unit)) && finalPrice.discount?.distributions) {
              delete finalPrice.discount.distributions[k];
            }
          }
          if (isEmpty(finalPrice.base.distributions) || !finalPrice.base.distributions) {
            finalPrice.base.distributions = null;

            if (finalPrice.discount) {
              finalPrice.discount.distributions = null;
              finalPrice.discount.distributorDistributions = null;
              finalPrice.discount.isUpdated = true;
            }

            if (finalPrice.distributor) {
              finalPrice.distributor.distributions = null;
              finalPrice.distributor.isUpdated = true;
            }
          }
          if (isEmpty(finalPrice.discount?.distributions)) {
            finalPrice.discount.distributions = null;
          }
          if (isEmpty(finalPrice.discount?.distributorDistributions)) {
            finalPrice.discount.distributorDistributions = null;
          }
          if (isEmpty(finalPrice.distributor?.distributions)) {
            finalPrice.distributor.distributions = null;
          }

          finalPrice.base.subfees = uniqBy(compact(finalPrice.base.subfees), (it) => it.subfeeId);
          return finalPrice;
        } else return it;
      });
    });
  }

  const { updateStats, isUpdated } = useMemo(() => {
    const updateStats: Record<SaveablePricingSections, UpdateStat> = {
      limitation: { updatedCount: 0, totalCount: 0 },
      base: { updatedCount: 0, totalCount: 0 },
      discount: { updatedCount: 0, totalCount: 0 },
      distributor: { updatedCount: 0, totalCount: 0 },
    };
    let isUpdated = false;

    for (const price of prices) {
      for (const k of ['base', 'limitation', 'discount', 'distributor'] as unknown as SaveablePricingSections) {
        if (price[k]) {
          updateStats[k].totalCount = updateStats[k].totalCount + 1;
          if (price[k].isUpdated) {
            updateStats[k].updatedCount = updateStats[k].updatedCount + 1;
            isUpdated = true;
          }
        }
      }
    }
    return { updateStats, isUpdated };
  }, [prices]);

  function getAllCombinations(filterLists: Array<PricingFilter>): Array<PricingFilters> {
    const keys = filterLists.map((it) => it.key);
    const lists = filterLists.map((it) => it.options.map((o) => o.value));
    function cartesianProduct(arrays: any[]) {
      return arrays.reduce(
        (acc, curr) => {
          const result = [];
          acc.forEach((a) => {
            curr.forEach((c) => {
              result.push([...a, c]);
            });
          });
          return result;
        },
        [[]]
      );
    }

    const combinations = cartesianProduct(lists);

    return combinations.map((combination) => {
      const objCombination = {};
      keys.forEach((key, index) => {
        objCombination[key] = combination[index];
      });
      return objCombination;
    });
  }

  function getMissingPrices(prices: AllPricingOptions[], filters: PricingFilter[]) {
    if (props.type === 'ProductPricingPlanClass') return [];
    const allFilters = getAllCombinations(filters);
    const missingPrices: AllPricingOptions[] = [];
    for (const filter of allFilters) {
      if (!prices.find((price) => isPriceMatch(filter, price))) {
        missingPrices.push(createNewPrice(filter));
      }
    }
    return missingPrices;
  }

  function addEmptyPrices(newFilters: PricingFilters) {
    const _filters = cloneDeep(filters);
    for (const [k, value] of entries(newFilters)) {
      const focusedFilter = _filters.find((it) => it.key === k);

      focusedFilter.options = [
        ...focusedFilter.options,
        {
          value,
          label:
            props.dataMappings[k as keyof typeof props.dataMappings]?.find((d) => d.value === value)?.label ??
            value.toString(),
          isSelected: props.type === 'ProductEnhancementTierPricePoint',
          isEnabledForCopy: false,
        },
      ];
    }
    const missingPrices = getMissingPrices(prices, _filters);
    setPrices((old) => [...old, ...missingPrices]);
    setFilters(_filters);
  }

  function removePrices(filters: PricingFilters) {
    setPrices((old) => old.filter((price) => !isPriceMatch(filters, price)));
  }
  //#endregion PRICES

  //#region SAVING
  const [isSaving, setIsSaving] = useState(false);
  const [savedCount, setSavedCount] = useState<Record<SaveablePricingSections, number>>({
    base: 0,
    discount: 0,
    distributor: 0,
    limitation: 0,
  });
  async function saveAll() {
    setIsSaving(true);
    setSavedCount({
      base: 0,
      discount: 0,
      distributor: 0,
      limitation: 0,
    });
    for (const price of prices) {
      if (
        price.base?.isUpdated ||
        price.discount?.isUpdated ||
        price.limitation?.isUpdated ||
        price.distributor?.isUpdated
      ) {
        await props.onSave(price);
        setSavedCount((old) => ({
          base: price.base?.isUpdated ? old.base + 1 : old.base,
          discount: price.discount?.isUpdated ? old.discount + 1 : old.discount,
          limitation: price.limitation?.isUpdated ? old.limitation + 1 : old.limitation,
          distributor: price.distributor?.isUpdated ? old.distributor + 1 : old.distributor,
        }));
      }
    }
    setIsSaving(false);
    props.onSaved();
  }
  //#endregion SAVING

  //#region FILTERING
  const [serverFilters, setServerFilters] = useState<Array<PricingFilter>>([]);
  const [filters, setFilters] = useState<Array<PricingFilter>>([]);
  useEffect(() => {
    (async () => {
      const _availableFilters: Array<PricingFilter> = [];
      for (const usedFilter of keys(props.usedFilters) as Array<keyof PricingFilters>) {
        if (props.usedFilters[usedFilter] === 'server') {
          _availableFilters.push({
            key: usedFilter,
            source: 'server',
            order: 'asc',
            timestamp: 0,
            type: FILTER_TYPES[usedFilter],
            options: props.dataMappings[usedFilter].map(({ value, label }, index) => ({
              value,
              label: label ?? value?.toString(),
              isSelected: serverFilters.length
                ? serverFilters
                    .find((f) => f.key === usedFilter)
                    ?.options?.some((o) => o.isSelected && o.value === value)
                : index === 0,
              isEnabledForCopy: index === 0,
            })),
          });
        }
      }
      console.log(serverFilters);
      const _prices = typeof props.prices === 'function' ? await props.prices(_availableFilters) : props.prices;
      for (const usedFilter of keys(props.usedFilters) as Array<keyof PricingFilters>) {
        if (props.usedFilters[usedFilter] === 'local') {
          _availableFilters.push({
            key: usedFilter,
            source: 'local',
            order: 'asc',
            timestamp: 0,
            type: FILTER_TYPES[usedFilter],
            options: sortBy(uniq(_prices.map((price) => price.filters[usedFilter]))).map((value, index) => ({
              value,
              label: props.dataMappings[usedFilter]?.find((it) => it.value === value)?.label ?? value?.toString(),
              isSelected: index === 0,
              isEnabledForCopy: index === 0,
            })),
          });
        }
      }
      const _filters = sortBy(_availableFilters, (it) => FILTER_ORDER.indexOf(it.key));
      const missingPrices = getMissingPrices(_prices, _filters);
      setFilters(_filters);
      setPrices([..._prices, ...missingPrices]);
    })();
  }, [props.usedFilters, props.prices, serverFilters]);

  function switchOrder(filterKey: keyof PricingFilters) {
    setFilters((old) =>
      old.map((f) =>
        f.key === filterKey ? { ...f, timestamp: dayjs().unix(), order: f.order === 'asc' ? 'desc' : 'asc' } : f
      )
    );
  }
  function switchFilter(filterKey: keyof PricingFilters, value: PricingFilterValue) {
    const _filters = filters.map((f) => ({
      ...f,
      options:
        f.key !== filterKey
          ? f.options
          : f.options.map((o) =>
              f.source === 'server'
                ? o.value === value
                  ? { ...o, isSelected: true, isEnabledForCopy: true }
                  : { ...o, isSelected: false, isEnabledForCopy: false }
                : o.value === value
                ? {
                    ...o,
                    isSelected: !o.isSelected,
                    isEnabledForCopy: !o.isSelected,
                  }
                : { ...o, isEnabledForCopy: o.isSelected }
            ),
    }));
    setFilters(_filters);
    if (props.usedFilters[filterKey] === 'server') {
      setServerFilters(cloneDeep(_filters.filter((f) => f.source === 'server')));
    }
  }
  function switchFilterCopy(filterKey: keyof PricingFilters, value: PricingFilterValue) {
    setFilters((old) =>
      old.map((f) =>
        f.key === filterKey
          ? {
              ...f,
              options: f.options.map((o) => (o.value === value ? { ...o, isEnabledForCopy: !o.isEnabledForCopy } : o)),
            }
          : f
      )
    );
  }
  //#endregion FILTERING

  const pricingType =
    props.type === 'CustomPricePoint'
      ? ProductType.custom
      : props.type === 'NonWarrantyPricePoint'
      ? ProductType.nonWarranty
      : props.type === 'ProductPricingPlanClass'
      ? ProductType.warrantiClassBased
      : ProductType.warrantyTierBased;

  const hasNoInfo =
    !prices.length && filters.every((it) => it.options.every((o) => !o.isSelected) || !it.options.length);

  const sortedPrices = useMemo(() => {
    const filteredPrices = filter(prices, (p) =>
      filters.every((f) => f.options.some((o) => o.isSelected && o.value === p.filters[f.key]))
    );
    const filtersSortedByLatest = orderBy(filters, (f) => f.timestamp, 'desc');
    return orderBy(
      filteredPrices.map(calculatePrices),
      filtersSortedByLatest.map((f) => (p) => p.filters[f.key]),
      filtersSortedByLatest.map((f) => f.order)
    );
  }, [prices, filters]);

  const copyToPrices = useMemo(
    () =>
      filter(prices, (p) =>
        filters.every((f) => f.options.some((o) => o.isEnabledForCopy && o.value === p.filters[f.key]))
      ).map(calculatePrices),
    [prices, filters]
  );

  function cleanupTemplateKeys(keys?: string[]) {
    return keys?.filter((it) => !keys?.some((k) => k !== it && k.indexOf(it) === 0));
  }

  const templates = {
    base: cleanupTemplateKeys(props.templates.base),
    distributor: cleanupTemplateKeys(props.templates.distributor),
  };

  return {
    templates,
    dataMappings: props.dataMappings,
    allPrices: prices,
    prices: sortedPrices,
    copyToPrices,
    hasNoInfo,
    isSaving,
    isUpdated,
    savedCount,
    updateStats,
    pricingType,
    saveAll,
    filters,
    addEmptyPrices,
    removePrices,
    switchOrder,
    switchFilter,
    switchFilterCopy,
    updatePrices,
    setOverrideEmptyPrices,
    overrideEmptyPrice,
  };
}

const { useProvider: usePriceEditor, Provider: PriceEditorProvider } = ProviderBuilder(usePriceEditorCore);

export { usePriceEditor, PriceEditorProvider };
